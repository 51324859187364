import React from "react"
import { Input, FormGroup, FormFeedback } from "reactstrap"

const TicketPriceNQuotosInput = ({
  id,
  state,
  type,
  weekdayOrWeekend,
  priceOrQuota,
  onChange,
}) => {
  if (!state) {
    return (
      <FormGroup className="m-0 h-75">
        <Input
          name={id}
          type="number"
          className={`form-control h-100 bg-light`}
          value={""}
          onChange={() => {}}
          invalid={false}
          disabled={true}
        />
      </FormGroup>
    )
  }
  const value = state.value[weekdayOrWeekend][priceOrQuota]
  return (
    <FormGroup className="m-0 h-75">
      <Input
        name={id}
        type="number"
        className={`form-control h-100 ${state.checked ? "" : "bg-light"}`}
        value={value}
        onChange={e =>
          onChange(type, weekdayOrWeekend, priceOrQuota, e.target.value)
        }
        invalid={state.hasError && priceOrQuota === "price" && !value}
        disabled={!state.checked}
        min="0"
        step="1"
      />
      <FormFeedback className="m-0">{state.errorMessage}</FormFeedback>
    </FormGroup>
  )
}

export default TicketPriceNQuotosInput
