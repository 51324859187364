import * as HomePageActions from "./actionTypes"

export const getHomePage = callBacks => ({
  type: HomePageActions.GET_HOMEPAGE,
  payload: callBacks,
})

export const getHomePageSuccess = homepageBanners => ({
  type: HomePageActions.GET_HOMEPAGE_SUCCESS,
  payload: homepageBanners,
})

export const getHomePageFail = error => ({
  type: HomePageActions.GET_HOMEPAGE_FAIL,
  payload: error,
})

export const editHomePage = homepageBanners => ({
  type: HomePageActions.EDIT_HOMEPAGE,
  payload: homepageBanners,
})

export const editHomePageSuccess = callBacks => ({
  type: HomePageActions.EDIT_HOMEPAGE_SUCCESS,
  payload: callBacks,
})

export const editHomePageFail = error => ({
  type: HomePageActions.EDIT_HOMEPAGE_FAIL,
  payload: error,
})
