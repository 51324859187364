import React from "react"
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap"
import moment from "moment"
import { transactionStatus } from "../../utils"

const TransactionsOrderColumns = ({ handleViewTransaction }) => [
  {
    dataField: "state",
    text: "Order Status",
    sort: true,
    formatter: cellContent => transactionStatus[cellContent]?.title,
  },
  {
    dataField: "ctime",
    text: "Order Datetime",
    sort: true,
    formatter: cellContent => {
      return (
        <div>
          <div>{moment(cellContent).format(`MMM DD, YYYY`)}</div>
          <div>{moment(cellContent).format(`HH:mm`)}</div>
        </div>
      )
    },
  },
  {
    dataField: "eventId",
    text: "Event Id",
    sort: true,
    formatter: cellContent => cellContent,
  },
  {
    dataField: "event",
    text: "Event Name",
    sort: true,
    formatter: cellContent => cellContent?.sc?.title,
  },
  {
    dataField: "date",
    text: "Event Date",
    sort: true,
    formatter: cellContent =>
      moment(cellContent, "YYYY-MM-DD").format("MMM DD, YYYY"),
  },
  {
    dataField: "items",
    text: "No. of Tix",
    sort: true,
    formatter: cellContent =>
      cellContent?.reduce((acc, cur) => acc + cur.quantity, 0),
  },
  {
    dataField: "items",
    text: "Total Amount",
    sort: true,
    formatter: cellContent =>
      cellContent?.reduce((acc, cur) => acc + cur.quantity * cur.grossPrice, 0),
  },
  {
    dataField: "",
    text: "",
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <ArticleDropDown
          handleViewTransaction={() => handleViewTransaction(row)}
        />
      )
    },
  },
]

export default TransactionsOrderColumns

const ArticleDropDown = ({ handleViewTransaction }) => {
  return (
    <>
      <UncontrolledDropdown className="dropdown">
        <DropdownToggle className="text-muted font-size-16" color="white">
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right" direction="right">
          <DropdownItem onClick={handleViewTransaction}>view</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}
