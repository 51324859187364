import React from "react"
import {
  Row,
  Col,
  CardTitle,
  FormGroup,
  Input,
  Label,
  FormFeedback,
} from "reactstrap"
import LabelCheckBox from "./LabelCheckBox"

const SpecialNotice = ({
  checked,
  checkBoxOnChange,
  titleValue,
  titleOnChange,
  titleInvalid,
  titleErrorMsg,
  bodyValue,
  bodyOnChange,
  bodyInvalid,
  bodyErrorMsg,
}) => {
  return (
    <React.Fragment>
      <CardTitle className="mb-3 font-size-20">Special notice</CardTitle>
      <div className="ml-2">
        <Row className="m-0 mb-3">
          <LabelCheckBox
            label="Show notice"
            id="notice_show"
            checked={checked}
            onChange={checkBoxOnChange}
          />
        </Row>
        <Row>
          <Col sm="12">
            <FormGroup>
              <Label htmlFor="notice_title">Notice Title</Label>
              <Input
                name="notice_title"
                type="text"
                className="form-control"
                value={titleValue}
                onChange={titleOnChange}
                invalid={titleInvalid}
              />
              <FormFeedback>{titleErrorMsg}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm="12">
            <FormGroup>
              <Label htmlFor="notice_body">Notice Body</Label>
              <Input
                name="notice_body"
                type="textarea"
                className="form-control"
                value={bodyValue}
                onChange={bodyOnChange}
                invalid={bodyInvalid}
              />
              <FormFeedback>{bodyErrorMsg}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default SpecialNotice
