import * as LoginActionTypes from "./actionTypes"

export const postLogin = loginInfosWithCallBack => ({
  type: LoginActionTypes.POST_LOGIN,
  payload: loginInfosWithCallBack,
})

export const postLoginSuccess = tokens => ({
  type: LoginActionTypes.POST_LOGIN_SUCCESS,
  payload: tokens,
})

export const postLoginFail = error => ({
  type: LoginActionTypes.POST_LOGIN_FAIL,
  payload: error,
})

export const loggingOut = callBacks => ({
  type: LoginActionTypes.LOGGING_OUT,
  payload: callBacks,
})

export const showLoading = () => ({
  type: LoginActionTypes.SHOW_LOADING,
})

export const hideLoading = () => ({
  type: LoginActionTypes.HIDE_LOADING,
})
