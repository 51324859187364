import React from "react"
import { Link } from "react-router-dom"
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap"
import moment from "moment"
import { capitalizeFirstLetter } from "../../utils/formatStrings"

const TicketingsOrderColumns = ({
  handleDeleteTicketing,
  handleEditTicketing,
  handleEditTicketingQuotas,
  handlePreviewTicketing,
}) => [
  {
    dataField: "id",
    text: "ID",
    formatter: cellContent => {
      return (
        <Link to="#" className="text-body font-weight-bold">
          {cellContent}
        </Link>
      )
    },
  },
  {
    dataField: "sc",
    text: "Title",
    sort: true,
    formatter: cellContent => cellContent?.title,
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
    formatter: cellContent => capitalizeFirstLetter(cellContent),
  },
  {
    dataField: "category_sortIndex",
    text: "Index",
    sort: true,
  },
  {
    dataField: "startDate",
    text: "Start Date",
    sort: true,
    formatter: cellContent => moment(cellContent)?.format("MMM DD, YYYY"),
  },
  {
    dataField: "endDate",
    text: "End Date",
    sort: true,
    formatter: cellContent => moment(cellContent)?.format("MMM DD, YYYY"),
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: cellContent => capitalizeFirstLetter(cellContent),
  },
  {
    dataField: "",
    text: "",
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <TicketingDropDown
          handleEditTicketing={() => handleEditTicketing(row)}
          handleDeleteTicketing={() => handleDeleteTicketing(row.id)}
          handleEditTicketingQuotas={() => handleEditTicketingQuotas(row)}
          handlePreviewTicketing={() => handlePreviewTicketing(row.id)}
        />
      )
    },
  },
]

export default TicketingsOrderColumns

const TicketingDropDown = ({
  handleEditTicketing,
  handleEditTicketingQuotas,
  handlePreviewTicketing,
}) => {
  return (
    <>
      <UncontrolledDropdown className="dropdown">
        <DropdownToggle className="text-muted font-size-16" color="white">
          <i className="mdi mdi-dots-horizontal" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right" direction="right">
          <DropdownItem onClick={handlePreviewTicketing}>preview</DropdownItem>
          <DropdownItem onClick={handleEditTicketing}>edit</DropdownItem>
          <DropdownItem onClick={handleEditTicketingQuotas}>
            edit quotas
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}
