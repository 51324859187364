import React, { useEffect, useReducer } from "react"
import { Container, Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { compose } from "redux"
import produce from "immer"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { NotificationContainer, NotificationManager } from "react-notifications"

import * as TicketingsActions from "../../store/ticketings/actions"
import {
  ticketingsCategories,
  ticketingsStatus,
} from "../../utils/ticketingsOptions"
import TicketingsOrderColumns from "./TicketingsOrderColumns"
import { axiosMp } from "../../utils/"
import { QrcodeModal } from "../../components/Modals"

const initialState = {
  currentPage: 1,
  sizePerPage: 10,
  category: "",
  status: "",
  startDate: null,
  endDate: null,
  qrcodeModalVisible: false,
  qrcodeBase64: "",
}

const reducer = produce((draft, action) => {
  const { page, value, key, qrcode } = action.payload
  switch (action.type) {
    case "onPrevPage":
      draft.currentPage = draft.currentPage - 1 || 1
      break
    case "OnSpecificPage":
      draft.currentPage = page
      break
    case "OnCategoryChange":
      draft.category = value
      break
    case "OnStatusChange":
      draft.status = value
      break
    case "OnInputChange":
    case "OnDateChange":
      console.log("🚀 ~ file: index.js ~ line 60 ~ reducer ~ value", value)
      draft[key] = !value ? null : moment(value?.valueOf()).format("YYYY-MM-DD")
      break
    case "OnQrcodeModalToggle":
      draft.qrcodeModalVisible = value
      draft.qrcodeBase64 = value ? qrcode : ""
      break
    default:
      break
  }
})

const Ticketings = ({ history }) => {
  const [state, dispatchToReducer] = useReducer(reducer, initialState)
  const { ticketings, ticketingsTotalCount } = useSelector(
    state => state.ticketings
  )
  console.log(
    "🚀 ~ file: index.js ~ line 59 ~ Ticketings ~ ticketings",
    ticketings
  )
  const dispatch = useDispatch()
  const pageOptions = {
    page: state.currentPage,
    sizePerPage: state.sizePerPage,
    totalSize: ticketingsTotalCount,
    custom: true,
    onPageChange: page =>
      dispatchToReducer({ type: "OnSpecificPage", payload: { page } }),
  }

  useEffect(() => {
    getTicketingsAndTicketingsCount()
  }, [state.currentPage])

  const getTicketingsAndTicketingsCount = resetToPage1 => {
    if (resetToPage1 && state.currentPage !== 1) {
      dispatchToReducer({ type: "OnSpecificPage", payload: { page: 1 } })
      return
    }
    const ticketingCountFilters = {
      ...(state.category && { category: state.category }),
      ...(state.status && { status: state.status }),
      ...(state.startDate && { endDate_gte: state.startDate }),
      ...(state.endDate && { startDate_lte: state.endDate }),
    }
    const ticketingFilters = {
      _limit: state.sizePerPage,
      _start: (state.currentPage - 1) * state.sizePerPage,
      _sort: "category_sortIndex:desc,created_at:desc",
      ...ticketingCountFilters,
    }
    dispatch(TicketingsActions.getTicketings({ filters: ticketingFilters }))
    dispatch(
      TicketingsActions.getTicketingsCount({ filters: ticketingCountFilters })
    )
  }

  const handlePreviewTicketing = async ticketId => {
    const res = await axiosMp.get(`/qrcode`, {
      params: {
        id: ticketId,
        path: "event",
      },
    })
    if (res.data?.errcode) {
      NotificationManager.error(
        "Getting Ticketing preview failed",
        "Error",
        3000
      )
    } else {
      dispatchToReducer({
        type: "OnQrcodeModalToggle",
        payload: { value: true, qrcode: res.data?.base64String },
      })
    }
    console.log("!H!H!H!Hres", res.data)
  }
  const handleDeleteTicketing = async ticketingId => {
    await new Promise((resolve, reject) =>
      dispatch(
        TicketingsActions.deleteTicketing({
          ticketingId,
          onSuccess: resolve,
          onError: reject,
        })
      )
    )
    // spectial situation ***
    if (
      (state.curretPage - 1) * state.sizePerPage + 1 ===
      ticketingsTotalCount
    ) {
      // if the currenet page's first item is the last item
      // after doing delete need to go to previous page
      dispatchToReducer({ type: "onPrevPage", payload: {} })
    } else {
      // normal situation refresh
      getTicketingsAndTicketingsCount()
    }
  }

  const handleEditTicketing = ticketing => {
    history.push({
      pathname: `/ticketing/${ticketing.id}/edit`,
    })
  }

  const handleEditTicketingQuotas = ticketing => {
    history.push({
      pathname: `/ticketing-quotas/${ticketing.id}/edit`,
    })
  }

  const handleSelectChange = (e, key) => {
    dispatchToReducer({
      type: key,
      payload: {
        value: e.target.value,
      },
    })
  }

  const handleDateChange = (val, key) => {
    dispatchToReducer({
      type: "OnDateChange",
      payload: {
        value: val,
        key: key,
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Ticktings</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="1">
                      <Label className="mb-3">Filter :</Label>
                    </Col>
                    <Col md="3">
                      <select
                        className="form-control select2 mb-3"
                        value={state.category}
                        onChange={e =>
                          handleSelectChange(e, "OnCategoryChange")
                        }
                      >
                        <option value="">Category</option>
                        {Object.values(ticketingsCategories).map(category => (
                          <option key={category.value} value={category.value}>
                            {category.title}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col md="2">
                      <select
                        className="form-control select2 mb-3"
                        value={state.status}
                        onChange={e => handleSelectChange(e, "OnStatusChange")}
                      >
                        <option value="">Status</option>
                        {Object.values(ticketingsStatus).map(as => (
                          <option key={as.value} value={as.value}>
                            {as.title}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col md="2">
                      <DatePicker
                        selected={
                          state.startDate
                            ? new Date(moment(state.startDate)?.valueOf())
                            : null
                        }
                        onChange={val => {
                          handleDateChange(val, "startDate")
                        }}
                        className="form-control"
                        placeholderText="Start date"
                        isClearable
                      />
                    </Col>

                    <Col md="2">
                      <DatePicker
                        selected={
                          state.endDate
                            ? new Date(moment(state.endDate)?.valueOf())
                            : null
                        }
                        onChange={val => {
                          console.log(`val`, val)
                          handleDateChange(val, "endDate")
                        }}
                        className="form-control"
                        placeholderText="End date"
                        isClearable
                      />
                    </Col>

                    <Col md="2">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md mb-3"
                        onClick={() => getTicketingsAndTicketingsCount(true)}
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <Link
                        to="/create-ticketing"
                        className="btn btn-primary mr-2 w-md mb-3 mt-4"
                      >
                        Create
                      </Link>
                      {/* <Button
                        type="button"
                        color="secondary"
                        className="w-md mr-2 mb-3 mt-4"
                      >
                        Export
                      </Button> */}
                    </Col>
                  </Row>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={ticketings}
                        columns={TicketingsOrderColumns({
                          handleDeleteTicketing,
                          handleEditTicketing,
                          handleEditTicketingQuotas,
                          handlePreviewTicketing,
                        })}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={() =>
                                      console.log("onTable CHange????")
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <QrcodeModal
            isOpen={state.qrcodeModalVisible}
            qrcodeBase64={state.qrcodeBase64}
            onCancel={() =>
              dispatchToReducer({
                type: "OnQrcodeModalToggle",
                payload: { value: false },
              })
            }
          ></QrcodeModal>
          <NotificationContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default compose(withRouter)(Ticketings)
