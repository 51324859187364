import React, { useReducer } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
} from "reactstrap"
import produce from "immer"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"
import _ from "lodash"
import classnames from "classnames"

import * as AdminsActions from "../../store/admins/actions"
import * as LoginActions from "../../store/login/actions"

const initialState = {
  formValue: {
    name: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    email: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    password: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    type: {
      value: "normal",
      hasError: false,
      errorMessage: "",
    },
  },
}

const editModeInitialState = editArticleTab => {
  const defaultFormValue = produce(editArticleTab, draftArticleTab => {
    Object.keys(draftArticleTab).forEach(key => {
      const keysToignore = ["updated_at", "created_at", "id"]
      if (keysToignore.includes(key)) {
        delete draftArticleTab[key]
      } else {
        draftArticleTab[key] = {
          value: draftArticleTab[key],
          hasError: false,
          errorMessage: "",
        }
      }
    })
    draftArticleTab.password = {
      value: "",
      hasError: false,
      errorMessage: "",
    }
  })
  return {
    ...initialState,
    formValue: defaultFormValue,
  }
}

const reducer = produce((draft, action) => {
  const { key, value } = action.payload
  switch (action.type) {
    case "OnInputChange":
    case "OnSelectChange":
      draft.formValue[key].value = value
      draft.formValue[key].hasError = false
      break
    case "OnUpdateFormValue":
      draft.formValue = value
      break
    default:
      break
  }
})

const CreateAdmin = props => {
  const { location } = props
  const isEditMode = location.state?.mode === "edit"
  const [state, dispatch] = useReducer(
    reducer,
    isEditMode ? editModeInitialState(location.state.editAdmin) : initialState
  )
  const { formValue } = state
  const handleInputChange = (e, key) => {
    dispatch({
      type: "OnInputChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }
  const handleSelectChange = (e, key) => {
    dispatch({
      type: "OnSelectChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }
  const handleSavePress = async () => {
    if (!validateFields()) return
    const {
      history,
      onEditAdmin,
      onPostAdmin,
      onShowLoading,
      onHideLoading,
    } = props
    try {
      const { formValue } = state
      onShowLoading()

      const submitForm = produce(formValue, draftFormValue => {
        Object.keys(draftFormValue).forEach(key => {
          if (isEditMode) {
            const keysToSubmit = ["password", "name"]
            if (keysToSubmit.includes(key))
              draftFormValue[key] = draftFormValue[key].value
            else delete draftFormValue[key]
          } else {
            draftFormValue[key] = draftFormValue[key].value
          }
        })
      })
      if (isEditMode) {
        await new Promise((resolve, reject) =>
          onEditAdmin(
            location.state?.editAdmin?.id,
            submitForm,
            resolve,
            reject
          )
        )
      } else {
        await new Promise((resolve, reject) =>
          onPostAdmin(submitForm, resolve, reject)
        )
      }
      onHideLoading()
      history.push("/admins")
    } catch (err) {
      console.log(err)
      onHideLoading()
    }
  }
  const validateFields = () => {
    const { formValue } = state
    let isAllValidate = true
    const newForm = produce(formValue, draftFormValue => {
      Object.keys(draftFormValue).forEach(key => {
        // const keysToSkip = ["en", "tc"]
        // if (!keysToSkip.includes(key)) {
        if (
          _.isNil(draftFormValue[key].value) ||
          draftFormValue[key].value === ""
        ) {
          draftFormValue[key].hasError = true
          draftFormValue[key].errorMessage = "Field cant be empty"
          isAllValidate = false
        }
        // }
      })
    })
    dispatch({ type: "OnUpdateFormValue", payload: { value: newForm } })
    return isAllValidate
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>{isEditMode ? "Edit Admin" : "Create Admin"}</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="shortDescription">Name</Label>
                        <Input
                          name="shortDescription"
                          type="text"
                          className="form-control"
                          value={formValue.name.value}
                          onChange={e => {
                            handleInputChange(e, "name")
                          }}
                          invalid={formValue.name.hasError}
                        />
                        <FormFeedback>
                          {formValue.name.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="shortDescription">Email</Label>
                        <Input
                          name="shortDescription"
                          type="text"
                          className={classnames("form-control", {
                            "bg-light": isEditMode,
                          })}
                          value={formValue.email.value}
                          onChange={e => {
                            handleInputChange(e, "email")
                          }}
                          invalid={formValue.email.hasError}
                          disabled={isEditMode}
                        />
                        <FormFeedback>
                          {formValue.email.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="shortDescription">Password</Label>
                        <Input
                          name="shortDescription"
                          type="text"
                          className="form-control"
                          value={formValue.password.value}
                          onChange={e => {
                            handleInputChange(e, "password")
                          }}
                          invalid={formValue.password.hasError}
                        />
                        <FormFeedback>
                          {formValue.password.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label className="control-label">Type</Label>
                        <select
                          className={classnames("form-control", "select2", {
                            "bg-light": isEditMode,
                          })}
                          value={formValue.type.value}
                          onChange={e => handleSelectChange(e, "type")}
                          disabled={isEditMode}
                        >
                          <option key={"super"} value={"super"}>
                            super
                          </option>
                          <option key={"normal"} value={"normal"}>
                            normal
                          </option>
                          <option key={"ticketing"} value={"ticketing"}>
                            ticketing
                          </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>
            <Button
              className="mr-3 w-md"
              color="primary"
              onClick={handleSavePress}
            >
              Save
            </Button>
            <Link to="/admins" className="btn btn-secondary mr-2 w-md">
              Cancel
            </Link>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ articles }) => ({
  articleTabs: articles.articleTabs,
})

const mapDispatchToProps = dispatch => ({
  onPostAdmin: (admin, onSuccess, onError) =>
    dispatch(AdminsActions.postAdmin({ admin, onSuccess, onError })),
  onEditAdmin: (adminId, admin, onSuccess, onError) =>
    dispatch(
      AdminsActions.editAdmin({
        adminId,
        admin,
        onSuccess,
        onError,
      })
    ),
  onShowLoading: () => dispatch(LoginActions.showLoading()),
  onHideLoading: () => dispatch(LoginActions.hideLoading()),
})
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CreateAdmin)
