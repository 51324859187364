import * as TicketingsActionTypes from "./actionTypes"

export const getTicketings = filtersWithCallbacks => ({
  type: TicketingsActionTypes.GET_TICKETINGS,
  payload: filtersWithCallbacks,
})

export const getTicketingsSuccess = ticketings => ({
  type: TicketingsActionTypes.GET_TICKETINGS_SUCCESS,
  payload: ticketings,
})

export const getTicketingsFail = error => ({
  type: TicketingsActionTypes.GET_TICKETINGS_FAIL,
  payload: error,
})

// getTicketingById
export const getTicketingById = ticketingIdWithCallbacks => ({
  type: TicketingsActionTypes.GET_TICKETING_BY_ID,
  payload: ticketingIdWithCallbacks,
})

export const getTicketingByIdSuccess = ticketings => ({
  type: TicketingsActionTypes.GET_TICKETING_BY_ID_SUCCESS,
  payload: ticketings,
})

export const getTicketingByIdFail = error => ({
  type: TicketingsActionTypes.GET_TICKETING_BY_ID_FAIL,
  payload: error,
})

// postTicketing
export const postTicketing = ticketingWithCallbacks => ({
  type: TicketingsActionTypes.POST_TICKETING,
  payload: ticketingWithCallbacks,
})

export const postTicketingSuccess = res => ({
  type: TicketingsActionTypes.POST_TICKETING_SUCCESS,
  payload: res,
})

export const postTicketingFail = error => ({
  type: TicketingsActionTypes.POST_TICKETING_FAIL,
  payload: error,
})

// deleteTicketing
export const deleteTicketing = ticketingIdWithCallbacks => ({
  type: TicketingsActionTypes.DELETE_TICKETING,
  payload: ticketingIdWithCallbacks,
})

export const deleteTicketingSuccess = res => ({
  type: TicketingsActionTypes.DELETE_TICKETING_SUCCESS,
  payload: res,
})

export const deleteTicketingFail = error => ({
  type: TicketingsActionTypes.DELETE_TICKETING_FAIL,
  payload: error,
})

// editTicketing
export const editTicketing = ticketingWithCallbacks => ({
  type: TicketingsActionTypes.EDIT_TICKETING,
  payload: ticketingWithCallbacks,
})

export const editTicketingSuccess = res => ({
  type: TicketingsActionTypes.EDIT_TICKETING_SUCCESS,
  payload: res,
})

export const editTicketingFail = error => ({
  type: TicketingsActionTypes.EDIT_TICKETING_FAIL,
  payload: error,
})

// getTicketings count
export const getTicketingsCount = filtersWithCallbacks => ({
  type: TicketingsActionTypes.GET_TICKETINGS_COUNT,
  payload: filtersWithCallbacks,
})

export const getTicketingsCountSuccess = ticketingsCount => ({
  type: TicketingsActionTypes.GET_TICKETINGS_COUNT_SUCCESS,
  payload: ticketingsCount,
})

export const getTicketingsCountFail = error => ({
  type: TicketingsActionTypes.GET_TICKETINGS_COUNT_FAIL,
  payload: error,
})

// generateTicketings
export const generateTicketings = ticketingWithCallbacks => ({
  type: TicketingsActionTypes.GENERATE_TICKETINGS,
  payload: ticketingWithCallbacks,
})

export const generateTicketingsSuccess = res => ({
  type: TicketingsActionTypes.GENERATE_TICKETINGS_SUCCESS,
  payload: res,
})

export const generateTicketingsFail = error => ({
  type: TicketingsActionTypes.GENERATE_TICKETINGS_FAIL,
  payload: error,
})

//extendTicketings
export const extendTicketings = ticketingWithCallbacks => ({
  type: TicketingsActionTypes.EXTEND_TICKETINGS,
  payload: ticketingWithCallbacks,
})

export const extendTicketingsSuccess = res => ({
  type: TicketingsActionTypes.EXTEND_TICKETINGS_SUCCESS,
  payload: res,
})

export const extendTicketingsFail = error => ({
  type: TicketingsActionTypes.EXTEND_TICKETINGS_FAIL,
  payload: error,
})

//quotas
export const getTicketingQuotas = filtersWithCallbacks => ({
  type: TicketingsActionTypes.GET_TICKETING_QUOTAS,
  payload: filtersWithCallbacks,
})

export const getTicketingQuotasSuccess = ticketings => ({
  type: TicketingsActionTypes.GET_TICKETING_QUOTAS_SUCCESS,
  payload: ticketings,
})

export const getTicketingQuotasFail = error => ({
  type: TicketingsActionTypes.GET_TICKETING_QUOTAS_FAIL,
  payload: error,
})

//edit quotas
export const editTicketingQuotas = filtersWithCallbacks => ({
  type: TicketingsActionTypes.EDIT_TICKETING_QUOTAS,
  payload: filtersWithCallbacks,
})

export const editTicketingQuotasSuccess = ticketings => ({
  type: TicketingsActionTypes.EDIT_TICKETING_QUOTAS_SUCCESS,
  payload: ticketings,
})

export const editTicketingQuotasFail = error => ({
  type: TicketingsActionTypes.EDIT_TICKETING_QUOTAS_FAIL,
  payload: error,
})

//get quotas count
export const getTicketingQuotasCount = filtersWithCallbacks => ({
  type: TicketingsActionTypes.GET_TICKETING_QUOTAS_COUNT,
  payload: filtersWithCallbacks,
})

export const getTicketingQuotasCountSuccess = ticketings => ({
  type: TicketingsActionTypes.GET_TICKETING_QUOTAS_COUNT_SUCCESS,
  payload: ticketings,
})

export const getTicketingQuotasCountFail = error => ({
  type: TicketingsActionTypes.GET_TICKETING_QUOTAS_COUNT_FAIL,
  payload: error,
})

// get multiple quotas

//quotas
export const getMultipleTicketingQuotas = filtersWithCallbacks => ({
  type: TicketingsActionTypes.GET_MULTIPLE_TICKETING_QUOTAS,
  payload: filtersWithCallbacks,
})

export const getMultipleTicketingQuotasSuccess = ticketings => ({
  type: TicketingsActionTypes.GET_MULTIPLE_TICKETING_QUOTAS_SUCCESS,
  payload: ticketings,
})

export const getMultipleTicketingQuotasFail = error => ({
  type: TicketingsActionTypes.GET_MULTIPLE_TICKETING_QUOTAS_FAIL,
  payload: error,
})
