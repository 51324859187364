import * as UserActionTypes from "./actionTypes"

const INIT_STATE = {
  users: [],
  usersTotalCount: 0,
  error: {},
}

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.GET_USERS:
      return {
        ...state,
        error: {},
      }
    case UserActionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      }
    case UserActionTypes.GET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UserActionTypes.GET_USERS_COUNT:
      return {
        ...state,
        error: {},
      }
    case UserActionTypes.GET_USERS_COUNT_SUCCESS:
      return {
        ...state,
        usersTotalCount: action.payload,
      }
    case UserActionTypes.GET_USERS_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default users
