import * as UserActionTypes from "./actionTypes"

// getUsers
export const getUsers = filtersWithCallbacks => ({
  type: UserActionTypes.GET_USERS,
  payload: filtersWithCallbacks,
})

export const getUsersSuccess = users => ({
  type: UserActionTypes.GET_USERS_SUCCESS,
  payload: users,
})

export const getUsersFail = error => ({
  type: UserActionTypes.GET_USERS_FAIL,
  payload: error,
})

// getUserById
export const getUserById = userIdWithCallbacks => ({
  type: UserActionTypes.GET_USER_BY_ID,
  payload: userIdWithCallbacks,
})

export const getUserByIdSuccess = users => ({
  type: UserActionTypes.GET_USER_BY_ID_SUCCESS,
  payload: users,
})

export const getUserByIdFail = error => ({
  type: UserActionTypes.GET_USER_BY_ID_FAIL,
  payload: error,
})

// editUser
export const editUser = userWithCallbacks => ({
  type: UserActionTypes.EDIT_USER,
  payload: userWithCallbacks,
})

export const editUserSuccess = res => ({
  type: UserActionTypes.EDIT_USER_SUCCESS,
  payload: res,
})

export const editUserFail = error => ({
  type: UserActionTypes.EDIT_USER_FAIL,
  payload: error,
})

// getUsers count
export const getUsersCount = filtersWithCallbacks => ({
  type: UserActionTypes.GET_USERS_COUNT,
  payload: filtersWithCallbacks,
})

export const getUsersCountSuccess = usersCount => ({
  type: UserActionTypes.GET_USERS_COUNT_SUCCESS,
  payload: usersCount,
})

export const getUsersCountFail = error => ({
  type: UserActionTypes.GET_USERS_COUNT_FAIL,
  payload: error,
})

// getUsersReports
export const getUsersReport = filtersWithCallbacks => ({
  type: UserActionTypes.GET_USERS_REPORT,
  payload: filtersWithCallbacks,
})

export const getUsersReportSuccess = users => ({
  type: UserActionTypes.GET_USERS_REPORT_SUCCESS,
  payload: users,
})

export const getUsersReportFail = error => ({
  type: UserActionTypes.GET_USERS_REPORT_FAIL,
  payload: error,
})
