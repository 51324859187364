import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"
import "react-datepicker/dist/react-datepicker.css"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"

import * as TransactionsActions from "../../store/transactions/actions"
import * as LoginActions from "../../store/login/actions"
import { transactionStatus } from "../../utils"

const ViewTransaction = props => {
  console.log("props", props)
  const { match } = props
  const { params } = match
  const [transaction, setTransaction] = useState({})
  const [alertShow, setAlertShow] = useState(false)
  console.log("transaction", transaction)
  const onUpdateToVoid = async () => {
    props.onShowLoading()
    setAlertShow(false)
    try {
      const res = await new Promise((resolve, reject) =>
        props.onUpdateTransactionStatusVoid(params.id, resolve, reject)
      )
      console.log("res", res)
    } catch (error) {
      console.log(error)
    }
    getTransaction()
    props.onHideLoading()
  }
  const getTransaction = async () => {
    if (params.id) {
      const transactionRes = await new Promise((resolve, reject) =>
        props.onGetTransactionById(params.id, resolve, reject)
      )
      setTransaction(transactionRes)
    }
  }
  useEffect(() => {
    getTransaction()
  }, [])
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>{"View Transaction"}</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Basic Info</CardTitle>
                  <Row className="mb-3">
                    <Col sm="6">
                      <Label className="mr-3">Order ID</Label>
                      <span>{transaction._id}</span>
                    </Col>
                    <Col sm="6">
                      <Label className="mr-3">Order Date</Label>
                      <span>{transaction.ctime}</span>
                    </Col>
                    <Col sm="6">
                      <Label className="mr-3">Name</Label>
                      <span>{transaction.contact?.name}</span>
                    </Col>
                    <Col sm="6">
                      <Label className="mr-3">Phone Number</Label>
                      <span>{`+${transaction.contact?.phone_countryCode} ${transaction.contact?.phone_number}`}</span>
                    </Col>
                    <Col sm="12">
                      <Label className="mr-3">Event ID</Label>
                      <span>{transaction.event?.id}</span>
                    </Col>
                    <Col sm="12">
                      <Label className="mr-3">Event Name</Label>
                      <span>{transaction.event?.sc?.title}</span>
                    </Col>
                    <Col sm="12">
                      <Label className="mr-3">Event Date</Label>
                      <span>
                        {moment(transaction.date, "YYYY-MM-DD").format(
                          "MMM DD, YYYY"
                        )}
                      </span>
                    </Col>
                    <Col sm="12">
                      <Label className="mr-3">Session</Label>
                      <span>
                        {
                          transaction?.event?.sessions.find(
                            session => session.id === transaction?.sessionId
                          )?.sc_name
                        }
                      </span>
                    </Col>
                    <Col sm="12">
                      <Label className="mr-3">No. of Tickets</Label>
                      <span>
                        {transaction?.items?.reduce(
                          (acc, cur) => acc + cur.quantity,
                          0
                        )}
                      </span>
                    </Col>
                    <Col sm="12">
                      <Label className="mr-3">Total Amount</Label>
                      <span>
                        {transaction?.items?.reduce(
                          (acc, cur) => acc + cur.quantity * cur.grossPrice,
                          0
                        )}
                      </span>
                    </Col>
                    <Col sm="12">
                      <Label className="mr-3">Order Status</Label>
                      <span>
                        {transactionStatus[transaction?.state]?.title}
                      </span>
                      {transaction?.state === "paid" && (
                        <Button
                          className="ml-3"
                          onClick={() => setAlertShow(true)}
                        >
                          Update to Void
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <CardTitle className="mb-3">Ticket Details</CardTitle>
                  <Row>
                    <Col sm="4">
                      <Label>Ticket Type</Label>
                    </Col>
                    <Col sm="8">
                      <Label>Code</Label>
                    </Col>
                  </Row>
                  {transaction?.items
                    ?.map(item =>
                      (item?.tickets ?? []).map(ticket => ({
                        code: ticket,
                        type: item.ticketType,
                      }))
                    )
                    ?.flat()
                    ?.map(tiketInfo => (
                      <Row key={tiketInfo.code}>
                        <Col sm="4">
                          <Label>{tiketInfo.type}</Label>
                        </Col>
                        <Col sm="8">
                          <Label>{tiketInfo.code}</Label>
                        </Col>
                      </Row>
                    ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, update to void!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={onUpdateToVoid}
        onCancel={() => setAlertShow(false)}
        focusCancelBtn
        show={alertShow}
      >
        Are you sure you want to update to void?
      </SweetAlert>
    </React.Fragment>
  )
}

const mapStateToProps = ({ articles }) => ({
  articleTabs: articles.articleTabs,
})

const mapDispatchToProps = dispatch => ({
  onGetTransactionById: (transactionId, onSuccess, onError) =>
    dispatch(
      TransactionsActions.getTransactionById({
        transactionId,
        onSuccess,
        onError,
      })
    ),
  onUpdateTransactionStatusVoid: (transactionId, onSuccess, onError) =>
    dispatch(
      TransactionsActions.updateTransactionStatusVoid({
        transactionId,
        onSuccess,
        onError,
      })
    ),
  onShowLoading: () => dispatch(LoginActions.showLoading()),
  onHideLoading: () => dispatch(LoginActions.hideLoading()),
})
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ViewTransaction)
