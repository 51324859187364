import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap"
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import { articleStatus, couponTypes } from "../../utils"

const CouponsOrderColumns = ({ handleDeleteCoupon, handleEditCoupon }) => [
  {
    dataField: "id",
    text: "ID",
    formatter: cellContent => {
      return (
        <Link to="#" className="text-body font-weight-bold">
          {cellContent}
        </Link>
      )
    },
  },
  {
    dataField: "scDetails",
    text: "Title",
    sort: true,
    formatter: cellContent => cellContent?.couponTitle,
  },
  {
    dataField: "couponType",
    text: "Type",
    sort: true,
    formatter: cellContent => couponTypes[cellContent]?.title,
  },
  {
    dataField: "sortingIndex",
    text: "Index",
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Creation Date",
    sort: true,
    formatter: cellContent => moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "expiryDate",
    text: "Expiry Date",
    sort: true,
    formatter: cellContent => moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "couponStatus",
    text: "Status",
    sort: true,
    formatter: cellContent => articleStatus[cellContent]?.title,
  },
  {
    dataField: "",
    text: "",
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <CouponDropDown
          handleEditCoupon={() => handleEditCoupon(row)}
          handleDeleteCoupon={() => handleDeleteCoupon(row.id)}
        />
      )
    },
  },
]

export default CouponsOrderColumns

const CouponDropDown = ({ handleEditCoupon, handleDeleteCoupon }) => {
  const [alertShow, setAlertShow] = useState(false)
  return (
    <>
      <UncontrolledDropdown className="dropdown">
        <DropdownToggle className="text-muted font-size-16" color="white">
          <i className="mdi mdi-dots-horizontal" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right" direction="right">
          <DropdownItem onClick={handleEditCoupon}>edit</DropdownItem>
          <DropdownItem onClick={() => setAlertShow(true)}>delete</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          handleDeleteCoupon()
          setAlertShow(false)
        }}
        onCancel={() => setAlertShow(false)}
        focusCancelBtn
        show={alertShow}
      >
        Are you sure you want to delete this coupon?
      </SweetAlert>
    </>
  )
}
