import React, {
  useReducer,
  useRef,
  useEffect,
  useCallback,
  useState,
} from "react"
import { Button, FormGroup, Label, Input, Row, Col } from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import produce from "immer"
import { v4 as uuidV4 } from "uuid"
import { useDispatch, useSelector } from "react-redux"

import { FileImageDisplayer } from "../ImageDisplayers"
import { CloseButtonWrapper } from "../Wrapper"
import {
  articleTabCategories,
  articleStatus,
  listingCategories,
} from "../../utils"
import {
  ticketingsCategories,
  ticketingsStatus,
} from "../../utils/ticketingsOptions"
import * as ArticlesActions from "../../store/articles/actions"
import * as HomepageActions from "../../store/homepage/actions"
import * as TicketingsActions from "../../store/ticketings/actions"

const modulesTypes = {
  IMAGE: "image",
}

const initialState = (defaultModulesTypesArr = []) => {
  return {
    modulesTypesArr:
      defaultModulesTypesArr.map(d => ({
        ...d,
        id: d.id.toString(),
        files: d.files || [],
        type: d.type || modulesTypes.IMAGE,
      })) || [],
  }
}

const reducer = produce((draft, action) => {
  const {
    sourceIndex,
    destinationIndex,
    moduleType,
    index,
    files,
    type,
    withUrl,
    formKey,
    formValue,
  } = action.payload
  switch (action.type) {
    case "onReorderFiles": {
      const [target] = draft.modulesTypesArr.splice(sourceIndex, 1)
      draft.modulesTypesArr.splice(destinationIndex, 0, target)
      break
    }
    case "AddModule":
      draft.modulesTypesArr.push({
        id: uuidV4(),
        type: moduleType,
        files: [],
        imageUrl: "",
        destination: "",
        articleId: "",
        category: "",
      })
      break
    case "OnUploadFile":
      draft.modulesTypesArr[index].files = files
      break
    case "OnFileClear":
      draft.modulesTypesArr[index].files = []
      if (withUrl) draft.modulesTypesArr[index].imageUrl = ""
      break
    case "OnTypeChange":
      draft.modulesTypesArr[index].files = []
      draft.modulesTypesArr[index].type = type
      draft.modulesTypesArr[index].imageUrl = ""
      break
    case "UpdateModuleForm":
      draft.modulesTypesArr[index][formKey] = formValue
      break
    case "OnDeleteModule":
      draft.modulesTypesArr.splice(sourceIndex, 1)
      break
    default:
      break
  }
})

const ImageModules = ({
  label,
  onChange,
  defaultModulesTypesArr,
  hasError,
  errorMessage,
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState(defaultModulesTypesArr)
  )
  const dispatchRedux = useDispatch()
  const isFirstRender = useRef(true)
  const { modulesTypesArr } = state
  useEffect(() => {
    dispatchRedux(HomepageActions.getHomePage({ noLoadingEffect: true }))
  }, [])
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      onChange && onChange(modulesTypesArr)
    }
  }, [modulesTypesArr])
  const handleDragEnd = result => {
    const { destination, source } = result
    if (!destination) {
      return
    }
    dispatch({
      type: "onReorderFiles",
      payload: {
        sourceIndex: source.index,
        destinationIndex: destination.index,
      },
    })
  }
  const handleAddNewModule = moduleType => {
    dispatch({ type: "AddModule", payload: { moduleType } })
  }
  const handleFileUpload = (e, index) => {
    const filesArr = [...e.target.files]
    e.target.value = ""
    dispatch({
      type: "OnUploadFile",
      payload: {
        index: index,
        files: filesArr,
      },
    })
  }
  const handleClearFile = (index, withUrl) => {
    dispatch({
      type: "OnFileClear",
      payload: {
        index: index,
        withUrl,
      },
    })
  }
  const handleTypeChange = (e, index) => {
    dispatch({
      type: "OnTypeChange",
      payload: {
        index,
        type: e.target.value,
      },
    })
  }
  const handleModuleDelete = index => {
    dispatch({
      type: "OnDeleteModule",
      payload: {
        sourceIndex: index,
      },
    })
  }
  const handleModuleUpdate = (index, formKey, formValue) => {
    dispatch({
      type: "UpdateModuleForm",
      payload: {
        index: index,
        formKey: formKey,
        formValue: formValue,
      },
    })
  }
  return (
    <div>
      {label && <Label>{label}</Label>}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="test">
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  backgroundColor: snapshot.isDraggingOver
                    ? "#f3f3f9"
                    : "white",
                }}
              >
                {modulesTypesArr.map((d, index) => {
                  return (
                    <ModuleContent
                      key={d.id}
                      id={d.id}
                      index={index}
                      module={d}
                      onFileUpload={handleFileUpload}
                      onTypeChange={handleTypeChange}
                      onFileClear={handleClearFile}
                      onDeleteModule={handleModuleDelete}
                      onModuleUpdate={handleModuleUpdate}
                      hasError={hasError}
                      errorMessage={errorMessage}
                    ></ModuleContent>
                  )
                })}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
        <Button
          className="mt-3"
          onClick={() => handleAddNewModule(modulesTypes.IMAGE)}
        >
          Add module
        </Button>
        {hasError && modulesTypesArr.length <= 0 && (
          <Col className="p-0" style={{ color: "#f46a6a" }}>
            {errorMessage}
          </Col>
        )}
      </DragDropContext>
    </div>
  )
}

export default ImageModules

const ModuleContent = ({
  id,
  index,
  module,
  onFileUpload,
  onModuleUpdate,
  onFileClear,
  hasError,
  errorMessage,
  onDeleteModule,
}) => {
  const dispatch = useDispatch()
  const homepageData = useSelector(state => state.homepage.homepageData)
  const [articleArr, setArticleArr] = useState([])
  const [ticketingArr, setTicketingArr] = useState([])
  const contentHasError =
    hasError && module.files.length <= 0 && module.imageUrl === ""
  const categoriesHasArticle = [
    articleTabCategories.dining.value,
    articleTabCategories.shop.value,
    articleTabCategories.story.value,
    articleTabCategories.event.value,
  ]
  const categoriesHasTicketing = [
    ticketingsCategories.general.value,
    ticketingsCategories.workshop.value,
    ticketingsCategories.exhibition.value,
  ]
  // useEffect(() => {
  //   dispatch(HomepageActions.getHomePage({}))
  // }, [])
  useEffect(() => {
    const categoryValue = module?.category
    if (categoriesHasArticle.includes(categoryValue)) {
      const filters = {
        "article_tab.category": categoryValue,
        status: articleStatus.published.value, // currently is published might be published and draft in the future
      }
      getArticlesByCategory(filters)
    } else if (categoriesHasTicketing.includes(categoryValue)) {
      const filters = {
        category: categoryValue,
        status: ticketingsStatus.published.value, // currently is published might be published and draft in the future
      }
      getTicketingByCategory(filters)
    }
  }, [module?.category])
  useEffect(() => {
    const categoryValue = module?.category
    const articleValue = module?.articleId
    if (listingCategories[categoryValue]) {
      onModuleUpdate(
        index,
        "destination",
        listingCategories[categoryValue].destination
      )
    } else if (categoriesHasTicketing.includes(categoryValue) && articleValue) {
      onModuleUpdate(index, "destination", `/event/event?id=${articleValue}`)
    } else if (categoriesHasArticle.includes(categoryValue) && articleValue) {
      onModuleUpdate(
        index,
        "destination",
        `/article/article?id=${articleValue}`
      )
    } else if (categoryValue === articleTabCategories.directions.value) {
      onModuleUpdate(
        index,
        "destination",
        `/article/article?id=${homepageData?.directionsID}`
      )
    } else if (categoryValue === articleTabCategories.ticketing.value) {
      onModuleUpdate(
        index,
        "destination",
        `/article/article?id=${homepageData?.ticketingID}`
      )
    } else if (categoryValue === articleTabCategories.about.value) {
      onModuleUpdate(
        index,
        "destination",
        `/article/article?id=${homepageData?.aboutMplusID}`
      )
    } else if (categoryValue === articleTabCategories.intro.value) {
      onModuleUpdate(
        index,
        "destination",
        `/article/article?id=${homepageData?.mPlusInfoID}`
      )
    } else if (categoryValue === articleTabCategories.membership.value) {
      onModuleUpdate(
        index,
        "destination",
        `/article/article?id=${homepageData?.aboutMembershipID}`
      )
    } else {
      onModuleUpdate(index, "destination", "")
    }
  }, [module?.category, module?.articleId])
  const getArticlesByCategory = async filters => {
    const res = await new Promise((resolve, reject) =>
      dispatch(
        ArticlesActions.getArticles({
          filters,
          onSuccess: resolve,
          onError: reject,
        })
      )
    )
    setArticleArr(res)
  }
  const getTicketingByCategory = async filters => {
    const res = await new Promise((resolve, reject) =>
      dispatch(
        TicketingsActions.getTicketings({
          filters: filters,
          onSuccess: resolve,
          onError: reject,
        })
      )
    )
    setTicketingArr(res)
  }
  const getModuleContent = () => {
    switch (module.type) {
      case modulesTypes.IMAGE:
        return (
          <CloseButtonWrapper onClose={() => onDeleteModule(index)}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormGroup className="m-0 mr-4">
                <Label htmlFor={`BannerImages_${id}`}>{id}</Label>
                <Input
                  id={`BannerImages_${id}`}
                  name={`BannerImages_${id}`}
                  type="file"
                  className="form-control btn btn-primary"
                  accept={`${module.type}/*`}
                  style={{ display: "none" }}
                  onChange={e => onFileUpload(e, index)}
                ></Input>
                <div>
                  <Label
                    className="btn btn-primary m-0"
                    htmlFor={`BannerImages_${id}`}
                    for={`BannerImages_${id}`}
                  >
                    Upload
                  </Label>
                </div>
              </FormGroup>
              <Row>
                <Col sm="12">
                  {module.files.length ? (
                    module.files.map((_file, _index) => (
                      <FileImageDisplayer
                        key={_index}
                        file={_file}
                        onClose={handleFileClose}
                      ></FileImageDisplayer>
                    ))
                  ) : (
                    <FileImageDisplayer
                      fileUrlObj={{ url: module.imageUrl, type: module.type }}
                      onClose={handleFileCloseUrl}
                    ></FileImageDisplayer>
                  )}
                </Col>
                <Col sm="12">
                  {contentHasError && (
                    <div style={{ color: "#F46A71" }}>{errorMessage}</div>
                  )}
                </Col>
              </Row>
            </div>
            <Row>
              <Col sm="6">
                <FormGroup className="m-0 mr-4">
                  <Label className="control-label">
                    On-Click Destination (Optional)
                  </Label>
                  <div style={{ display: "flex" }}>
                    <select
                      className="form-control select2"
                      style={{ flex: 1 }}
                      value={module?.category}
                      onChange={e => {
                        onModuleUpdate(index, "category", e.target.value)
                        onModuleUpdate(index, "articleId", "")
                      }}
                    >
                      <option value="">Select a category</option>
                      {Object.values(listingCategories).map(listingCategory => (
                        <option
                          key={listingCategory.value}
                          value={listingCategory.value}
                        >
                          {listingCategory.title}
                        </option>
                      ))}
                      {Object.values(articleTabCategories).map(category => (
                        <option key={category.value} value={category.value}>
                          {category.title}
                        </option>
                      ))}
                      {Object.values(ticketingsCategories).map(category => (
                        <option key={category.value} value={category.value}>
                          {category.title}
                        </option>
                      ))}
                    </select>
                    {categoriesHasArticle.includes(module?.category) && (
                      <div>
                        <select
                          className="form-control select2"
                          style={{ flex: 1, marginLeft: "6px" }}
                          placeholder="select a article"
                          value={module?.articleId}
                          onChange={e => {
                            onModuleUpdate(index, "articleId", e.target.value)
                          }}
                        >
                          <option value="">select an article</option>
                          {articleArr.map(article => (
                            <option key={article.id} value={article.id}>
                              {article.sc.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {categoriesHasTicketing.includes(module?.category) && (
                      <div>
                        <select
                          className="form-control select2"
                          style={{ flex: 1, marginLeft: "6px" }}
                          placeholder="select a ticket"
                          value={module?.articleId}
                          onChange={e => {
                            onModuleUpdate(index, "articleId", e.target.value)
                          }}
                        >
                          <option>Select an ticket</option>
                          {ticketingArr.map(ticket => (
                            <option key={ticket.id} value={ticket.id}>
                              {ticket.sc.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </CloseButtonWrapper>
        )
      default:
        return null
    }
  }
  const handleFileClose = useCallback(() => onFileClear(index), [index])
  const handleFileCloseUrl = useCallback(() => onFileClear(index, true), [
    index,
  ])
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          style={{
            border: snapshot.isDragging
              ? "1px solid #50A5F3"
              : contentHasError
              ? "1px solid #F46A71"
              : "1px solid #ced4da",
            borderRadius: "0.25rem",
            margin: "6px 0",
            backgroundColor: "white",
            padding: 8,
            ...provided.draggableProps.style,
          }}
        >
          {getModuleContent()}
        </div>
      )}
    </Draggable>
  )
}
