import * as AdminActionTypes from "./actionTypes"

const INIT_STATE = {
  admins: [],
  adminsTotalCount: 0,
  error: {},
  adminsMe: {},
}

const admins = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AdminActionTypes.GET_ADMINS:
      return {
        ...state,
        error: {},
      }
    case AdminActionTypes.GET_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.payload,
      }
    case AdminActionTypes.GET_ADMINS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case AdminActionTypes.GET_ADMINS_COUNT:
      return {
        ...state,
        error: {},
      }
    case AdminActionTypes.GET_ADMINS_COUNT_SUCCESS:
      return {
        ...state,
        adminsTotalCount: action.payload,
      }
    case AdminActionTypes.GET_ADMINS_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case AdminActionTypes.GET_ADMINS_ME:
      return {
        ...state,
        error: {},
      }
    case AdminActionTypes.GET_ADMINS_ME_SUCCESS:
      return {
        ...state,
        adminsMe: action.payload,
      }
    case AdminActionTypes.GET_ADMINS_ME_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default admins
