export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

export const GET_USER_BY_ID = "GET_USER_BY_ID"
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS"
export const GET_USER_BY_ID_FAIL = "GET_USER_BY_ID_FAIL"

export const EDIT_USER = "EDIT_USER"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_FAIL = "EDIT_USER_FAIL"

export const GET_USERS_COUNT = "GET_USERS_COUNT"
export const GET_USERS_COUNT_SUCCESS = "GET_USERS_COUNT_SUCCESS"
export const GET_USERS_COUNT_FAIL = "GET_USERS_COUNT_FAIL"

export const GET_USERS_REPORT = "GET_USERS_REPORT"
export const GET_USERS_REPORT_SUCCESS = "GET_USERS_REPORT_SUCCESS"
export const GET_USERS_REPORT_FAIL = "GET_USERS_REPORT_FAIL"
