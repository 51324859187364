export const GET_ADMINS = "GET_ADMINS"
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS"
export const GET_ADMINS_FAIL = "GET_ADMINS_FAIL"

export const POST_ADMIN = "POST_ADMIN"
export const POST_ADMIN_SUCCESS = "POST_ADMIN_SUCCESS"
export const POST_ADMIN_FAIL = "POST_ADMIN_FAIL"

export const DELETE_ADMIN = "DELETE_ADMIN"
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS"
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL"

export const EDIT_ADMIN = "EDIT_ADMIN"
export const EDIT_ADMIN_SUCCESS = "EDIT_ADMIN_SUCCESS"
export const EDIT_ADMIN_FAIL = "EDIT_ADMIN_FAIL"

export const GET_ADMINS_COUNT = "GET_ADMINS_COUNT"
export const GET_ADMINS_COUNT_SUCCESS = "GET_ADMINS_COUNT_SUCCESS"
export const GET_ADMINS_COUNT_FAIL = "GET_ADMINS_COUNT_FAIL"

export const GET_ADMINS_ME = "GET_ADMINS_ME"
export const GET_ADMINS_ME_SUCCESS = "GET_ADMINS_ME_SUCCESS"
export const GET_ADMINS_ME_FAIL = "GET_ADMINS_ME_FAIL"
