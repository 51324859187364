import { takeEvery, put, takeLatest, all } from "redux-saga/effects"
import * as TicketingsActionTypes from "./actionTypes"
import * as TicketingsActions from "./actions"
import * as LoginActions from "../login/actions"
import { axiosMp } from "../../utils"
import querystring from "querystring"

function* fetchTicketings({ payload: { filters, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(queries ? `/events?${queries}` : `/events`)
    yield put(TicketingsActions.getTicketingsSuccess(res.data.results))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(TicketingsActions.getTicketingsFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* getTicketingById({ payload: { ticketingId, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.get(`/events/${ticketingId}`)
    yield put(TicketingsActions.getTicketingByIdSuccess(res.data))
    onSuccess && onSuccess(res.data)
  } catch (error) {
    yield put(TicketingsActions.getTicketingByIdFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* postTicketing({ payload: { ticketing, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.post(`/events`, ticketing)
    yield put(TicketingsActions.postTicketingSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(TicketingsActions.postTicketingFail(error))
    onError && onError(error)
  }
}

function* deleteTicketing({ payload: { ticketingId, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.delete(`/events/${ticketingId}`)
    yield put(TicketingsActions.deleteTicketingSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(TicketingsActions.deleteTicketingFail(error))
    onError && onError()
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* editTicketing({
  payload: { ticketingId, ticketing, onSuccess, onError },
}) {
  try {
    const res = yield axiosMp.put(`/events/${ticketingId}`, ticketing)
    yield put(TicketingsActions.editTicketingSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(TicketingsActions.editTicketingFail(error))
    onError && onError(error)
  }
}

function* getTicketingsCount({ payload: { filters, onSuccess, onError } }) {
  try {
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/events/count?${queries}` : `/events/count`
    )
    yield put(TicketingsActions.getTicketingsCountSuccess(res.data.count))
    onSuccess && onSuccess(res.data.count)
  } catch (error) {
    yield put(TicketingsActions.getTicketingsCountFail(error))
    onError && onError(error)
  }
}

function* generateInitialTickets({
  payload: { ticketingId, onSuccess, onError },
}) {
  try {
    const res = yield axiosMp.put(`/events/${ticketingId}/ticket-settings`)
    yield put(TicketingsActions.generateTicketingsSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(TicketingsActions.generateTicketingsFail(error))
    onError && onError(error)
  }
}

function* extendTickets({
  payload: { ticketingId, ticketSetting, onSuccess, onError },
}) {
  try {
    const res = yield axiosMp.post(
      `/events/${ticketingId}/ticket-settings`,
      ticketSetting
    )
    yield put(TicketingsActions.extendTicketingsSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(TicketingsActions.extendTicketingsFail(error))
    onError && onError(error)
  }
}

function* fetchQuotas({ payload: { filters, onSuccess, onError } }) {
  try {
    console.log(`filters`, filters)
    yield put(LoginActions.showLoading())
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(`/quotas?${queries}`)
    yield put(TicketingsActions.getTicketingQuotasSuccess(res.data.results))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(TicketingsActions.getTicketingQuotasFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* getTicketingQuotasCount({
  payload: { filters, onSuccess, onError },
}) {
  try {
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/quotas/count?${queries}` : `/quotas/count`
    )
    console.log(`res`, res)
    yield put(TicketingsActions.getTicketingQuotasCountSuccess(res.data.count))
    onSuccess && onSuccess(res.data.count)
  } catch (error) {
    yield put(TicketingsActions.getTicketingQuotasCountFail(error))
    onError && onError(error)
  }
}

function* editQuotas({ payload: { quotas, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.put(`/quotas`, quotas)
    yield put(TicketingsActions.editTicketingQuotasSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(TicketingsActions.editTicketingQuotasFail(error))
    onError && onError(error)
  }
}

function* fetchMultipleQuotas({ payload: { filtersArr, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const results = yield all(
      filtersArr.map(filters => {
        const queries = querystring.stringify(filters)
        return axiosMp.get(`/quotas?${queries}`)
      })
    )
    console.log("!!!results", results)
    const resObj = filtersArr.reduce((acc, cur, index) => {
      acc[cur.eventId] = results[index].data.results
      return acc
    }, {})
    yield put(TicketingsActions.getMultipleTicketingQuotasSuccess(resObj))
    onSuccess && onSuccess(resObj)
  } catch (error) {
    yield put(TicketingsActions.getMultipleTicketingQuotasFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* ticketingsSaga() {
  yield takeEvery(TicketingsActionTypes.GET_TICKETINGS, fetchTicketings)
  yield takeEvery(TicketingsActionTypes.GET_TICKETING_BY_ID, getTicketingById)
  yield takeLatest(TicketingsActionTypes.POST_TICKETING, postTicketing)
  yield takeEvery(TicketingsActionTypes.DELETE_TICKETING, deleteTicketing)
  yield takeEvery(TicketingsActionTypes.EDIT_TICKETING, editTicketing)
  yield takeEvery(
    TicketingsActionTypes.GET_TICKETINGS_COUNT,
    getTicketingsCount
  )
  yield takeEvery(
    TicketingsActionTypes.GENERATE_TICKETINGS,
    generateInitialTickets
  )
  yield takeEvery(TicketingsActionTypes.EXTEND_TICKETINGS, extendTickets)
  yield takeLatest(TicketingsActionTypes.GET_TICKETING_QUOTAS, fetchQuotas)
  yield takeEvery(TicketingsActionTypes.EDIT_TICKETING_QUOTAS, editQuotas)
  yield takeLatest(
    TicketingsActionTypes.GET_TICKETING_QUOTAS_COUNT,
    getTicketingQuotasCount
  )
  yield takeLatest(
    TicketingsActionTypes.GET_MULTIPLE_TICKETING_QUOTAS,
    fetchMultipleQuotas
  )
}

export default ticketingsSaga
