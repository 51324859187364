import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap"
import SweetAlert from "react-bootstrap-sweetalert"

import { articleTabCategories } from "../../utils"

const ArticleTabsOrderColumns = ({
  handleDeleteArticleTab,
  handleEditArticleTab,
}) => [
  {
    dataField: "id",
    text: "ID",
    formatter: cellContent => {
      return (
        <Link to="#" className="text-body font-weight-bold">
          {cellContent}
        </Link>
      )
    },
  },
  {
    dataField: "sc",
    text: "Title",
    sort: true,
    formatter: cellContent => cellContent,
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
    formatter: cellContent => articleTabCategories[cellContent]?.title,
  },
  {
    dataField: "sortIndex",
    text: "Index",
    sort: true,
  },
  {
    dataField: "",
    text: "",
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <ArticleDropDown
          handleEditArticleTab={() => handleEditArticleTab(row)}
          handleDeleteArticleTab={() => handleDeleteArticleTab(row.id)}
        />
      )
    },
  },
]

export default ArticleTabsOrderColumns

const ArticleDropDown = ({ handleEditArticleTab, handleDeleteArticleTab }) => {
  const [alertShow, setAlertShow] = useState(false)
  return (
    <>
      <UncontrolledDropdown className="dropdown">
        <DropdownToggle className="text-muted font-size-16" color="white">
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right" direction="right">
          <DropdownItem onClick={handleEditArticleTab}>edit</DropdownItem>
          <DropdownItem onClick={() => setAlertShow(true)}>delete</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          handleDeleteArticleTab()
          setAlertShow(false)
        }}
        onCancel={() => setAlertShow(false)}
        focusCancelBtn
        show={alertShow}
      >
        Are you sure you want to delete this article tab?
      </SweetAlert>
    </>
  )
}
