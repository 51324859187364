import React, { useRef } from "react"
import {
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap"

const PasscodeModal = ({ isOpen, onConfirm, onCancel }) => {
  const inputEl = useRef(null)
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <FormGroup>
          <Label className="control-label">Passcode</Label>
          <Input
            name="passcode"
            type="text"
            className="form-control"
            innerRef={inputEl}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            onConfirm?.(inputEl.current.value)
            onCancel?.()
          }}
        >
          Confirm
        </Button>{" "}
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default PasscodeModal
