import { takeEvery, put } from "redux-saga/effects"
import * as HomepageActionTypes from "./actionTypes"
import * as HomepageActions from "./actions"
import * as LoginActions from "../login/actions"
import { axiosMp } from "../../utils"

function* getHomepage({ payload: { onSuccess, onError, noLoadingEffect } }) {
  try {
    if (!noLoadingEffect) {
      yield put(LoginActions.showLoading())
    }
    const res = yield axiosMp.get(`/_/homepage`)
    yield put(HomepageActions.getHomePageSuccess(res.data))
    onSuccess && onSuccess(res.data)
  } catch (error) {
    yield put(HomepageActions.getHomePageFail(error))
    onError && onError(error)
  } finally {
    if (!noLoadingEffect) {
      yield put(LoginActions.hideLoading())
    }
  }
}

function* editHomepage({ payload: { homepage, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.put(`/_/homepage`, homepage)
    yield put(HomepageActions.editHomePageSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(HomepageActions.editHomePageFail(error))
    onError && onError(error)
  }
}

function* homepageSaga() {
  yield takeEvery(HomepageActionTypes.GET_HOMEPAGE, getHomepage)
  yield takeEvery(HomepageActionTypes.EDIT_HOMEPAGE, editHomepage)
}

export default homepageSaga
