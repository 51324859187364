import React, { useReducer, useRef, useEffect, useCallback } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import produce from "immer"
import { v4 as uuidV4 } from "uuid"

import { FileImageDisplayer } from "../ImageDisplayers"
import { CloseButtonWrapper } from "../Wrapper"

const modulesTypes = {
  IMAGE: "image",
  VIDEO: "video",
}

const initialState = (defaultModulesTypesArr = []) => ({
  modulesTypesArr:
    defaultModulesTypesArr.map(d => ({
      ...d,
      id: d.id.toString(),
      files: d.files || [],
    })) || [],
  modalVisible: false,
})

const reducer = produce((draft, action) => {
  const {
    sourceIndex,
    destinationIndex,
    moduleType,
    index,
    files,
    type,
    withUrl,
  } = action.payload
  switch (action.type) {
    case "onReorderFiles": {
      const [target] = draft.modulesTypesArr.splice(sourceIndex, 1)
      draft.modulesTypesArr.splice(destinationIndex, 0, target)
      break
    }
    case "ToogleModal":
      draft.modalVisible = !draft.modalVisible
      break
    case "AddModule":
      draft.modulesTypesArr.push({
        id: uuidV4(),
        type: moduleType,
        files: [],
        url: "",
      })
      break
    case "OnUploadFile":
      draft.modulesTypesArr[index].files = files
      break
    case "OnFileClear":
      draft.modulesTypesArr[index].files = []
      if (withUrl) draft.modulesTypesArr[index].url = ""
      break
    case "OnTypeChange":
      draft.modulesTypesArr[index].files = []
      draft.modulesTypesArr[index].type = type
      draft.modulesTypesArr[index].url = ""
      break
    case "OnDeleteModule":
      draft.modulesTypesArr.splice(sourceIndex, 1)
      break
    default:
      break
  }
})

const ImageVideoModules = ({
  label,
  onChange,
  defaultModulesTypesArr,
  hasError,
  errorMessage,
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState(defaultModulesTypesArr)
  )
  const isFirstRender = useRef(true)
  const { modulesTypesArr, modalVisible } = state
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      onChange && onChange(modulesTypesArr)
    }
  }, [modulesTypesArr])
  const handleDragEnd = result => {
    const { destination, source } = result
    if (!destination) {
      return
    }
    dispatch({
      type: "onReorderFiles",
      payload: {
        sourceIndex: source.index,
        destinationIndex: destination.index,
      },
    })
  }
  const handleModalToogle = () => {
    dispatch({ type: "ToogleModal", payload: {} })
  }
  const handleAddNewModule = moduleType => {
    dispatch({ type: "AddModule", payload: { moduleType } })
  }
  const handleFileUpload = (e, index) => {
    const filesArr = [...e.target.files]
    e.target.value = ""
    dispatch({
      type: "OnUploadFile",
      payload: {
        index: index,
        files: filesArr,
      },
    })
  }
  const handleClearFile = (index, withUrl) => {
    dispatch({
      type: "OnFileClear",
      payload: {
        index: index,
        withUrl,
      },
    })
  }
  const handleTypeChange = (e, index) => {
    dispatch({
      type: "OnTypeChange",
      payload: {
        index,
        type: e.target.value,
      },
    })
  }
  const handleModuleDelete = index => {
    dispatch({
      type: "OnDeleteModule",
      payload: {
        sourceIndex: index,
      },
    })
  }
  return (
    <div>
      {label && <Label>{label}</Label>}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="test">
          {(provided, snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  backgroundColor: snapshot.isDraggingOver
                    ? "#f3f3f9"
                    : "white",
                }}
              >
                {modulesTypesArr.map((d, index) => {
                  return (
                    <ModuleContent
                      key={d.id}
                      id={d.id}
                      index={index}
                      module={d}
                      onFileUpload={handleFileUpload}
                      onTypeChange={handleTypeChange}
                      onFileClear={handleClearFile}
                      onDeleteModule={handleModuleDelete}
                      hasError={hasError}
                      errorMessage={errorMessage}
                    ></ModuleContent>
                  )
                })}
                {provided.placeholder}
              </div>
            )
          }}
        </Droppable>
        <Button className="mt-3" onClick={handleModalToogle}>
          Add module
        </Button>
        {hasError && modulesTypesArr.length <= 0 && (
          <Col className="p-0" style={{ color: "#f46a6a" }}>
            {errorMessage}
          </Col>
        )}
        <ModuleModal
          isOpen={modalVisible}
          onAdd={handleAddNewModule}
          onCancel={handleModalToogle}
        ></ModuleModal>
      </DragDropContext>
    </div>
  )
}

export default ImageVideoModules

const ModuleContent = ({
  id,
  index,
  module,
  onFileUpload,
  onTypeChange,
  onFileClear,
  hasError,
  errorMessage,
  onDeleteModule,
}) => {
  const contentHasError =
    hasError && module.files.length <= 0 && module.url === ""
  const getModuleContent = () => {
    switch (module.type) {
      case modulesTypes.IMAGE:
      case modulesTypes.VIDEO:
        return (
          <CloseButtonWrapper onClose={() => onDeleteModule(index)}>
            <FormGroup>
              <Label htmlFor={`BannerImages_${id}`}>{id}</Label>
              <Input
                id={`BannerImages_${id}`}
                name={`BannerImages_${id}`}
                type="file"
                className="form-control btn btn-primary"
                accept={`${module.type}/*`}
                style={{ display: "none" }}
                onChange={e => onFileUpload(e, index)}
              ></Input>
              <Row>
                <Col sm="6">
                  <select
                    className="form-control select2"
                    value={module.type}
                    onChange={e => onTypeChange(e, index)}
                  >
                    <option value="image">image</option>
                    <option value="video">video</option>
                  </select>
                </Col>
                <Label
                  className="btn btn-primary"
                  htmlFor={`BannerImages_${id}`}
                  for={`BannerImages_${id}`}
                >
                  Upload
                </Label>
                <Col sm="12">
                  {module.files.length ? (
                    module.files.map((_file, _index) => (
                      <FileImageDisplayer
                        key={_index}
                        file={_file}
                        onClose={handleFileClose}
                      ></FileImageDisplayer>
                    ))
                  ) : (
                    <FileImageDisplayer
                      fileUrlObj={{ url: module.url, type: module.type }}
                      onClose={handleFileCloseUrl}
                    ></FileImageDisplayer>
                  )}
                </Col>
                <Col sm="12">
                  {contentHasError && (
                    <div style={{ color: "#F46A71" }}>{errorMessage}</div>
                  )}
                </Col>
              </Row>
            </FormGroup>
          </CloseButtonWrapper>
        )
      default:
        return null
    }
  }
  const handleFileClose = useCallback(() => onFileClear(index), [index])
  const handleFileCloseUrl = useCallback(() => onFileClear(index, true), [
    index,
  ])
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          style={{
            border: snapshot.isDragging
              ? "1px solid #50A5F3"
              : contentHasError
              ? "1px solid #F46A71"
              : "1px solid #ced4da",
            borderRadius: "0.25rem",
            margin: "6px 0",
            backgroundColor: "white",
            padding: 8,
            ...provided.draggableProps.style,
          }}
        >
          {getModuleContent()}
        </div>
      )}
    </Draggable>
  )
}

const ModuleModal = ({ isOpen, onAdd, onCancel }) => {
  const selectEl = useRef(null)
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <FormGroup>
          <Label className="control-label">Module type</Label>
          <select className="form-control select2" ref={selectEl}>
            {Object.keys(modulesTypes).map(moduleType => (
              <option
                key={modulesTypes[moduleType]}
                value={modulesTypes[moduleType]}
              >
                {moduleType}
              </option>
            ))}
          </select>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            onAdd(selectEl.current.value)
            onCancel()
          }}
        >
          Add
        </Button>{" "}
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
