import React from "react"

const CloseButton = ({ onClose, style }) => {
  return (
    <div
      style={{
        fontSize: "24px",
        borderRadius: "50%",
        backgroundColor: "red",
        width: 25,
        height: 25,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        cursor: "pointer",
        ...style,
      }}
      onClick={onClose}
    >
      <i className="bx bx-x"></i>
    </div>
  )
}

export default CloseButton
