//--------------------Article associated--------------------------------
export const GET_ARTICLES = "GET_ARTICLES"
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS"
export const GET_ARTICLES_FAIL = "GET_ARTICLES_FAIL"

export const GET_ARTICLE_BY_ID = "GET_ARTICLE_BY_ID"
export const GET_ARTICLE_BY_ID_SUCCESS = "GET_ARTICLE_BY_ID_SUCCESS"
export const GET_ARTICLE_BY_ID_FAIL = "GET_ARTICLE_BY_ID_FAIL"

export const POST_ARTICLE = "POST_ARTICLE"
export const POST_ARTICLE_SUCCESS = "POST_ARTICLE_SUCCESS"
export const POST_ARTICLE_FAIL = "POST_ARTICLE_FAIL"

export const DELETE_ARTICLE = "DELETE_ARTICLE"
export const DELETE_ARTICLE_SUCCESS = "DELETE_ARTICLE_SUCCESS"
export const DELETE_ARTICLE_FAIL = "DELETE_ARTICLE_FAIL"

export const EDIT_ARTICLE = "EDIT_ARTICLE"
export const EDIT_ARTICLE_SUCCESS = "EDIT_ARTICLE_SUCCESS"
export const EDIT_ARTICLE_FAIL = "EDIT_ARTICLE_FAIL"

export const GET_ARTICLES_COUNT = "GET_ARTICLES_COUNT"
export const GET_ARTICLES_COUNT_SUCCESS = "GET_ARTICLES_COUNT_SUCCESS"
export const GET_ARTICLES_COUNT_FAIL = "GET_ARTICLES_COUNT_FAIL"

//--------------------ArticleTab associated--------------------------------
export const GET_ARTICLE_TAB = "GET_ARTICLE_TAB"
export const GET_ARTICLE_TAB_SUCCESS = "GET_ARTICLE_TAB_SUCCESS"
export const GET_ARTICLE_TAB_FAIL = "GET_ARTICLE_TAB_FAIL"

export const GET_ARTICLE_TAB_BY_ID = "GET_ARTICLE_TAB_BY_ID"
export const GET_ARTICLE_TAB_BY_ID_SUCCESS = "GET_ARTICLE_TAB_BY_ID_SUCCESS"
export const GET_ARTICLE_TAB_BY_ID_FAIL = "GET_ARTICLE_TAB_BY_ID_FAIL"

export const POST_ARTICLE_TAB = "POST_ARTICLE_TAB"
export const POST_ARTICLE_TAB_SUCCESS = "POST_ARTICLE_TAB_SUCCESS"
export const POST_ARTICLE_TAB_FAIL = "POST_ARTICLE_TAB_FAIL"

export const DELETE_ARTICLE_TAB = "DELETE_ARTICLE_TAB"
export const DELETE_ARTICLE_TAB_SUCCESS = "DELETE_ARTICLE_TAB_SUCCESS"
export const DELETE_ARTICLE_TAB_FAIL = "DELETE_ARTICLE_TAB_FAIL"

export const EDIT_ARTICLE_TAB = "EDIT_ARTICLE_TAB"
export const EDIT_ARTICLE_TAB_SUCCESS = "EDIT_ARTICLE_TAB_SUCCESS"
export const EDIT_ARTICLE_TAB_FAIL = "EDIT_ARTICLE_TAB_FAIL"

export const GET_ARTICLE_TAB_COUNT = "GET_ARTICLE_TAB_COUNT"
export const GET_ARTICLE_TAB_COUNT_SUCCESS = "GET_ARTICLE_TAB_COUNT_SUCCESS"
export const GET_ARTICLE_TAB_COUNT_FAIL = "GET_ARTICLE_TAB_COUNT_FAIL"
