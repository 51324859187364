import React, { useEffect, useReducer, useState } from "react"
import { Container, Row, Col, Card, CardBody, Label, Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import produce from "immer"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import { NotificationContainer, NotificationManager } from "react-notifications"

import * as LoginActions from "../../store/login/actions"
import * as TicketingsActions from "../../store/ticketings/actions"
import QuotasOrderColumns from "./QuotasOrderColumns"
import { ticketTypes } from "utils/ticketingsOptions"

const initialState = {
  currentPage: 1,
  sizePerPage: 10,
  eventId: null,
  sessionName: "",
  ticketType: "",
  startDate: null,
  endDate: null,
  quotasState: [],
}

const reducer = produce((draft, action) => {
  const { page, value, key } = action.payload || {}
  console.log(`type!!`, action.type)
  console.log(`value!!`, value)
  switch (action.type) {
    case "onPrevPage":
      draft.currentPage = draft.currentPage - 1 || 1
      break
    case "OnSpecificPage":
      draft.currentPage = page
      break
    case "OnChange":
      draft[key] = value
      break
    case "OnEventIdChange":
      draft.eventId = value
      break
    case "OnSessionNameChange":
      draft.sessionName = value
      break
    case "OnTicketTypeChange":
      draft.ticketType = value
      break
    case "OnInputChange":
    case "OnDateChange": {
      const formattedDate = moment(value?.valueOf()).format("YYYY-MM-DD")
      draft[key] = !value ? null : formattedDate
      break
    }
    case "OnQuotasInputChange": {
      const index = draft.quotasState.findIndex(item => {
        console.log(`item._id`, item._id)
        console.log(`key`, key)
        return item._id === key
      })
      console.log(`index`, index)
      draft.quotasState[index].quota = parseInt(value)
      break
    }
    default:
      break
  }
})

const EditTicketQuotas = ({ match }) => {
  const { params } = match
  const [ticketing, setTicketing] = useState(undefined)
  const title = ticketing?.sc?.title //location.state?.ticketingTitle
  const sessions = ticketing?.sessions || [] //location.state?.sessions || []
  const dispatch = useDispatch()
  const [state, dispatchToReducer] = useReducer(reducer, initialState)
  const { quotas, quotasTotalCount } = useSelector(state => state.ticketings)
  const { quotasState } = state
  const pageOptions = {
    page: state.currentPage,
    sizePerPage: state.sizePerPage,
    totalSize: quotasTotalCount,
    custom: true,
    onPageChange: page =>
      dispatchToReducer({ type: "OnSpecificPage", payload: { page } }),
  }
  console.log("[EditTicketQuotas] state", state)
  console.log("[EditTicketQuotas] quotasState", quotasState)
  const sessionScNames = sessions.map(session => session.sc_name) || []
  const uniqueSessionScNames = [...new Set(sessionScNames)]
  // const sessionIds = quotas?.map(({ sessionId }) => sessionId) || []
  // const uniqueSessionIds = [...new Set(sessionIds)]

  useEffect(async () => {
    if (params.id) {
      const ticketRes = await new Promise((resolve, reject) =>
        dispatch(
          TicketingsActions.getTicketingById({
            ticketingId: params.id,
            onSuccess: resolve,
            onError: reject,
          })
        )
      )
      setTicketing(ticketRes)
    }
  }, [])

  useEffect(() => {
    const eventId = ticketing?.id //location.state?.ticketingId
    handleChange(eventId, "eventId")
    getQuotasAndQuotasCount()
  }, [state.currentPage, state.eventId, ticketing])

  useEffect(() => {
    handleChange(quotas, "quotasState")
  }, [quotas])

  const getQuotasAndQuotasCount = resetToPage1 => {
    if (!state.eventId) return
    if (resetToPage1 && state.currentPage !== 1) {
      dispatchToReducer({ type: "OnSpecificPage", payload: { page: 1 } })
      return
    }
    let matchSessions = []
    sessions?.forEach(session => {
      if (session.sc_name === state.sessionName) {
        matchSessions.push(session.id)
      }
    })
    console.log(
      "🚀 ~ file: edit-ticket-quotas.js ~ line 51 ~ reducer ~ matchSessions",
      matchSessions
    )
    const quotasCountFilters = {
      ...(state.eventId && { eventId: state.eventId }),
      ...(matchSessions.length && { sessionId: matchSessions[0] }), //TODO: if more than 1 session
      ...(state.ticketType && { ticketType: state.ticketType }),
      ...(state.startDate && {
        startDate: moment(state.startDate).format("YYYY-MM-DD"),
      }),
      ...(state.endDate && {
        endDate: moment(state.endDate).format("YYYY-MM-DD"),
      }),
    }
    const quotasFilters = {
      _limit: state.sizePerPage,
      _start: (state.currentPage - 1) * state.sizePerPage,
      ...quotasCountFilters,
    }
    dispatch(TicketingsActions.getTicketingQuotas({ filters: quotasFilters }))
    dispatch(
      TicketingsActions.getTicketingQuotasCount({ filters: quotasCountFilters })
    )
  }

  const handleChange = (value, key) => {
    dispatchToReducer({
      type: "OnChange",
      payload: {
        key,
        value,
      },
    })
  }

  const handleSelectChange = (e, key) => {
    dispatchToReducer({
      type: key,
      payload: {
        value: e.target.value,
      },
    })
  }

  const handleDateChange = (val, key) => {
    dispatchToReducer({
      type: "OnDateChange",
      payload: {
        value: val,
        key: key,
      },
    })
  }

  const handleOptionChange = (e, key) => {
    dispatchToReducer({
      type: key,
      payload: {
        value: e.target.value,
      },
    })
  }

  const handleQuotasInputChange = (value, key) => {
    console.log(`[handleQuotasInputChange] value`, value)
    console.log(`[handleQuotasInputChange] key`, key)
    dispatchToReducer({
      type: "OnQuotasInputChange",
      payload: {
        value,
        key,
      },
    })
  }

  const handleUpdateAllQuotas = () => {
    try {
      dispatch(LoginActions.showLoading())
      const transformedQuotas = quotasState.map(quotaObj => ({
        _id: quotaObj._id,
        quota: parseInt(quotaObj.quota),
      }))
      dispatch(
        TicketingsActions.editTicketingQuotas({ quotas: transformedQuotas })
      )
      dispatch(LoginActions.hideLoading())
      NotificationManager.success("Quotas updated", "Success")
    } catch (err) {
      dispatch(LoginActions.hideLoading())
      NotificationManager.error("Fail to upadte quotas", "Error", 3000)
    }
  }

  return (
    <React.Fragment>
      <NotificationContainer />
      <div className="page-content">
        <Container fluid>
          <h4>Edit Ticketing Quotas</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="m-0">
                    <Label className="font-size-14 d-flex align-items-center">
                      Ticketing Title:
                    </Label>
                    <Label className="font-size-20 d-flex align-items-center ml-2 pb-1">
                      {title}
                    </Label>
                  </Row>
                  <Row className="mb-4">
                    <Col md="1">
                      <Label className="mb-3">Filter :</Label>
                    </Col>
                    <Col md="3">
                      <select
                        className="form-control select2 mb-3"
                        value={state.sessionName}
                        onChange={e =>
                          handleOptionChange(e, "OnSessionNameChange")
                        }
                      >
                        <option value="">Session</option>
                        {uniqueSessionScNames?.map(name => (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col md="2">
                      <select
                        className="form-control select2 mb-3"
                        value={state.status}
                        onChange={e =>
                          handleSelectChange(e, "OnTicketTypeChange")
                        }
                      >
                        <option value="">Ticket Type</option>
                        {ticketTypes.map(type => (
                          <option key={type} value={type.toLowerCase()}>
                            {type}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col md="2">
                      <DatePicker
                        selected={
                          state.startDate
                            ? new Date(moment(state.startDate)?.valueOf())
                            : null
                        }
                        onChange={val => {
                          handleDateChange(val, "startDate")
                        }}
                        className="form-control"
                        placeholderText="Start date"
                        isClearable
                      />
                    </Col>

                    <Col md="2">
                      <DatePicker
                        minDate={new Date(state.startDate)}
                        selected={
                          state.endDate
                            ? new Date(moment(state.endDate)?.valueOf())
                            : null
                        }
                        onChange={val => {
                          console.log(`val`, val)
                          handleDateChange(val, "endDate")
                        }}
                        className="form-control"
                        placeholderText="End date"
                        isClearable
                      />
                    </Col>

                    <Col md="2">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md mb-3"
                        onClick={() => getQuotasAndQuotasCount(true)}
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={quotas}
                        columns={QuotasOrderColumns({
                          sessions,
                          handleQuotasInputChange,
                        })}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    bodyClasses={"border-bottom"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={() =>
                                      console.log("onTable CHange????")
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="d-flex justify-content-end mb-4">
                              <Button onClick={() => handleUpdateAllQuotas()}>
                                Update All Quotas of this page by Batch
                              </Button>
                            </div>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EditTicketQuotas
