import PropTypes from "prop-types"
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { compose } from "redux"

import { AvForm, AvField } from "availity-reactstrap-validation"

import * as LoginActions from "../../store/login/actions"
import * as AdminsActions from "../../store/admins/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

const Login = props => {
  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    await new Promise((resolve, reject) =>
      props.onSubmitLogin(values, resolve, reject)
    )
    const response = await new Promise((resolve, reject) =>
      props.onGetAdminsMe(resolve, reject)
    )
    switch (response.type) {
      case "ticketing":
        props.history.push("/coupons")
        return
      case "super":
        props.history.push("/articles")
        return
      case "normal":
        props.history.push("/articles")
        return
      default:
        props.history.push("/articles")
        return
    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to MPlus.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                            width="60"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error &&
                      typeof props.error.message === "string" ? (
                        <Alert color="danger">{props.error.message}</Alert>
                      ) : null}

                      <div className="form-group">
                        <AvField
                          name="email"
                          label="Email"
                          // value="matchman@redso.com.hk"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="form-group">
                        <AvField
                          name="password"
                          label="Password"
                          // value="testtest"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.mplusLogin
  return { error }
}
const mapDispatchToProps = dispatch => ({
  onSubmitLogin: (loginInfo, onSuccess, onError) =>
    dispatch(LoginActions.postLogin({ loginInfo, onSuccess, onError })),
  onGetAdminsMe: (onSuccess, onError) =>
    dispatch(AdminsActions.getAdminsMe({ onSuccess, onError })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Login)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
