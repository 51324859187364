import * as HomePageActionTypes from "./actionTypes"

const INIT_STATE = {
  homepageData: {},
  error: {},
}

const homepage = (state = INIT_STATE, action) => {
  switch (action.type) {
    case HomePageActionTypes.GET_HOMEPAGE:
      return {
        ...state,
        error: {},
        homepageData: {},
      }
    case HomePageActionTypes.GET_HOMEPAGE_SUCCESS:
      return {
        ...state,
        homepageData: action.payload,
      }
    case HomePageActionTypes.GET_HOMEPAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default homepage
