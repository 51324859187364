export const UPLOAD_IMAGES = "UPLOAD_IMAGES"
export const UPLOAD_IMAGES_SUCCESS = "UPLOAD_IMAGES_SUCCESS"
export const UPLOAD_IMAGES_FAIL = "UPLOAD_IMAGES_FAIL"

export const UPLOAD_VIDEOS = "UPLOAD_VIDEOS"
export const UPLOAD_VIDEOS_SUCCESS = "UPLOAD_VIDEOS_SUCCESS"
export const UPLOAD_VIDEOS_FAIL = "UPLOAD_VIDEOS_FAIL"

export const UPLOAD_IMAGES_AND_VIDEOS = "UPLOAD_IMAGES_AND_VIDEOS"
export const UPLOAD_IMAGES_AND_VIDEOS_SUCCESS =
  "UPLOAD_IMAGES_AND_VIDEOS_SUCCESS"
export const UPLOAD_IMAGES_AND_VIDEOS_FAIL = "UPLOAD_IMAGES_AND_VIDEOS_FAIL"
