import React, { useEffect, useReducer, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  FormFeedback,
} from "reactstrap"
import { connect } from "react-redux"
import { compose } from "redux"
import produce from "immer"
import { Link, withRouter } from "react-router-dom"
import _ from "lodash"
import { v4 as uuidV4 } from "uuid"

import * as LoginActions from "../../store/login/actions"
import * as ArticlesActions from "../../store/articles/actions"
import * as FileActions from "../../store/file/actions"
import { articleTabCategories, articleStatus } from "../../utils"
import { ImageVideoModules, ContentModules } from "../../components/DnDModules"
import { FileImageDisplayer } from "../../components/ImageDisplayers"
import { NotificationContainer, NotificationManager } from "react-notifications"
import { PasscodeModal } from "../../components/Modals"

const Tabs = {
  TC: "tc",
  SC: "sc",
  EN: "en",
}

const startsWithSC = key => key.startsWith(Tabs.SC)

const startsWithLang = key =>
  key.startsWith(Tabs.EN) || key.startsWith(Tabs.SC) || key.startsWith(Tabs.TC)

const startsWithContentModules = key => key.startsWith("_contentModules", 2)

const settingEditArticleToState = editArticle => {
  const defaultFormValue = produce(editArticle, draftArticle => {
    Object.keys(draftArticle).forEach(key => {
      const keysToignore = ["updated_at", "created_at", "id"]
      // keys to ignore
      if (keysToignore.includes(key)) {
        delete draftArticle[key]
      }
      // mormal keys
      else if (!startsWithLang(key)) {
        if (key === "article_tab") {
          draftArticle[key] = {
            value: draftArticle[key].id,
            hasError: false,
            errorMessage: "",
          }
        } else {
          draftArticle[key] = {
            value: draftArticle[key],
            hasError: false,
            errorMessage: "",
          }
        }
      }
      // keys that is lang
      else if (startsWithLang(key) && !startsWithContentModules(key)) {
        Object.keys(draftArticle[key]).forEach(
          _key =>
            (draftArticle[key][_key] = {
              value: draftArticle[key][_key],
              hasError: false,
              errorMessage: "",
            })
        )
      }
      // keys that is _contentModules
      else if (startsWithContentModules(key)) {
        draftArticle[key].forEach(_module => {
          Object.keys(_module).forEach(_key => {
            const specialKeys = ["__component", "id"]
            _module[_key] = specialKeys.includes(_key)
              ? _module[_key]
              : {
                  value: _module[_key],
                  hasError: false,
                  errorMessage: "",
                }
          })
        })
      }
    })
  })
  const defaultCategory = editArticle.article_tab.category
  return {
    ...initialState,
    formValue: defaultFormValue,
    selectedCategory: defaultCategory,
    validateID: uuidV4(),
  }
}

const initialState = {
  formValue: {
    specialFlag: {
      value: null,
      hasError: false,
      errorMessage: "",
    },
    category_sortIndex: {
      value: 100,
      hasError: false,
      errorMessage: "",
    },
    article_tab: {
      value: 1,
      hasError: false,
      errorMessage: "",
    },
    featured_homepage: {
      value: true,
      hasError: false,
      errorMessage: "",
    },
    featured_homepage_sortIndex: {
      value: 100,
      hasError: false,
      errorMessage: "",
    },
    featured_eventPage_sortIndex: {
      value: 100,
      hasError: false,
      errorMessage: "",
    },
    featured_eventPage: {
      value: false,
      hasError: false,
      errorMessage: "",
    },
    status: {
      value: "draft",
      hasError: false,
      errorMessage: "",
    },
    en: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      shortDescription: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      thumbnailUrl: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_location: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_openingHours: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_website: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_phone: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_body: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_show: {
        value: true,
        hasError: false,
        errorMessage: "",
      },
      banner: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
    },
    sc: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      shortDescription: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      thumbnailUrl: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_location: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_openingHours: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_website: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_phone: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_body: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_show: {
        value: true,
        hasError: false,
        errorMessage: "",
      },
      banner: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
    },
    tc: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      shortDescription: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      thumbnailUrl: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_location: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_openingHours: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_website: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bizInfo_phone: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_body: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_show: {
        value: true,
        hasError: false,
        errorMessage: "",
      },
      banner: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
    },
    en_contentModules: [],
    sc_contentModules: [],
    tc_contentModules: [],
  },
  activeTab: Tabs.SC,
  selectedCategory: undefined,
  files: {},
  contentModuleFiles: {},
  validateID: uuidV4(),
  passcodeModalVisible: false,
}

const reducer = produce((draft, action) => {
  const { value, key, assignType } = action.payload
  console.log("!!!!", action.type, "!!!!!")
  const isValidkey =
    draft.formValue[key] || draft.formValue[draft.activeTab][key]
  switch (action.type) {
    case "OnInputChange":
    case "OnOptionChange":
    case "OnBannerValueChange":
    case "OnCheckboxChange":
      if (isValidkey) {
        if (draft.formValue[key]) {
          draft.formValue[key].value = value
          draft.formValue[key].hasError = false
          if (key === "featured_eventPage" || key === "featured_homepage") {
            draft.formValue[`${key}_sortIndex`].hasError = false
          }
        } else {
          draft.formValue[draft.activeTab][key].value = value
          draft.formValue[draft.activeTab][key].hasError = false
        }
      }
      break
    case "OnTabChange":
      draft.activeTab = value
      break
    case "OnCopyPress":
      Object.values(Tabs)
        .filter(tab => tab !== draft.activeTab) // tabs that are not currenet tab
        .forEach(tab => {
          // copy formValue to other tabs
          draft.formValue[tab] = draft.formValue[draft.activeTab]
          draft.formValue[`${tab}_contentModules`] =
            draft.formValue[`${draft.activeTab}_contentModules`]
          // copying files to other tabs
          Object.keys(draft.files)
            .filter(_key => _key.startsWith(draft.activeTab))
            .forEach(_key => {
              draft.files[_key.replace(draft.activeTab, tab)] = {
                ...draft.files[_key],
                key: _key.replace(draft.activeTab, tab),
              }
            })
          // copying content module files to other tabs
          Object.keys(draft.contentModuleFiles)
            .filter(_key => _key.startsWith(draft.activeTab))
            .forEach(_key => {
              draft.contentModuleFiles[_key.replace(draft.activeTab, tab)] = {
                ...draft.contentModuleFiles[_key],
                key: _key.replace(draft.activeTab, tab),
              }
            })
        })
      break
    case "OnContentModuleChange":
      draft.formValue[`${draft.activeTab}_contentModules`] = value
      break
    case "OnContentModuleFilesChange":
      // clearing current lang tab contentModuleFiles
      Object.keys(draft.contentModuleFiles).forEach(_key => {
        if (_key.startsWith(`${draft.activeTab}_contentModules`))
          delete draft.contentModuleFiles[_key]
      })
      // putting current lang tab contentModuleFiles
      draft.contentModuleFiles = {
        ...draft.contentModuleFiles,
        ...Object.values(value).reduce((acc, cur) => {
          return {
            ...acc,
            [`${draft.activeTab}_contentModules${cur.key}`]: {
              ...cur,
              key: `${draft.activeTab}_contentModules${cur.key}`,
            },
          }
        }, {}),
      }
      break
    case "OnSelectCategory":
      draft.selectedCategory = value
      // reset some fields
      Object.values(Tabs).forEach(tab => {
        draft.formValue[tab].bizInfo_location.value = ""
        draft.formValue[tab].bizInfo_openingHours.value = ""
        draft.formValue[tab].bizInfo_website.value = ""
        draft.formValue[tab].bizInfo_phone.value = ""
        draft.formValue[tab].bizInfo_location.hasError = false
        draft.formValue[tab].bizInfo_openingHours.hasError = false
        draft.formValue[tab].bizInfo_website.hasError = false
        draft.formValue[tab].bizInfo_phone.hasError = false
      })
      break
    case "OnUploadImage":
      draft.files[key] = {
        files: value,
        key,
        assignType,
        type: "images",
      }
      _.set(draft.formValue, `${key}.hasError`, false)
      break
    case "OnClearImage":
      delete draft.files[key]
      break
    case "OnUpdateFormValue":
      draft.formValue = value
      draft.validateID = uuidV4()
      break
    case "OnPasscodeModalToogle":
      draft.passcodeModalVisible = value
      break
    case "OnSetAllState":
      return value
  }
})

const CreateArticle = props => {
  const { articleTabs, match } = props
  const { path, params } = match
  const isEditMode = path === "/article/:id/edit"
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    formValue,
    activeTab,
    selectedCategory,
    validateID,
    passcodeModalVisible,
  } = state
  const originArticleRes = useRef(null)

  useEffect(async () => {
    props.onShowLoading()
    const res = await new Promise((resolve, reject) =>
      props.onGetArticleTabs(undefined, true, resolve, reject)
    )
    if (res.length && !isEditMode) {
      // default selecting category and article tab
      dispatch({
        type: "OnSelectCategory",
        payload: { value: res[0].category },
      })
      dispatch({
        type: "OnOptionChange",
        payload: {
          value: res[0].id,
          key: "article_tab",
        },
      })
    }
    if (isEditMode && params.id) {
      const articleRes = await new Promise((resolve, reject) =>
        props.onGetArticleById(params.id, resolve, reject)
      )
      originArticleRes.current = articleRes
      dispatch({
        type: "OnSetAllState",
        payload: { value: settingEditArticleToState(articleRes) },
      })
    }
    props.onHideLoading()
  }, [])
  const availableCategories = articleTabs.reduce((acc, cur) => {
    if (!acc.find(cat => cat.value === cur.category)) {
      acc.push({ ...articleTabCategories[cur.category] })
    }
    return acc
  }, [])
  console.log("state", state)

  const handleCheckBoxChange = (e, key) => {
    dispatch({
      type: "OnCheckboxChange",
      payload: {
        value: e.target.checked,
        key: key,
      },
    })
  }
  const handleOptionChange = (e, key) => {
    dispatch({
      type: "OnOptionChange",
      payload: {
        value: e.target.value === "" ? null : e.target.value,
        key: key,
      },
    })
  }
  const handleInputChange = (e, key) => {
    dispatch({
      type: "OnInputChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }
  const handleCategoryChange = e => {
    dispatch({
      type: "OnSelectCategory",
      payload: { value: e.target.value },
    })
    dispatch({
      type: "OnOptionChange",
      payload: {
        value: articleTabs.filter(aTab => aTab.category === e.target.value)[0]
          .id,
        key: "article_tab",
      },
    })
  }
  const hadleTabChange = tabLang => {
    dispatch({
      type: "OnTabChange",
      payload: {
        value: tabLang,
      },
    })
  }
  const handleCopyPress = () => {
    dispatch({ type: "OnCopyPress", payload: {} })
  }
  const getDefaultContentFilesObj = () => {
    const { contentModuleFiles, activeTab } = state
    return Object.values(contentModuleFiles).reduce((acc, cur) => {
      if (cur.key.startsWith(`${activeTab}_contentModules`)) {
        acc[cur.key.replace(`${activeTab}_contentModules`, "")] = {
          ...cur,
          key: cur.key.replace(`${activeTab}_contentModules`, ""),
        }
      }
      return acc
    }, {})
  }
  const getDefaultBannerImageVideoModules = () => {
    return formValue[activeTab].banner.value.map((_banner, _index) => {
      return {
        ..._banner,
        files:
          state.files[`${activeTab}.banner`]?.files[_index] instanceof File
            ? [state.files[`${activeTab}.banner`].files[_index]]
            : [],
      }
    })
  }
  const handleContentModuleChange = contentModule => {
    dispatch({
      type: "OnContentModuleChange",
      payload: {
        value: contentModule,
      },
    })
  }
  const handleContentModuleFilesChange = contentModuleFiles => {
    dispatch({
      type: "OnContentModuleFilesChange",
      payload: {
        value: contentModuleFiles,
      },
    })
  }
  const handleBannerImageVideoModulesFilesChange = (
    filesWithType,
    key,
    assignType
  ) => {
    dispatch({
      type: "OnUploadImage",
      payload: {
        value: filesWithType
          .map(_files =>
            _files.files.length
              ? _files.files
              : { url: _files.url, type: _files.type }
          )
          .flat(),
        key,
        assignType: assignType,
      },
    })
    dispatch({
      type: "OnBannerValueChange",
      payload: {
        value: filesWithType.map(_files => ({
          id: _files.id,
          type: _files.type,
          url: _files.url,
        })),
        key: "banner",
      },
    })
  }
  const handleImageUpload = (e, key, assignType) => {
    const filesArr = [...e.target.files]
    if (filesArr.length) {
      dispatch({
        type: "OnUploadImage",
        payload: {
          value: filesArr,
          key,
          assignType: assignType, // onlyValue arrayWithType
        },
      })
    } else {
      handleClearImage(key)
    }
  }
  const handleClearImage = (key, isFormValue) => {
    if (isFormValue) {
      dispatch({
        type: "OnInputChange",
        payload: {
          value: "",
          key: key,
        },
      })
    } else {
      dispatch({
        type: "OnClearImage",
        payload: {
          key,
        },
      })
    }
  }

  const handlePreSave = () => {
    if (!validateFields()) return
    const { formValue } = state
    if (
      formValue.status.value === "published" &&
      originArticleRes.current?.status !== "published"
    ) {
      dispatch({
        type: "OnPasscodeModalToogle",
        payload: {
          value: true,
        },
      })
    } else {
      handleSavePress()
    }
  }

  const onClosePasscode = () => {
    dispatch({
      type: "OnPasscodeModalToogle",
      payload: {
        value: false,
      },
    })
  }

  const handleSavePress = async passcode => {
    const { formValue } = state
    const {
      onPostArticle,
      onEditArticle,
      history,
      onShowLoading,
      onHideLoading,
    } = props
    if (!validateFields()) return
    onShowLoading()
    const imageUploadRes = await UploadFilesToCloud()
    console.log("imageUploadRes", imageUploadRes)
    const submitForm = produce(formValue, draftFormValue => {
      // 1.putting image url to form value
      imageUploadRes.forEach(_res => {
        if (_res.assignType === "onlyValue") {
          _.set(draftFormValue, `${_res.key}.value`, _res.value[0].url)
        } else {
          _.set(draftFormValue, `${_res.key}.value`, _res.value)
        }
      })
      // 2.flatten the form values
      Object.keys(draftFormValue).forEach(key => {
        // normal keys
        if (!startsWithLang(key)) {
          if (
            (key === "featured_eventPage_sortIndex" &&
              draftFormValue[key].value === "") ||
            (key === "featured_homepage_sortIndex" &&
              draftFormValue[key].value === "")
          ) {
            draftFormValue[key] = 0
          } else {
            draftFormValue[key] = draftFormValue[key].value
          }
        }
        // keys that is lang
        if (startsWithLang(key) && !startsWithContentModules(key)) {
          Object.keys(draftFormValue[key]).forEach(_key => {
            draftFormValue[key][_key] = draftFormValue[key][_key].value
            if (_key === "id") delete draftFormValue[key][_key]
            if (_key === "banner") {
              draftFormValue[key][_key].forEach(_banner => delete _banner.id)
            }
          })
        }
        // keys that is _contentModules
        if (startsWithContentModules(key)) {
          draftFormValue[key].forEach(_module => {
            Object.keys(_module).forEach(_key => {
              const specialKeys = ["__component", "id"]
              _module[_key] = specialKeys.includes(_key)
                ? _module[_key]
                : _module[_key].value
              if (_key === "id") delete _module[_key]
            })
          })
        }
      })
      // 3. adding passcode
      draftFormValue.passcode = passcode
    })
    try {
      if (isEditMode) {
        await new Promise((resolve, reject) =>
          onEditArticle(params.id, submitForm, resolve, reject)
        )
      } else {
        await new Promise((resolve, reject) =>
          onPostArticle(submitForm, resolve, reject)
        )
      }
      onHideLoading()
      history.push("/articles")
    } catch (err) {
      console.log(err)
      onHideLoading()
      NotificationManager.error(
        isEditMode ? "Save Article Failed" : "Create Article Failed",
        "Error",
        3000
      )
    }
  }
  const UploadFilesToCloud = async () => {
    // uploading files to cloud
    const filesArr = Object.values(state.files)
    const resArr = []
    for (let file of filesArr) {
      const res = await new Promise((resolve, reject) =>
        props.onUploadImagesAndVideos(file.files, resolve, reject)
      )
      resArr.push({
        value: res,
        key: file.key,
        assignType: file.assignType,
      })
    }
    // uploading content module files to cloud
    const contentModuleFilesArr = Object.values(state.contentModuleFiles)
    for (let cfile of contentModuleFilesArr) {
      const res = await new Promise((resolve, reject) =>
        props.onUploadImagesAndVideos(cfile.files, resolve, reject)
      )
      resArr.push({
        value: res,
        key: cfile.key,
        assignType: cfile.assignType,
      })
    }
    console.log("!!!!resArr!!!!", resArr)
    return resArr
  }
  const validateFields = () => {
    const { formValue, files, contentModuleFiles } = state
    let isAllValidate = true
    // console.log("validateFields formValue", formValue)
    const newForm = produce(formValue, draftFormValue => {
      Object.keys(draftFormValue).forEach(key => {
        // normal keys
        if (!startsWithLang(key)) {
          if (key === "specialFlag") {
            // do nothing
          } else if (
            (key === "featured_eventPage_sortIndex" &&
              !draftFormValue.featured_eventPage.value) ||
            (key === "featured_homepage_sortIndex" &&
              !draftFormValue.featured_homepage.value)
          ) {
            draftFormValue[key].hasError = false
          } else if (
            _.isNil(draftFormValue[key].value) ||
            draftFormValue[key].value === ""
          ) {
            draftFormValue[key].hasError = true
            draftFormValue[key].errorMessage = "Field cant be empty"
            isAllValidate = false
          }
        }
        // keys that is lang,
        if (
          startsWithLang(key) &&
          !startsWithContentModules(key) &&
          startsWithSC(key) // (currntly only validate sc other lang can bypass)
        ) {
          Object.keys(draftFormValue[key]).forEach(_key => {
            // console.log(key, _key, current(draftFormValue[key][_key]))
            if (_key === "thumbnailUrl") {
              if (
                (draftFormValue[key][_key].value === "" ||
                  _.isNil(draftFormValue[key][_key].value)) &&
                !files[`${key}.${_key}`]?.files.length
              ) {
                draftFormValue[key][_key].hasError = true
                draftFormValue[key][_key].errorMessage = "Field cant be empty"
                isAllValidate = false
              }
            } else if (_key === "banner") {
              const allBannerHasUrlOrFile =
                draftFormValue[key][_key].value.reduce((acc, cur, _index) => {
                  const isUrlEmpty = cur.url === "" || _.isNil(cur.url)
                  const isFileEmpty = !(
                    files[`${key}.${_key}`]?.files[_index] instanceof File
                  )
                  return acc && !(isUrlEmpty && isFileEmpty)
                }, true) && draftFormValue[key][_key].value.length > 0
              if (!allBannerHasUrlOrFile) {
                draftFormValue[key][_key].hasError = true
                draftFormValue[key][_key].errorMessage = "Field cant be empty"
                isAllValidate = false
              }
            }
            // else if (_key.startsWith("bizInfo_")) {
            //   if (
            //     (draftFormValue[key][_key].value === "" ||
            //       _.isNil(draftFormValue[key][_key].value)) &&
            //     articleTabCategories[selectedCategory]?.businessInfo
            //   ) {
            //     draftFormValue[key][_key].hasError = true
            //     draftFormValue[key][_key].errorMessage = "Field cant be empty"
            //     isAllValidate = false
            //   }
            // }
            else if (
              (_key === "notice_body" &&
                !draftFormValue[key].notice_show.value) ||
              (_key === "notice_title" &&
                !draftFormValue[key].notice_show.value)
            ) {
              draftFormValue[key][_key].hasError = false
            } else if (
              _key === "id" ||
              _key.startsWith("bizInfo_") ||
              _key === "shortDescription"
            ) {
              // do nothing
            } else {
              if (
                draftFormValue[key][_key].value === "" ||
                _.isNil(draftFormValue[key][_key].value)
              ) {
                draftFormValue[key][_key].hasError = true
                draftFormValue[key][_key].errorMessage = "Field cant be empty"
                isAllValidate = false
              }
            }
          })
        }
        // keys that is _contentModules
        if (
          startsWithContentModules(key) &&
          startsWithSC(key) // (currntly only validate sc other lang can bypass)
        ) {
          draftFormValue[key].forEach((_module, _index) => {
            Object.keys(_module).forEach(_key => {
              const specialKeys = [
                "__component",
                "id",
                "category",
                "appId",
                "appPath",
                "articleId",
                "destination",
              ]
              if (!specialKeys.includes(_key)) {
                // console.log(_key, current(_module[_key]))
                const keysNeedToCheckFile = ["imageUrl", "videoUrl"]
                const keysNeedToCheckCssColor = ["textColor", "backgroundColor"]
                const keysNeedToCheckFloat = ["lat", "long"]
                if (
                  _module[_key].value === "" ||
                  _.isNil(_module[_key].value)
                ) {
                  if (keysNeedToCheckFile.includes(_key)) {
                    // keys in _contentModules need to check have file or not
                    if (
                      !contentModuleFiles[`${key}[${_index}].${_key}`]?.files
                        .length
                    ) {
                      _module[_key].hasError = true
                      _module[_key].errorMessage = "Need to upload file"
                      isAllValidate = false
                    }
                  } else {
                    // normal keys in _contentModules
                    _module[_key].hasError = true
                    _module[_key].errorMessage = "Field cant be empty"
                    isAllValidate = false
                  }
                } else if (keysNeedToCheckFloat.includes(_key)) {
                  const floatRegex = /^[0-9]*\.?[0-9]*$/
                  const result = floatRegex.test(_module[_key].value)
                  if (!result) {
                    _module[_key].hasError = true
                    _module[_key].errorMessage = "Invalid value"
                    isAllValidate = false
                  }
                } else if (keysNeedToCheckCssColor.includes(_key)) {
                  const cssColorRegex = /^#[0-9a-fA-F]{6}$/
                  const result = cssColorRegex.test(_module[_key].value)
                  if (!result) {
                    _module[_key].hasError = true
                    _module[_key].errorMessage = "Color is invalid"
                    isAllValidate = false
                  }
                }
              }
            })
          })
        }
      })
    })
    dispatch({ type: "OnUpdateFormValue", payload: { value: newForm } })
    // console.log("newForm", newForm)
    return isAllValidate
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>{isEditMode ? "Edit Article" : "Create Article"}</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Featuring</CardTitle>
                  <Row className="mb-3">
                    <Col sm="6">
                      <LabelCheckBox
                        label="On Home Page"
                        id="featured_homepage"
                        value={"featured_homepage"}
                        checked={formValue.featured_homepage.value}
                        onChange={e =>
                          handleCheckBoxChange(e, "featured_homepage")
                        }
                      ></LabelCheckBox>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="featured_homepage_sortIndex">
                          Home page Sorting Index
                        </Label>
                        <Input
                          name="featured_homepage_sortIndex"
                          type="text"
                          className="form-control"
                          value={formValue.featured_homepage_sortIndex.value}
                          onChange={e =>
                            handleInputChange(e, "featured_homepage_sortIndex")
                          }
                          invalid={
                            formValue.featured_homepage_sortIndex.hasError
                          }
                        />
                        <FormFeedback>
                          {formValue.featured_homepage_sortIndex.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <CardTitle className="mb-3">Publishing Control</CardTitle>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">Special Flag</Label>
                        <select
                          className="form-control select2"
                          value={formValue.specialFlag.value}
                          onChange={e => {
                            handleOptionChange(e, "specialFlag")
                          }}
                        >
                          <option value={""}>none</option>
                          <option value="extraFeeRequired">
                            extraFeeRequired
                          </option>
                          <option value="includedInGeneralAdmissionTicket">
                            includedInGeneralAdmissionTicket
                          </option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">Category</Label>
                        <select
                          className="form-control select2"
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                        >
                          {availableCategories.map(category => (
                            <option key={category.value} value={category.value}>
                              {category.title}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="category_sortIndex">
                          Category Sorting Index
                        </Label>
                        <Input
                          name="category_sortIndex"
                          type="text"
                          className="form-control"
                          value={formValue.category_sortIndex.value}
                          onChange={e => {
                            handleInputChange(e, "category_sortIndex")
                          }}
                          invalid={formValue.category_sortIndex.hasError}
                        />
                        <FormFeedback>
                          {formValue.category_sortIndex.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">Article Tab</Label>
                        <select
                          className="form-control select2"
                          value={formValue.article_tab.value}
                          onChange={e => handleOptionChange(e, "article_tab")}
                        >
                          {articleTabs
                            .filter(aTab => aTab.category === selectedCategory)
                            .map(aTab => (
                              <option key={aTab.id} value={aTab.id}>
                                {aTab.sc}
                              </option>
                            ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">Article Status</Label>
                        <select
                          className="form-control select2"
                          value={formValue.status.value}
                          onChange={e => handleOptionChange(e, "status")}
                        >
                          {Object.values(articleStatus).map(as => (
                            <option key={as.value} value={as.value}>
                              {as.title}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Nav tabs>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.SC}
                    onClick={() => hadleTabChange(Tabs.SC)}
                  >
                    <i className="bx bx-chat font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">簡體中文</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.TC}
                    onClick={() => hadleTabChange(Tabs.TC)}
                  >
                    <i className="bx bx-group font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">繁體中文</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.EN}
                    onClick={() => hadleTabChange(Tabs.EN)}
                  >
                    <i className="bx bx-book-content font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">English</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <Card>
                <CardBody>
                  <Row className="justify-content-between m-0 mb-4">
                    <CardTitle className="mb-3">General</CardTitle>
                    <Button onClick={handleCopyPress}>
                      Copy to other lang
                    </Button>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="title">Title</Label>
                        <Input
                          name="title"
                          type="text"
                          className="form-control"
                          value={formValue[activeTab].title.value}
                          onChange={e => {
                            handleInputChange(e, "title")
                          }}
                          invalid={formValue[activeTab].title.hasError}
                        />
                        <FormFeedback>
                          {formValue[activeTab].title.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {/* <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="shortDescription">
                          Short Description
                        </Label>
                        <Input
                          name="shortDescription"
                          type="text"
                          className="form-control"
                          value={formValue[activeTab].shortDescription.value}
                          onChange={e => {
                            handleInputChange(e, "shortDescription")
                          }}
                          invalid={
                            formValue[activeTab].shortDescription.hasError
                          }
                        />
                        <FormFeedback>
                          {formValue[activeTab].shortDescription.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col> */}
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="ThumbnailImage">Thumbnail Image</Label>
                        <Input
                          id="ThumbnailImage"
                          name="ThumbnailImage"
                          type="file"
                          className="form-control btn btn-primary"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={e =>
                            handleImageUpload(
                              e,
                              `${activeTab}.thumbnailUrl`,
                              "onlyValue"
                            )
                          }
                        ></Input>
                        <Col className="p-0">
                          <Label
                            className="btn btn-primary"
                            htmlFor="ThumbnailImage"
                            for="ThumbnailImage"
                          >
                            Upload
                          </Label>
                        </Col>
                        <Col className="p-0">
                          {state.files[`${activeTab}.thumbnailUrl`] ? (
                            state.files[
                              `${activeTab}.thumbnailUrl`
                            ]?.files.map((_file, _index) => (
                              <FileImageDisplayer
                                key={_index}
                                file={_file}
                                onClose={() =>
                                  handleClearImage(`${activeTab}.thumbnailUrl`)
                                }
                              ></FileImageDisplayer>
                            ))
                          ) : (
                            <FileImageDisplayer
                              fileUrlObj={{
                                url: formValue[activeTab].thumbnailUrl.value,
                                type: "image",
                              }}
                              onClose={() =>
                                handleClearImage("thumbnailUrl", true)
                              }
                            ></FileImageDisplayer>
                          )}
                        </Col>
                        {formValue[activeTab].thumbnailUrl.hasError && (
                          <Col className="p-0" style={{ color: "#f46a6a" }}>
                            {formValue[activeTab].thumbnailUrl.errorMessage}
                          </Col>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm="12" className="mb-3">
                      <ImageVideoModules
                        key={`${activeTab}_${validateID}`}
                        label="Banner Images or Videos (max 6 files)"
                        onChange={filesWithType =>
                          handleBannerImageVideoModulesFilesChange(
                            filesWithType,
                            `${activeTab}.banner`,
                            "arrayWithType"
                          )
                        }
                        defaultModulesTypesArr={getDefaultBannerImageVideoModules()}
                        hasError={formValue[activeTab].banner.hasError}
                        errorMessage={formValue[activeTab].banner.errorMessage}
                      ></ImageVideoModules>
                    </Col>
                  </Row>
                  {articleTabCategories[selectedCategory]?.businessInfo && (
                    <>
                      <CardTitle className="mb-3">Business Info</CardTitle>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="Location">Location</Label>
                            <Input
                              name="Location"
                              type="text"
                              className="form-control"
                              value={
                                formValue[activeTab].bizInfo_location.value
                              }
                              onChange={e => {
                                handleInputChange(e, "bizInfo_location")
                              }}
                              invalid={
                                formValue[activeTab].bizInfo_location.hasError
                              }
                            />
                            <FormFeedback>
                              {
                                formValue[activeTab].bizInfo_location
                                  .errorMessage
                              }
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="OpeningHours">Opening Hours</Label>
                            <Input
                              name="OpeningHours"
                              type="text"
                              className="form-control"
                              value={
                                formValue[activeTab].bizInfo_openingHours.value
                              }
                              onChange={e => {
                                handleInputChange(e, "bizInfo_openingHours")
                              }}
                              invalid={
                                formValue[activeTab].bizInfo_openingHours
                                  .hasError
                              }
                            />
                            <FormFeedback>
                              {
                                formValue[activeTab].bizInfo_openingHours
                                  .errorMessage
                              }
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="bizInfo_website">Website</Label>
                            <Input
                              name="bizInfo_website"
                              type="text"
                              className="form-control"
                              value={formValue[activeTab].bizInfo_website.value}
                              onChange={e => {
                                handleInputChange(e, "bizInfo_website")
                              }}
                              invalid={
                                formValue[activeTab].bizInfo_website.hasError
                              }
                            />
                            <FormFeedback>
                              {
                                formValue[activeTab].bizInfo_website
                                  .errorMessage
                              }
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <Label htmlFor="bizInfo_phone">Phone</Label>
                            <Input
                              name="bizInfo_phone"
                              type="text"
                              className="form-control"
                              value={formValue[activeTab].bizInfo_phone.value}
                              onChange={e => {
                                handleInputChange(e, "bizInfo_phone")
                              }}
                              invalid={
                                formValue[activeTab].bizInfo_phone.hasError
                              }
                            />
                            <FormFeedback>
                              {formValue[activeTab].bizInfo_phone.errorMessage}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}

                  <CardTitle className="mb-3">Special notice</CardTitle>
                  <Row className="m-0 mb-3">
                    <LabelCheckBox
                      label="Show notice"
                      id="notice_show"
                      checked={formValue[activeTab].notice_show.value}
                      onChange={e => handleCheckBoxChange(e, "notice_show")}
                    ></LabelCheckBox>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="notice_title">Notice Title</Label>
                        <Input
                          name="notice_title"
                          type="text"
                          className="form-control"
                          value={formValue[activeTab].notice_title.value}
                          onChange={e => {
                            handleInputChange(e, "notice_title")
                          }}
                          invalid={formValue[activeTab].notice_title.hasError}
                        />
                        <FormFeedback>
                          {formValue[activeTab].notice_title.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="notice_body">Notice Body</Label>
                        <Input
                          name="notice_body"
                          type="text"
                          className="form-control"
                          value={formValue[activeTab].notice_body.value}
                          onChange={e => {
                            handleInputChange(e, "notice_body")
                          }}
                          invalid={formValue[activeTab].notice_body.hasError}
                        />
                        <FormFeedback>
                          {formValue[activeTab].notice_body.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <ContentModules
                    key={`${activeTab}_${validateID}`}
                    defaultModulesTypesArr={
                      formValue[`${activeTab}_contentModules`]
                    }
                    defaultModulesFileObj={getDefaultContentFilesObj()}
                    onChange={handleContentModuleChange}
                    onFilesChange={handleContentModuleFilesChange}
                  ></ContentModules>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <NotificationContainer />
          <div>
            <Button
              className="mr-3 w-md"
              color="primary"
              onClick={handlePreSave}
            >
              Save
            </Button>
            <Link to="/articles" className="btn btn-secondary mr-2 w-md">
              Cancel
            </Link>
          </div>
          <PasscodeModal
            isOpen={passcodeModalVisible}
            onConfirm={handleSavePress}
            onCancel={onClosePasscode}
          ></PasscodeModal>
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = ({ articles }) => ({
  articleTabs: articles.articleTabs,
})

const mapDispatchToProps = dispatch => ({
  onGetArticleById: (articleId, onSuccess, onError) =>
    dispatch(ArticlesActions.getArticleById({ articleId, onSuccess, onError })),
  onGetArticleTabs: (filter, noLoadingEffect, onSuccess, onError) =>
    dispatch(
      ArticlesActions.getArticleTab({
        filter,
        noLoadingEffect,
        onSuccess,
        onError,
      })
    ),
  onPostArticle: (article, onSuccess, onError) =>
    dispatch(ArticlesActions.postArticle({ article, onSuccess, onError })),
  onEditArticle: (articleId, article, onSuccess, onError) =>
    dispatch(
      ArticlesActions.editArticle({ articleId, article, onSuccess, onError })
    ),
  onUploadImage: (images, onSuccess, onError) =>
    dispatch(FileActions.uploadImages({ images, onSuccess, onError })),
  onUploadVideos: (videos, onSuccess, onError) =>
    dispatch(FileActions.uploadVideos({ videos, onSuccess, onError })),
  onUploadImagesAndVideos: (files, onSuccess, onError) =>
    dispatch(FileActions.uploadImagesAndVideos({ files, onSuccess, onError })),
  onShowLoading: () => dispatch(LoginActions.showLoading()),
  onHideLoading: () => dispatch(LoginActions.hideLoading()),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CreateArticle)

const LabelCheckBox = ({ id, label, value, onChange, checked }) => {
  return (
    <Row className="align-items-center m-0">
      <h5 className="text-truncate font-size-14 m-0 mr-2">{label}</h5>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          onChange={onChange}
          value={value}
          checked={checked}
        />
        <label className="custom-control-label" htmlFor={id} />
      </div>
    </Row>
  )
}
