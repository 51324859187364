import React, { useState } from "react"
import { Input, Button, Row } from "reactstrap"
import moment from "moment"
import { capitalizeFirstLetter } from "../../utils/formatStrings"
import { useDispatch } from "react-redux"
import { NotificationContainer, NotificationManager } from "react-notifications"

import * as TicketingsActions from "../../store/ticketings/actions"
import * as LoginActions from "../../store/login/actions"

const QuotasOrderColumns = ({ sessions, handleQuotasInputChange }) => [
  {
    dataField: "date",
    text: "Date",
    sort: true,
    editable: false,
    formatter: cellContent => moment(cellContent).format("MM/DD/YYYY"),
  },
  {
    dataField: "sessionId",
    text: "Session",
    sort: true,
    editable: false,
    formatter: cellContent => {
      const found = sessions.find(session => session.id == cellContent)
      return found?.sc_name || ""
    },
  },
  {
    dataField: "ticketType",
    text: "Ticket Type",
    sort: true,
    editable: false,
    formatter: cellContent => capitalizeFirstLetter(cellContent),
  },
  {
    dataField: "price",
    text: "Price (HKD)",
    editable: false,
    sort: true,
  },
  {
    dataField: "quota",
    text: "Quota",
    sort: true,
    formatter: (cellContent, row) => (
      <QuotaInputNUpdate
        init={cellContent}
        row={row}
        handleQuotasInputChange={handleQuotasInputChange}
      />
    ),
  },
]

export default QuotasOrderColumns

const QuotaInputNUpdate = ({ init, row, handleQuotasInputChange }) => {
  const [input, setState] = useState(init)
  const dispatch = useDispatch()

  const handleUpdate = (_id, quota) => {
    try {
      dispatch(LoginActions.showLoading())
      dispatch(
        TicketingsActions.editTicketingQuotas({
          quotas: [{ _id, quota }],
        })
      )
      dispatch(LoginActions.hideLoading())
      NotificationManager.success("Quotas updated", "Success")
    } catch (err) {
      NotificationManager.error("Fail to upadte quotas", "Error", 3000)
      dispatch(LoginActions.hideLoading())
    }
  }

  return (
    <Row key={row._id}>
      <NotificationContainer />
      <Input
        name="quota"
        type="number"
        className="form-control d-flex align-items-center m-0 mr-3 w-75"
        value={input}
        onChange={e => {
          if (parseInt(e.target.value) < 0) return
          setState(e.target.value)
          handleQuotasInputChange(e.target.value, row._id)
        }}
      />
      <Button className="" onClick={() => handleUpdate(row._id, input)}>
        Update
      </Button>
    </Row>
  )
}
