import React from "react"
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"

const QrcodeModal = ({ isOpen, onCancel, qrcodeBase64 }) => {
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <img src={`data:image/jpeg;base64,${qrcodeBase64}`} />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default QrcodeModal
