import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"
import ecommerceSaga from "./e-commerce/saga"
import calendarSaga from "./calendar/saga"
import chatSaga from "./chat/saga"
import cryptoSaga from "./crypto/saga"
import invoiceSaga from "./invoices/saga"
import projectsSaga from "./projects/saga"
import tasksSaga from "./tasks/saga"

// article
import articlesSaga from "./articles/saga"
import loginSaga from "./login/saga"
import filesSaga from "./file/saga"
import homepageSaga from "./homepage/saga"
import couponsSaga from "./coupons/saga"
import ticketingsSaga from "./ticketings/saga"
import transactionsSaga from "./transactions/saga"
import usersSaga from "./users/saga"
import adminSaga from "./admins/saga"

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    // articles
    fork(articlesSaga),
    fork(loginSaga),
    fork(filesSaga),
    fork(homepageSaga),
    fork(couponsSaga),
    fork(ticketingsSaga),
    fork(transactionsSaga),
    fork(usersSaga),
    fork(adminSaga),
  ])
}
