import React, { useReducer, useState, useEffect, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  FormFeedback,
} from "reactstrap"
import { useDispatch } from "react-redux"
import produce, { current, original } from "immer"
import { Link } from "react-router-dom"
import _ from "lodash"
import { v4 as uuidV4 } from "uuid"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import { NotificationContainer, NotificationManager } from "react-notifications"

import * as TicketingsActions from "../../store/ticketings/actions"
import * as LoginActions from "../../store/login/actions"
import * as FileActions from "../../store/file/actions"
import { CloseButtonWrapper } from "components/Wrapper"
import { FileImageDisplayer } from "../../components/ImageDisplayers"
import { ImageVideoModules, ContentModules } from "../../components/DnDModules"
import SpecialNotice from "../../components/SpecialNotice"
import TicketSession from "../../components/Ticketings/TicketSession"
import TicketPriceNQuotosInput from "../../components/Ticketings/TicketPriceNQuotosInput"
import ExtendTickets from "../../components/Ticketings/ExtendTickets"
import {
  daysOfWeek,
  ticketingsCategories,
  ticketingsSpecialFlag,
  ticketingsStatus,
  ticketTypes,
} from "utils/ticketingsOptions"
import LabelCheckBox from "components/LabelCheckBox"
import { CloseButton } from "components/Buttons"
import { PasscodeModal } from "../../components/Modals"

const Tabs = {
  TC: "tc",
  SC: "sc",
  EN: "en",
}

const startsWithSC = key => key.startsWith(Tabs.SC)

const isLangKey = key => key === Tabs.EN || key === Tabs.SC || key === Tabs.TC

const startsWithContentModules = key => key.startsWith("_contentModules", 2)

const session = {
  en_name: {
    value: "",
    hasError: false,
    errorMessage: "",
  },
  tc_name: {
    value: "",
    hasError: false,
    errorMessage: "",
  },
  sc_name: {
    value: "",
    hasError: false,
    errorMessage: "",
  },
  sortIndex: {
    value: 100,
    hasError: false,
    errorMessage: "",
  },
}

const ticketSettingsObject = {
  blackoutDaysOfWeek: [],
  blackoutDates: [],
  pricesAndQuotas: {
    adult: {
      value: {
        weekday: {
          quota: 0,
          price: 0,
        },
        weekend: {
          quota: 0,
          price: 0,
        },
      },
      checked: false,
      hasError: false,
      errorMessage: "",
    },
    child: {
      value: {
        weekday: {
          quota: 0,
          price: 0,
        },
        weekend: {
          quota: 0,
          price: 0,
        },
      },
      checked: false,
      hasError: false,
      errorMessage: "",
    },
    disables: {
      value: {
        weekday: {
          quota: 0,
          price: 0,
        },
        weekend: {
          quota: 0,
          price: 0,
        },
      },
      checked: false,
      hasError: false,
      errorMessage: "",
    },
    elderly: {
      value: {
        weekday: {
          quota: 0,
          price: 0,
        },
        weekend: {
          quota: 0,
          price: 0,
        },
      },
      checked: false,
      hasError: false,
      errorMessage: "",
    },
    normal: {
      value: {
        weekday: {
          quota: 0,
          price: 0,
        },
        weekend: {
          quota: 0,
          price: 0,
        },
      },
      checked: false,
      hasError: false,
      errorMessage: "",
    },
  },
}

const initialState = {
  formValue: {
    category: {
      value: ticketingsCategories.general?.value,
      hasError: false,
      errorMessage: "",
    },
    category_sortIndex: {
      value: 100,
      hasError: false,
      errorMessage: "",
    },
    specialFlag: {
      value: null,
      hasError: false,
      errorMessage: "",
    },
    purchaseWindows: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    status: {
      value: ticketingsStatus.draft.value,
      hasError: false,
      errorMessage: "",
    },
    qrcodeScanningLimit: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    startDate: {
      value: moment().format(),
      hasError: false,
      errorMessage: "",
    },
    endDate: {
      value: moment().format(),
      hasError: false,
      errorMessage: "",
    },
    sessions: [],
    initialTicketSetting: {
      ...ticketSettingsObject,
    },
    en: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      thumbnailUrl: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      venue: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      displayInfo: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      tnc: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      howToUse: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_body: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_show: {
        value: false,
        hasError: false,
        errorMessage: "",
      },
      banner: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      remarks_show: {
        value: false,
        hasError: false,
        errorMessage: "",
      },
      remarks: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
    },
    sc: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      thumbnailUrl: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      venue: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      displayInfo: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      tnc: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      howToUse: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_body: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_show: {
        value: false,
        hasError: false,
        errorMessage: "",
      },
      banner: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      remarks_show: {
        value: false,
        hasError: false,
        errorMessage: "",
      },
      remarks: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
    },
    tc: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      thumbnailUrl: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      venue: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      displayInfo: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      tnc: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      howToUse: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_body: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      notice_show: {
        value: false,
        hasError: false,
        errorMessage: "",
      },
      banner: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      remarks_show: {
        value: false,
        hasError: false,
        errorMessage: "",
      },
      remarks: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
    },
    en_contentModules: [],
    sc_contentModules: [],
    tc_contentModules: [],
  },
  activeTab: Tabs.SC,
  extendTickets: {
    endDate: {
      value: null,
      hasError: false,
      errorMessage: "",
    },
    ...ticketSettingsObject,
  },
  files: {},
  contentModuleFiles: {},
  validateID: uuidV4(),
  passcodeModalVisible: false,
}

const reducer = produce((draft, action) => {
  const { value, key, assignType } = action.payload
  console.log("!!!!", action.type, "!!!!!")
  const isValidkey =
    draft.formValue[key] || draft.formValue[draft.activeTab][key]
  switch (action.type) {
    case "OnInputChange":
    case "OnDateChange":
    case "OnOptionChange":
    case "OnBannerValueChange":
    case "OnCheckboxChange":
      if (isValidkey) {
        if (draft.formValue[key]) {
          draft.formValue[key].value = value
          draft.formValue[key].hasError = false
        } else {
          draft.formValue[draft.activeTab][key].value = value
          draft.formValue[draft.activeTab][key].hasError = false
        }
      } else if (key.startsWith("session")) {
        const splitKey = key.split("-") //e.g. session-sc_name-${index}
        const realKey = splitKey[1]
        const sessionIndex = splitKey[2] || draft.formValue.sessions.length
        draft.formValue.sessions[sessionIndex][realKey].value = value
      } else if (key.includes("blackoutDaysOfWeek")) {
        const splitKey = key.split("-")
        const day = splitKey[1]
        let currentValue =
          draft.formValue.initialTicketSetting.blackoutDaysOfWeek
        if (currentValue.includes(day)) {
          draft.formValue.initialTicketSetting.blackoutDaysOfWeek = currentValue.filter(
            item => item != day
          )
        } else {
          currentValue.push(day)
          draft.formValue.initialTicketSetting.blackoutDaysOfWeek = currentValue
        }
      } else if (key === "blackoutDates") {
        const formattedDate = moment(value.valueOf()).format("YYYY-MM-DD")
        draft.formValue.initialTicketSetting.blackoutDates.push(formattedDate)
      } else if (key === "extendedEndDate") {
        draft.extendedEndDate.value = value
      }
      break
    case "OnDeleteBlackoutDate":
      draft.formValue.initialTicketSetting.blackoutDates = draft.formValue.initialTicketSetting.blackoutDates.filter(
        date => date != value
      )
      break
    case "OnTabChange":
      draft.activeTab = value
      break
    case "OnCopyPress":
      Object.values(Tabs)
        .filter(tab => tab !== draft.activeTab) // tabs that are not currenet tab
        .forEach(tab => {
          // copy formValue to other tabs
          draft.formValue[tab] = draft.formValue[draft.activeTab]
          draft.formValue[`${tab}_contentModules`] =
            draft.formValue[`${draft.activeTab}_contentModules`]
          // copying files to other tabs
          Object.keys(draft.files)
            .filter(_key => _key.startsWith(draft.activeTab))
            .forEach(_key => {
              draft.files[_key.replace(draft.activeTab, tab)] = {
                ...draft.files[_key],
                key: _key.replace(draft.activeTab, tab),
              }
            })
          // copying content module files to other tabs
          Object.keys(draft.contentModuleFiles)
            .filter(_key => _key.startsWith(draft.activeTab))
            .forEach(_key => {
              draft.contentModuleFiles[_key.replace(draft.activeTab, tab)] = {
                ...draft.contentModuleFiles[_key],
                key: _key.replace(draft.activeTab, tab),
              }
            })
        })
      break
    case "OnContentModuleChange":
      draft.formValue[`${draft.activeTab}_contentModules`] = value
      break
    case "OnContentModuleFilesChange":
      // clearing current lang tab contentModuleFiles
      Object.keys(draft.contentModuleFiles).forEach(_key => {
        if (_key.startsWith(`${draft.activeTab}_contentModules`))
          delete draft.contentModuleFiles[_key]
      })
      // putting current lang tab contentModuleFiles
      draft.contentModuleFiles = {
        ...draft.contentModuleFiles,
        ...Object.values(value).reduce((acc, cur) => {
          return {
            ...acc,
            [`${draft.activeTab}_contentModules${cur.key}`]: {
              ...cur,
              key: `${draft.activeTab}_contentModules${cur.key}`,
            },
          }
        }, {}),
      }
      break
    case "OnUploadImage":
      draft.files[key] = {
        files: value,
        key,
        assignType,
        type: "images",
      }
      _.set(draft.formValue, `${key}.hasError`, false)
      break
    case "OnClearImage":
      delete draft.files[key]
      break
    case "OnUpdateFormValue":
      draft.formValue = value
      draft.validateID = uuidV4()
      break
    case "OnAddSession":
      draft.formValue.sessions.push({ ...session })
      break
    case "OnDeleteSession":
      draft.formValue.sessions?.splice(value, 1)
      break
    case "OnTicketTypeCheckboxChange":
      if (draft.formValue.initialTicketSetting.pricesAndQuotas[key]) {
        draft.formValue.initialTicketSetting.pricesAndQuotas[
          key
        ].checked = value
        if (!value) {
          draft.formValue.initialTicketSetting.pricesAndQuotas[
            key
          ].hasError = false
          draft.formValue.initialTicketSetting.pricesAndQuotas[
            key
          ].errorMessage = ""
        }
      }
      break
    case "OnPasscodeModalToogle":
      console.log("OnPasscodeModalToogle???", value)
      draft.passcodeModalVisible = value
      break
    case "OnPricesAndQuotasChange": {
      const {
        ticketType,
        weekdayOrWeekend,
        priceOrQuota,
        value: input,
      } = action.payload
      console.log(`ticketType`, ticketType)
      console.log(`weekdayOrWeekend`, weekdayOrWeekend)
      console.log(`priceOrQuota`, priceOrQuota)
      console.log(`input`, input)
      draft.formValue.initialTicketSetting.pricesAndQuotas[ticketType].value[
        weekdayOrWeekend
      ][priceOrQuota] = parseInt(input)
      draft.formValue.initialTicketSetting.pricesAndQuotas[
        ticketType
      ].hasError = false
      draft.formValue.initialTicketSetting.pricesAndQuotas[
        ticketType
      ].errorMessage = ""
      break
    }
    case "OnSetAllState":
      if (value.formValue.initialTicketSetting.state === "draft") {
        return produce(value, draftState => {
          const pricesAndQuotas =
            value.formValue.initialTicketSetting.pricesAndQuotas
          for (const key of Object.keys(
            initialState.formValue.initialTicketSetting.pricesAndQuotas
          )) {
            draftState.formValue.initialTicketSetting.pricesAndQuotas[key] =
              initialState.formValue.initialTicketSetting.pricesAndQuotas[key]
          }
          for (const key of Object.keys(pricesAndQuotas)) {
            draftState.formValue.initialTicketSetting.pricesAndQuotas[key] = {
              ...pricesAndQuotas[key],
              checked: true,
            }
          }
        })
      } else {
        // if status is not draft, the initial ticket settings can't be changed
        return produce(value, draftState => {
          for (const key of Object.keys(
            draftState.formValue.initialTicketSetting.pricesAndQuotas
          )) {
            draftState.formValue.initialTicketSetting.pricesAndQuotas[
              key
            ].checked = true
          }
        })
      }
  }
})

const createTicket = ({ history, match }) => {
  const { path, params } = match
  const isEditMode = path === "/ticketing/:id/edit"
  const dispatch = useDispatch()
  const [state, dispatchToReducer] = useReducer(reducer, initialState)
  const [generateAlertShow, setGenerateAlertShow] = useState(false)
  const { formValue, activeTab, validateID, passcodeModalVisible } = state
  const {
    adult,
    child,
    disables,
    elderly,
    normal,
  } = formValue.initialTicketSetting.pricesAndQuotas
  const { ticketSettings } = formValue
  const originTicketingRes = useRef(null)
  console.log(
    "🚀 ~ file: create-ticket.js ~ line 583 ~ createTicket ~ ticketSettings",
    ticketSettings
  )
  const isInitialTicketSettingStateGenerated =
    formValue.initialTicketSetting?.state === "generated"
  const isInitialTicketSettingStateDraft =
    formValue.initialTicketSetting?.state === "draft"

  console.log(`formValue`, formValue)
  useEffect(async () => {
    if (isEditMode && params.id) {
      const ticketingRes = await new Promise((resolve, reject) =>
        dispatch(
          TicketingsActions.getTicketingById({
            ticketingId: params.id,
            onSuccess: resolve,
            onError: reject,
          })
        )
      )
      originTicketingRes.current = ticketingRes
      dispatchToReducer({
        type: "OnSetAllState",
        payload: { value: settingEditTicketingToState(ticketingRes) },
      })
    }
  }, [])

  const handleCheckBoxChange = (e, key) => {
    dispatchToReducer({
      type: "OnCheckboxChange",
      payload: {
        value: e.target.checked,
        key: key,
      },
    })
  }
  const handleOptionChange = (e, key) => {
    dispatchToReducer({
      type: "OnOptionChange",
      payload: {
        value: e.target.value === "" ? null : e.target.value,
        key: key,
      },
    })
  }
  const handleInputChange = (e, key) => {
    dispatchToReducer({
      type: "OnInputChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }

  const hadleTabChange = tabLang => {
    dispatchToReducer({
      type: "OnTabChange",
      payload: {
        value: tabLang,
      },
    })
  }

  const handleDeleteSession = index => {
    dispatchToReducer({
      type: "OnDeleteSession",
      payload: {
        value: index,
      },
    })
  }

  const handleCopyPress = () => {
    dispatchToReducer({ type: "OnCopyPress", payload: {} })
  }
  const getDefaultContentFilesObj = () => {
    const { contentModuleFiles, activeTab } = state
    return Object.values(contentModuleFiles).reduce((acc, cur) => {
      if (cur.key.startsWith(`${activeTab}_contentModules`)) {
        acc[cur.key.replace(`${activeTab}_contentModules`, "")] = {
          ...cur,
          key: cur.key.replace(`${activeTab}_contentModules`, ""),
        }
      }
      return acc
    }, {})
  }
  const getDefaultBannerImageVideoModules = () => {
    return formValue[activeTab].banner.value.map((_banner, _index) => {
      return {
        ..._banner,
        files:
          state.files[`${activeTab}.banner`]?.files[_index] instanceof File
            ? [state.files[`${activeTab}.banner`].files[_index]]
            : [],
      }
    })
  }
  const handleContentModuleChange = contentModule => {
    dispatchToReducer({
      type: "OnContentModuleChange",
      payload: {
        value: contentModule,
      },
    })
  }
  const handleContentModuleFilesChange = contentModuleFiles => {
    dispatchToReducer({
      type: "OnContentModuleFilesChange",
      payload: {
        value: contentModuleFiles,
      },
    })
  }
  const handleBannerImageVideoModulesFilesChange = (
    filesWithType,
    key,
    assignType
  ) => {
    dispatchToReducer({
      type: "OnUploadImage",
      payload: {
        value: filesWithType
          .map(_files =>
            _files.files.length
              ? _files.files
              : { url: _files.url, type: _files.type }
          )
          .flat(),
        key,
        assignType: assignType,
      },
    })
    dispatchToReducer({
      type: "OnBannerValueChange",
      payload: {
        value: filesWithType.map(_files => ({
          id: _files.id,
          type: _files.type,
          url: _files.url,
        })),
        key: "banner",
      },
    })
  }
  const handleImageUpload = (e, key, assignType) => {
    const filesArr = [...e.target.files]
    if (filesArr.length) {
      dispatchToReducer({
        type: "OnUploadImage",
        payload: {
          value: filesArr,
          key,
          assignType: assignType, // onlyValue arrayWithType
        },
      })
    } else {
      handleClearImage(key)
    }
  }
  const handleClearImage = (key, isFormValue) => {
    if (isFormValue) {
      dispatchToReducer({
        type: "OnInputChange",
        payload: {
          value: "",
          key: key,
        },
      })
    } else {
      dispatchToReducer({
        type: "OnClearImage",
        payload: {
          key,
        },
      })
    }
  }

  const handleDateChange = (val, key) => {
    dispatchToReducer({
      type: "OnDateChange",
      payload: {
        value: val,
        key: key,
      },
    })
  }

  const handleDeleteBlackoutDate = val => {
    dispatchToReducer({
      type: "OnDeleteBlackoutDate",
      payload: {
        value: val,
      },
    })
  }

  const handleAddSession = () => {
    if (formValue.sessions.length >= 10) return
    dispatchToReducer({ type: "OnAddSession", payload: {} })
  }

  const handlePriceNQuotasChange = (
    ticketType,
    weekdayOrWeekend,
    priceOrQuota,
    value
  ) => {
    console.log(`{ ticketType, weekdayOrWeekend, priceOrQuota, value }`, {
      ticketType,
      weekdayOrWeekend,
      priceOrQuota,
      value,
    })
    dispatchToReducer({
      type: "OnPricesAndQuotasChange",
      payload: { ticketType, weekdayOrWeekend, priceOrQuota, value },
    })
  }

  const handleTicketTypeCheckboxChange = (e, key) => {
    dispatchToReducer({
      type: "OnTicketTypeCheckboxChange",
      payload: {
        key,
        value: e.target.checked,
      },
    })
  }

  const handlePreSave = () => {
    if (!validateFields()) return
    const { formValue } = state
    if (
      formValue.status.value === "published" &&
      originTicketingRes.current?.status !== "published"
    ) {
      dispatchToReducer({
        type: "OnPasscodeModalToogle",
        payload: {
          value: true,
        },
      })
    } else {
      handleSavePress({ isGenerateTickets: false })
    }
  }

  const onClosePasscode = () => {
    dispatchToReducer({
      type: "OnPasscodeModalToogle",
      payload: {
        value: false,
      },
    })
  }

  const handleSavePress = async ({ isGenerateTickets, passcode }) => {
    const { formValue } = state
    if (!validateFields()) throw "FORM_INVALID"
    dispatch(LoginActions.showLoading())
    const imageUploadRes = await UploadFilesToCloud()
    console.log("imageUploadRes", imageUploadRes)
    const submitForm = produce(formValue, draftFormValue => {
      const orig = original(draftFormValue)
      console.log(`orig`, orig)

      // 1.putting image url to form value
      imageUploadRes.forEach(_res => {
        if (_res.assignType === "onlyValue") {
          _.set(draftFormValue, `${_res.key}.value`, _res.value[0].url)
        } else {
          _.set(draftFormValue, `${_res.key}.value`, _res.value)
        }
      })
      // 2.flatten the form values
      Object.keys(draftFormValue).forEach(key => {
        // normal keys
        if (
          !isLangKey(key) &&
          !["initialTicketSetting", "sessions"].includes(key) &&
          !startsWithContentModules(key)
        ) {
          if (
            (key === "category_sortIndex" ||
              key === "purchaseWindows" ||
              key === "qrcodeScanningLimit") &&
            draftFormValue[key].value === ""
          ) {
            draftFormValue[key] = 0
          } else {
            draftFormValue[key] = draftFormValue[key].value
          }
          const copy = current(draftFormValue)
          console.log(`key`, key)
          console.log(`copy1`, copy)
        }
        // keys that is lang
        if (isLangKey(key) && !startsWithContentModules(key)) {
          Object.keys(draftFormValue[key]).forEach(_key => {
            draftFormValue[key][_key] = draftFormValue[key][_key].value
            if (_key === "id") delete draftFormValue[key][_key]
            if (_key === "banner") {
              draftFormValue[key][_key].forEach(_banner => delete _banner.id)
            }
          })
          const copy = current(draftFormValue)
          console.log(`key`, key)
          console.log(`copy2`, copy)
        }
        // keys that is _contentModules
        if (startsWithContentModules(key)) {
          draftFormValue[key].forEach(_module => {
            Object.keys(_module).forEach(_key => {
              const specialKeys = ["__component", "id"]
              _module[_key] = specialKeys.includes(_key)
                ? _module[_key]
                : _module[_key].value
              if (_key === "id") delete _module[_key]
            })
          })
          const copy = current(draftFormValue)
          console.log(`key`, key)
          console.log(`copy3`, copy)
        }

        if (key === "sessions") {
          draftFormValue[key].forEach((session, index) => {
            Object.keys(session).forEach(_key => {
              draftFormValue[key][index][_key] =
                draftFormValue[key][index][_key].value
            })
            if (!isEditMode) {
              session.id = uuidV4()
            }
          })
          const copy = current(draftFormValue)
          console.log(`key`, key)
          console.log(`copy4`, copy)
        }

        if (key === "initialTicketSetting") {
          Object.keys(draftFormValue[key].pricesAndQuotas).forEach(_key => {
            if (draftFormValue[key].pricesAndQuotas[_key].checked) {
              draftFormValue[key].pricesAndQuotas[_key] =
                draftFormValue[key].pricesAndQuotas[_key].value
            } else {
              delete draftFormValue[key].pricesAndQuotas[_key]
            }
          })
          const copy = current(draftFormValue)
          console.log(`key`, key)
          console.log(`copy5`, copy)
        }
      })
      draftFormValue.passcode = passcode
    })
    console.log("submitForm!!!", submitForm)
    try {
      if (isEditMode) {
        await new Promise((resolve, reject) =>
          dispatch(
            TicketingsActions.editTicketing({
              ticketingId: params.id,
              ticketing: submitForm,
              onSuccess: resolve,
              onError: reject,
            })
          )
        )
      } else {
        await new Promise((resolve, reject) =>
          dispatch(
            TicketingsActions.postTicketing({
              ticketing: submitForm,
              onSuccess: resolve,
              onError: reject,
            })
          )
        )
      }
      console.log(`isGenerateTickets`, isGenerateTickets)
      if (!isGenerateTickets) {
        dispatch(LoginActions.hideLoading())
        history.push("/ticketings")
      }
    } catch (err) {
      console.log(err)
      dispatch(LoginActions.hideLoading())
      NotificationManager.error(
        isEditMode ? "Save Ticketing Failed" : "Create Ticketing Failed",
        "Error",
        3000
      )
      throw err
    }
  }

  const UploadFilesToCloud = async () => {
    // uploading files to cloud
    const filesArr = Object.values(state.files)
    const resArr = []
    for (let file of filesArr) {
      const res = await new Promise((resolve, reject) =>
        dispatch(
          FileActions.uploadImagesAndVideos({
            files: file.files,
            onSuccess: resolve,
            onError: reject,
          })
        )
      )
      resArr.push({
        value: res,
        key: file.key,
        assignType: file.assignType,
      })
    }
    // uploading content module files to cloud
    const contentModuleFilesArr = Object.values(state.contentModuleFiles)
    for (let cfile of contentModuleFilesArr) {
      const res = await new Promise((resolve, reject) =>
        dispatch(
          FileActions.uploadImagesAndVideos({
            files: cfile.files,
            onSuccess: resolve,
            onError: reject,
          })
        )
      )
      resArr.push({
        value: res,
        key: cfile.key,
        assignType: cfile.assignType,
      })
    }
    console.log("!!!!resArr!!!!", resArr)
    return resArr
  }

  const validateFields = () => {
    const { formValue, contentModuleFiles } = state
    let isAllValidate = true
    // console.log("validateFields formValue", formValue)
    const newForm = produce(formValue, draftFormValue => {
      const end = moment(draftFormValue.endDate.value).valueOf()
      const start = moment(draftFormValue.startDate.value).valueOf()
      Object.keys(draftFormValue).forEach(key => {
        // normal keys
        if (
          !isLangKey(key) &&
          !startsWithContentModules(key) &&
          !["sessions", "ticketSettings"].includes(key)
        ) {
          if (
            (key === "category_sortIndex" &&
              !draftFormValue.category_sortIndex.value) ||
            (key === "purchaseWindows" && !draftFormValue.purchaseWindows.value)
          ) {
            draftFormValue[key].hasError = false
          } else if (key === "endDate" && start > end) {
            draftFormValue[key].hasError = true
            draftFormValue[key].errorMessage =
              "The end date cannot be before the start date."
            isAllValidate = false
            console.log("invalid!!", key)
          } else if (
            key === "initialTicketSetting" &&
            !isInitialTicketSettingStateGenerated
          ) {
            Object.values(draftFormValue[key].pricesAndQuotas).forEach(
              pricesAndQuota => {
                if (pricesAndQuota.checked) {
                  if (
                    pricesAndQuota.value.weekday.price <= 0 ||
                    pricesAndQuota.value.weekend.price <= 0 ||
                    isNaN(pricesAndQuota.value.weekday.price) ||
                    isNaN(pricesAndQuota.value.weekend.price)
                  ) {
                    isAllValidate = false
                    pricesAndQuota.hasError = true
                    pricesAndQuota.errorMessage = "price must bigger than 0"
                  }
                } else {
                  pricesAndQuota.hasError = false
                  pricesAndQuota.errorMessage = ""
                }
              }
            )
          } else {
            console.log(
              "🚀 ~ file: create-ticket.js ~ line 973 ~ Object.keys ~ key",
              key
            )
            draftFormValue[key].hasError = false
            draftFormValue[key].errorMessage = ""
          }

          /* else if (
            _.isNil(draftFormValue[key].value) ||
            draftFormValue[key].value === ""
          ) {
            draftFormValue[key].hasError = true
            draftFormValue[key].errorMessage = "Field cant be empty"
            isAllValidate = false
          } */
        }

        // keys that is lang,
        if (
          isLangKey(key) &&
          !startsWithContentModules(key) &&
          startsWithSC(key) // (currntly only validate sc other lang can bypass)
        ) {
          Object.keys(draftFormValue[key]).forEach(_key => {
            // console.log(key, _key, current(draftFormValue[key][_key]))
            if (_key === "thumbnailUrl") {
              /* if (
                (draftFormValue[key][_key].value === "" ||
                  _.isNil(draftFormValue[key][_key].value)) &&
                !files[`${key}.${_key}`]?.files.length
              ) {
                draftFormValue[key][_key].hasError = true
                draftFormValue[key][_key].errorMessage = "Field cant be empty"
                isAllValidate = false
                console.log("invalid!!1", key)
              } */
            } else if (_key === "banner") {
              /* const allBannerHasUrlOrFile =
                draftFormValue[key][_key].value.reduce((acc, cur, _index) => {
                  const isUrlEmpty = cur.url === "" || _.isNil(cur.url)
                  const isFileEmpty = !(
                    files[`${key}.${_key}`]?.files[_index] instanceof File
                  )
                  return acc && !(isUrlEmpty && isFileEmpty)
                }, true) && draftFormValue[key][_key].value.length > 0
              if (!allBannerHasUrlOrFile) {
                draftFormValue[key][_key].hasError = true
                draftFormValue[key][_key].errorMessage = "Field cant be empty"
                isAllValidate = false
                console.log("invalid!!2", key)
              } */
            } else if (_key === "id") {
              //TODO
            } else if (
              (_key === "notice_body" &&
                !draftFormValue[key].notice_show.value) ||
              (_key === "notice_title" &&
                !draftFormValue[key].notice_show.value) ||
              (_key === "remarks" && !draftFormValue[key].remarks_show.value)
            ) {
              draftFormValue[key][_key].hasError = false
            } else if (
              ["venue", "displayInfo", "tnc", "howToUse"].includes(_key)
            ) {
              //DO NTH
            } else {
              if (
                draftFormValue[key][_key].value === "" ||
                _.isNil(draftFormValue[key][_key].value)
              ) {
                draftFormValue[key][_key].hasError = true
                draftFormValue[key][_key].errorMessage = "Field cant be empty"
                isAllValidate = false
                console.log("invalid!!3", key, _key)
              }
            }
          })
        }
        // keys that is _contentModules
        if (
          startsWithContentModules(key) &&
          startsWithSC(key) // (currntly only validate sc other lang can bypass)
        ) {
          draftFormValue[key].forEach((_module, _index) => {
            Object.keys(_module).forEach(_key => {
              const specialKeys = [
                "__component",
                "id",
                "category",
                "appId",
                "appPath",
                "articleId",
                "destination",
              ]
              if (!specialKeys.includes(_key)) {
                // console.log(_key, current(_module[_key]))
                const keysNeedToCheckFile = ["imageUrl", "videoUrl"]
                const keysNeedToCheckCssColor = ["textColor", "backgroundColor"]
                const keysNeedToCheckFloat = ["lat", "long"]
                if (
                  _module[_key].value === "" ||
                  _.isNil(_module[_key].value)
                ) {
                  if (keysNeedToCheckFile.includes(_key)) {
                    // keys in _contentModules need to check have file or not
                    if (
                      !contentModuleFiles[`${key}[${_index}].${_key}`]?.files
                        .length
                    ) {
                      _module[_key].hasError = true
                      _module[_key].errorMessage = "Need to upload file"
                      isAllValidate = false
                      console.log("invalid!!4", key)
                    }
                  } else {
                    // normal keys in _contentModules
                    _module[_key].hasError = true
                    _module[_key].errorMessage = "Field cant be empty"
                    isAllValidate = false
                    console.log("invalid!!5", key)
                  }
                } else if (keysNeedToCheckFloat.includes(_key)) {
                  const floatRegex = /^[0-9]*\.?[0-9]*$/
                  const result = floatRegex.test(_module[_key].value)
                  if (!result) {
                    _module[_key].hasError = true
                    _module[_key].errorMessage = "Invalid value"
                    isAllValidate = false
                  }
                } else if (keysNeedToCheckCssColor.includes(_key)) {
                  const cssColorRegex = /^#[0-9a-fA-F]{6}$/
                  const result = cssColorRegex.test(_module[_key].value)
                  if (!result) {
                    _module[_key].hasError = true
                    _module[_key].errorMessage = "Color is invalid"
                    isAllValidate = false
                  }
                }
              }
            })
          })
        }
      })
    })
    dispatchToReducer({
      type: "OnUpdateFormValue",
      payload: { value: newForm },
    })
    return isAllValidate
  }

  const handleGenerateTickets = async () => {
    try {
      await handleSavePress({ isGenerateTickets: true })
      await new Promise((resolve, reject) =>
        dispatch(
          TicketingsActions.generateTicketings({
            ticketingId: params?.id,
            onSuccess: resolve,
            onError: reject,
          })
        )
      )
      dispatch(LoginActions.hideLoading())
      history.push("/ticketings")
    } catch (err) {
      console.log(err)
      dispatch(LoginActions.hideLoading())
      NotificationManager.error("Fail to generate tickets", "Error", 3000)
    }
  }

  const handleExtendTickets = async currentTicketSetting => {
    const newTicketSetting = produce(
      currentTicketSetting,
      draftTicketSetting => {
        draftTicketSetting.endDate = moment(
          draftTicketSetting.endDate.value
        ).format("YYYY-MM-DD")
        Object.keys(draftTicketSetting.pricesAndQuotas).forEach(_key => {
          if (draftTicketSetting.pricesAndQuotas[_key].checked) {
            draftTicketSetting.pricesAndQuotas[_key] =
              draftTicketSetting.pricesAndQuotas[_key].value
          } else {
            delete draftTicketSetting.pricesAndQuotas[_key]
          }
        })
      }
    )
    console.log(`newTicketSetting`, newTicketSetting)
    try {
      await new Promise((resolve, reject) =>
        dispatch(
          TicketingsActions.extendTicketings({
            ticketingId: params?.id,
            ticketSetting: newTicketSetting,
            onSuccess: resolve,
            onError: reject,
          })
        )
      )
      dispatch(LoginActions.hideLoading())
      history.push("/ticketings")
    } catch (err) {
      console.log(err)
      dispatch(LoginActions.hideLoading())
      NotificationManager.error("Fail to extend tickets", "Error", 3000)
    }
  }

  const excludeDates = formValue.initialTicketSetting.blackoutDates.map(
    date => new Date(date)
  )

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h3>{isEditMode ? "Edit Ticketing" : "Create Ticketing"}</h3>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-3 font-size-20">
                    Event Settings
                  </CardTitle>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">Category</Label>
                        <select
                          className="form-control select2"
                          value={formValue.category.value}
                          onChange={e => {
                            handleOptionChange(e, "category")
                          }}
                        >
                          {Object.values(ticketingsCategories)?.map(
                            category => (
                              <option
                                key={category.value}
                                value={category.value}
                              >
                                {category.title}
                              </option>
                            )
                          )}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="category_sortIndex">
                          Category Sorting Index
                        </Label>
                        <Input
                          name="category_sortIndex"
                          type="number"
                          className="form-control"
                          value={formValue.category_sortIndex.value}
                          onChange={e => {
                            handleInputChange(e, "category_sortIndex")
                          }}
                          invalid={formValue.category_sortIndex.hasError}
                        />
                        <FormFeedback>
                          {formValue.category_sortIndex.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">Special Flag</Label>
                        <select
                          className="form-control select2"
                          value={formValue.specialFlag.value}
                          onChange={e => {
                            handleOptionChange(e, "specialFlag")
                          }}
                        >
                          {Object.values(ticketingsSpecialFlag)?.map(
                            (flag, index) => (
                              <option value={flag.value} key={index}>
                                {flag.title}
                              </option>
                            )
                          )}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="purchaseWindows">
                          Purchase Windows
                        </Label>
                        <Input
                          name="purchaseWindows"
                          type="number"
                          className="form-control"
                          value={formValue.purchaseWindows.value}
                          onChange={e => {
                            handleInputChange(e, "purchaseWindows")
                          }}
                          invalid={formValue.purchaseWindows.hasError}
                        />
                        <FormFeedback>
                          {formValue.purchaseWindows.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">
                          Ticketing Status
                        </Label>
                        <select
                          className="form-control select2"
                          value={formValue.status.value}
                          onChange={e => handleOptionChange(e, "status")}
                        >
                          {Object.values(ticketingsStatus)?.map(status => (
                            <option key={status.value} value={status.value}>
                              {status.title}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="qrcodeScanningLimit">
                          Ticket QR Code Scanning Limit
                        </Label>
                        <Input
                          name="qrcodeScanningLimit"
                          type="number"
                          className="form-control"
                          value={formValue.qrcodeScanningLimit.value}
                          onChange={e => {
                            handleInputChange(e, "qrcodeScanningLimit")
                          }}
                          invalid={formValue.qrcodeScanningLimit.hasError}
                        />
                        <FormFeedback>
                          {formValue.qrcodeScanningLimit.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <CardTitle className="my-3 font-size-20">
                    Ticket Settings
                    <small className="my-3 text-muted">
                      {" "}
                      [Cannot be edited after tickets generation]
                    </small>
                  </CardTitle>
                  <p className="m-0">
                    <Label
                      htmlFor="qrcodeScanningLimit"
                      className="font-size-14 text-muted m-0"
                    >
                      Ticket Session per Day (max. 10 sessions)
                    </Label>
                  </p>
                  {formValue.sessions.map((session, index) =>
                    isInitialTicketSettingStateGenerated ? (
                      <TicketSession
                        key={index}
                        index={index}
                        session={session}
                        isInitialTicketSettingStateGenerated={
                          isInitialTicketSettingStateGenerated
                        }
                        onChange={handleInputChange}
                      />
                    ) : (
                      <CloseButtonWrapper
                        onClose={() => handleDeleteSession(index)}
                      >
                        <TicketSession
                          key={index}
                          index={index}
                          session={session}
                          isInitialTicketSettingStateGenerated={
                            isInitialTicketSettingStateGenerated
                          }
                          onChange={handleInputChange}
                        />
                      </CloseButtonWrapper>
                    )
                  )}
                  {formValue.sessions.length >= 10 ||
                  isInitialTicketSettingStateGenerated ? null : (
                    <Button className="mt-3" onClick={() => handleAddSession()}>
                      Add Session
                    </Button>
                  )}

                  {/* Date */}
                  <Row className="mt-3">
                    <Col sm="2">
                      <FormGroup>
                        <Label htmlFor="startDate">Ticket Start Date</Label>
                        <DatePicker
                          minDate={new Date()}
                          selected={new Date(moment(formValue.startDate.value))}
                          onChange={val => {
                            handleDateChange(
                              moment(val?.valueOf()).format("YYYY-MM-DD"),
                              "startDate"
                            )
                          }}
                          className={`form-control ${
                            isInitialTicketSettingStateGenerated
                              ? "bg-light"
                              : ""
                          }`}
                          placeholderText="Select date"
                          disabled={isInitialTicketSettingStateGenerated}
                        />
                        <FormFeedback>
                          {formValue.startDate.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="2">
                      <FormGroup>
                        <Label htmlFor="endDate">Ticket End Date</Label>
                        <DatePicker
                          minDate={new Date(formValue.startDate.value)}
                          selected={new Date(moment(formValue.endDate.value))}
                          onChange={val => {
                            handleDateChange(
                              moment(val?.valueOf()).format("YYYY-MM-DD"),
                              "endDate"
                            )
                          }}
                          className={`form-control ${
                            isInitialTicketSettingStateGenerated
                              ? "bg-light"
                              : ""
                          }`}
                          placeholderText="Select date"
                          disabled={isInitialTicketSettingStateGenerated}
                        />
                        {formValue.endDate.hasError ? (
                          <div className="text-danger">
                            {formValue.endDate.errorMessage}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>

                  {isInitialTicketSettingStateGenerated ? (
                    <div>
                      <CardTitle className="my-3 font-size-20">
                        Available Ticket Types
                        <small className="my-3 text-muted">
                          {" "}
                          [Cannot be edited after tickets generation]
                        </small>
                      </CardTitle>

                      <Row>
                        {ticketTypes.map((type, index) => (
                          <Col sm={{ size: "auto" }} key={index}>
                            <LabelCheckBox
                              label={type}
                              id={type}
                              value={type}
                              checked={
                                formValue.initialTicketSetting.pricesAndQuotas[
                                  type.toLowerCase()
                                ]
                                  ? formValue.initialTicketSetting
                                      .pricesAndQuotas[type.toLowerCase()]
                                      .checked
                                  : false
                              }
                              disabled
                              onChange={() => {}}
                            />
                          </Col>
                        ))}
                      </Row>

                      <ExtendTickets
                        initialEndDate={formValue.endDate.value}
                        initialPricesAndQuotas={
                          formValue.initialTicketSetting.pricesAndQuotas
                        }
                        extendTickets={ticketSettingsObject}
                        handleExtendTickets={handleExtendTickets}
                      />
                    </div>
                  ) : (
                    <div>
                      <Label htmlFor="blackoutDate">
                        Blackout Date (days of week):
                      </Label>
                      <Row>
                        {daysOfWeek.map((day, index) => (
                          <Col sm={{ size: "auto" }} key={index}>
                            <LabelCheckBox
                              label={day.label}
                              id={day.id}
                              value={day.id}
                              checked={(
                                ticketSettings?.blackoutDaysOfWeek ||
                                formValue.initialTicketSetting
                                  .blackoutDaysOfWeek
                              ).includes(day.apiValue)}
                              onChange={e =>
                                handleCheckBoxChange(
                                  e,
                                  `blackoutDaysOfWeek-${day.apiValue}`
                                )
                              }
                            ></LabelCheckBox>
                          </Col>
                        ))}
                      </Row>
                      <Label htmlFor="endDate" className="mt-3">
                        Specific Blackout Dates:
                      </Label>
                      <Row>
                        {(
                          ticketSettings?.blackoutDates ||
                          formValue.initialTicketSetting.blackoutDates
                        ).map((date, index) => {
                          const display = moment(date).format("MM/DD/YYYY")
                          return (
                            <React.Fragment key={index}>
                              <CloseButton
                                style={{
                                  width: 15,
                                  height: 15,
                                  fontSize: "22px",
                                }}
                                onClose={() => {
                                  handleDeleteBlackoutDate(
                                    date,
                                    "OnDeleteBlackoutDate"
                                  )
                                }}
                              />
                              <div className="ml-1 mr-2 mb-1">
                                {display}
                                {index + 1 !=
                                formValue.initialTicketSetting.blackoutDates
                                  .length
                                  ? ", "
                                  : null}
                              </div>
                            </React.Fragment>
                          )
                        })}
                      </Row>
                      <Col xs="3" className="p-0">
                        <DatePicker
                          customInput={
                            <Button className="mt-1">Add Specific Date</Button>
                          }
                          minDate={new Date(formValue.startDate?.value)}
                          maxDate={new Date(formValue.endDate?.value)}
                          onChange={val => {
                            handleDateChange(val, "blackoutDates")
                          }}
                          className="form-control"
                          excludeDates={excludeDates}
                        />
                      </Col>

                      <CardTitle className="my-4 font-size-20">
                        Available Ticket Prices & Quotas
                        <small className="my-3 text-muted">
                          {" "}
                          [Cannot be edited after tickets generation]
                        </small>
                      </CardTitle>

                      <Row className="mb-2">
                        <Col className="font-size-14">
                          Available Ticket Type
                        </Col>
                        <Col className="font-size-14">Weekday Price (HKD)</Col>
                        <Col className="font-size-14">Weekday Quota</Col>
                        <Col className="font-size-14">Weekend Price (HKD)</Col>
                        <Col className="font-size-14">Weekend Quota</Col>
                      </Row>
                      {/* adult */}
                      <Row className="mb-2">
                        <Col className="d-flex align-items-center">
                          <Row className="ml-1">
                            <LabelCheckBox
                              label=""
                              id="adult"
                              value="adult"
                              checked={adult?.checked ?? false}
                              disabled={isInitialTicketSettingStateGenerated}
                              onChange={e =>
                                handleTicketTypeCheckboxChange(e, "adult")
                              }
                            />
                            <span className="font-size-14">Adult</span>
                          </Row>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="adultWeekdayPrice"
                            type="adult"
                            state={adult}
                            weekdayOrWeekend="weekday"
                            priceOrQuota="price"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="adultWeekdayQuota"
                            type="adult"
                            state={adult}
                            weekdayOrWeekend="weekday"
                            priceOrQuota="quota"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="adultWeekendPrice"
                            type="adult"
                            state={adult}
                            weekdayOrWeekend="weekend"
                            priceOrQuota="price"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="adultWeekendQuota"
                            type="adult"
                            state={adult}
                            weekdayOrWeekend="weekend"
                            priceOrQuota="quota"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                      </Row>
                      {/* child */}
                      <Row className="mb-2">
                        <Col className="d-flex align-items-center">
                          <Row className="ml-1">
                            <LabelCheckBox
                              label=""
                              id="child"
                              value="child"
                              checked={child?.checked ?? false}
                              disabled={isInitialTicketSettingStateGenerated}
                              onChange={e =>
                                handleTicketTypeCheckboxChange(e, "child")
                              }
                            />
                            <span className="font-size-14">Child</span>
                          </Row>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="childWeekdayPrice"
                            type="child"
                            state={child}
                            weekdayOrWeekend="weekday"
                            priceOrQuota="price"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="childWeekdayQuota"
                            type="child"
                            state={child}
                            weekdayOrWeekend="weekday"
                            priceOrQuota="quota"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="childWeekendPrice"
                            type="child"
                            state={child}
                            weekdayOrWeekend="weekend"
                            priceOrQuota="price"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="childWeekendQuota"
                            type="child"
                            state={child}
                            weekdayOrWeekend="weekend"
                            priceOrQuota="quota"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                      </Row>
                      {/* disables */}
                      <Row className="mb-2">
                        <Col className="d-flex align-items-center">
                          <Row className="ml-1">
                            <LabelCheckBox
                              label=""
                              id="disables"
                              value="disables"
                              checked={disables?.checked ?? false}
                              disabled={isInitialTicketSettingStateGenerated}
                              onChange={e =>
                                handleTicketTypeCheckboxChange(e, "disables")
                              }
                            />
                            <span className="font-size-14">Disables</span>
                          </Row>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="disablesWeekdayPrice"
                            type="disables"
                            state={disables}
                            weekdayOrWeekend="weekday"
                            priceOrQuota="price"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="disablesWeekdayQuota"
                            type="disables"
                            state={disables}
                            weekdayOrWeekend="weekday"
                            priceOrQuota="quota"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="disablesWeekendPrice"
                            type="disables"
                            state={disables}
                            weekdayOrWeekend="weekend"
                            priceOrQuota="price"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="disablesWeekendQuota"
                            type="disables"
                            state={disables}
                            weekdayOrWeekend="weekend"
                            priceOrQuota="quota"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                      </Row>
                      {/* elderly */}
                      <Row className="mb-2">
                        <Col className="d-flex align-items-center">
                          <Row className="ml-1">
                            <LabelCheckBox
                              label=""
                              id="elderly"
                              value="elderly"
                              checked={elderly?.checked ?? false}
                              disabled={isInitialTicketSettingStateGenerated}
                              onChange={e =>
                                handleTicketTypeCheckboxChange(e, "elderly")
                              }
                            />
                            <span className="font-size-14">Elderly</span>
                          </Row>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="elderlyWeekdayPrice"
                            type="elderly"
                            state={elderly}
                            weekdayOrWeekend="weekday"
                            priceOrQuota="price"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="elderlyWeekdayQuota"
                            type="elderly"
                            state={elderly}
                            weekdayOrWeekend="weekday"
                            priceOrQuota="quota"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="elderlyWeekendPrice"
                            type="elderly"
                            state={elderly}
                            weekdayOrWeekend="weekend"
                            priceOrQuota="price"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="elderlyWeekendQuota"
                            type="elderly"
                            state={elderly}
                            weekdayOrWeekend="weekend"
                            priceOrQuota="quota"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                      </Row>
                      {/* normal */}
                      <Row className="mb-2">
                        <Col className="d-flex align-items-center">
                          <Row className="ml-1">
                            <LabelCheckBox
                              label=""
                              id="normal"
                              value="normal"
                              checked={normal?.checked ?? false}
                              disabled={isInitialTicketSettingStateGenerated}
                              onChange={e =>
                                handleTicketTypeCheckboxChange(e, "normal")
                              }
                            />
                            <span className="font-size-14">Normal</span>
                          </Row>
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="normalWeekdayPrice"
                            type="normal"
                            state={normal}
                            weekdayOrWeekend="weekday"
                            priceOrQuota="price"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="normalWeekdayQuota"
                            type="normal"
                            state={normal}
                            weekdayOrWeekend="weekday"
                            priceOrQuota="quota"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="normalWeekendPrice"
                            type="normal"
                            state={normal}
                            weekdayOrWeekend="weekend"
                            priceOrQuota="price"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                        <Col className="d-flex align-items-center">
                          <TicketPriceNQuotosInput
                            id="normalWeekendQuota"
                            type="normal"
                            state={normal}
                            weekdayOrWeekend="weekend"
                            priceOrQuota="quota"
                            onChange={handlePriceNQuotasChange}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                  {isInitialTicketSettingStateDraft ? (
                    <Button
                      className="mt-3"
                      onClick={() => setGenerateAlertShow(true)}
                    >
                      Generate Tickets (irreversible action)
                    </Button>
                  ) : null}
                </CardBody>
              </Card>

              <Nav tabs>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.SC}
                    onClick={() => hadleTabChange(Tabs.SC)}
                  >
                    <i className="bx bx-chat font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">簡體中文</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.TC}
                    onClick={() => hadleTabChange(Tabs.TC)}
                  >
                    <i className="bx bx-group font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">繁體中文</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.EN}
                    onClick={() => hadleTabChange(Tabs.EN)}
                  >
                    <i className="bx bx-book-content font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">English</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <Card>
                <CardBody>
                  <Row className="justify-content-between m-0 mb-4">
                    <CardTitle className="mb-3 font-size-20">General</CardTitle>
                    <Button onClick={handleCopyPress}>
                      Copy to other lang
                    </Button>
                  </Row>

                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="title">Title</Label>
                      <Input
                        name="title"
                        type="text"
                        className="form-control"
                        value={formValue[activeTab].title.value}
                        onChange={e => {
                          handleInputChange(e, "title")
                        }}
                        invalid={formValue[activeTab].title.hasError}
                      />
                      <FormFeedback>
                        {formValue[activeTab].title.errorMessage}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="ThumbnailImage">Thumbnail Image</Label>
                      <Input
                        id="ThumbnailImage"
                        name="ThumbnailImage"
                        type="file"
                        className="form-control btn btn-primary"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={e =>
                          handleImageUpload(
                            e,
                            `${activeTab}.thumbnailUrl`,
                            "onlyValue"
                          )
                        }
                      ></Input>
                      <Col className="p-0">
                        <Label
                          className="btn btn-primary"
                          htmlFor="ThumbnailImage"
                          for="ThumbnailImage"
                        >
                          Upload
                        </Label>
                      </Col>
                      <Col className="p-0">
                        {state.files[`${activeTab}.thumbnailUrl`] ? (
                          state.files[
                            `${activeTab}.thumbnailUrl`
                          ]?.files.map((_file, _index) => (
                            <FileImageDisplayer
                              key={_index}
                              file={_file}
                              onClose={() =>
                                handleClearImage(`${activeTab}.thumbnailUrl`)
                              }
                            ></FileImageDisplayer>
                          ))
                        ) : (
                          <FileImageDisplayer
                            fileUrlObj={{
                              url: formValue[activeTab].thumbnailUrl.value,
                              type: "image",
                            }}
                            onClose={() =>
                              handleClearImage("thumbnailUrl", true)
                            }
                          ></FileImageDisplayer>
                        )}
                      </Col>
                      {formValue[activeTab].thumbnailUrl.hasError && (
                        <Col className="p-0" style={{ color: "#f46a6a" }}>
                          {formValue[activeTab].thumbnailUrl.errorMessage}
                        </Col>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="12" className="mb-3">
                    <ImageVideoModules
                      key={`${activeTab}_${validateID}`}
                      label="Banner Images or Videos (max 6 files)"
                      onChange={filesWithType =>
                        handleBannerImageVideoModulesFilesChange(
                          filesWithType,
                          `${activeTab}.banner`,
                          "arrayWithType"
                        )
                      }
                      defaultModulesTypesArr={getDefaultBannerImageVideoModules()}
                      hasError={formValue[activeTab].banner.hasError}
                      errorMessage={formValue[activeTab].banner.errorMessage}
                    ></ImageVideoModules>
                  </Col>
                  <div className="d-flex flex-row">
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="venue">Venue</Label>
                        <Input
                          name="venue"
                          className="form-control"
                          value={formValue[activeTab].venue.value}
                          onChange={e => {
                            handleInputChange(e, "venue")
                          }}
                          invalid={formValue[activeTab].venue.hasError}
                        />
                        <FormFeedback>
                          {formValue[activeTab].venue.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="displayInfo">Display Info</Label>
                        <Input
                          name="displayInfo"
                          className="form-control"
                          value={formValue[activeTab].displayInfo.value}
                          onChange={e => {
                            handleInputChange(e, "displayInfo")
                          }}
                          invalid={formValue[activeTab].displayInfo.hasError}
                        />
                        <FormFeedback>
                          {formValue[activeTab].displayInfo.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </div>

                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="ticketingTNC">T&C</Label>
                      <Input
                        name="ticketingTNC"
                        type="textarea"
                        className="form-control"
                        value={formValue[activeTab].tnc.value}
                        onChange={e => {
                          handleInputChange(e, "tnc")
                        }}
                        invalid={formValue[activeTab].tnc.hasError}
                      />
                      <FormFeedback>
                        {formValue[activeTab].tnc.errorMessage}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col sm="12">
                    <FormGroup>
                      <Label htmlFor="ticketingHowToUse">How to Use</Label>
                      <Input
                        name="ticketingHowToUse"
                        type="textarea"
                        className="form-control"
                        value={formValue[activeTab].howToUse.value}
                        onChange={e => {
                          handleInputChange(e, "howToUse")
                        }}
                        invalid={formValue[activeTab].howToUse.hasError}
                      />
                      <FormFeedback>
                        {formValue[activeTab].howToUse.errorMessage}
                      </FormFeedback>
                    </FormGroup>
                  </Col>

                  <SpecialNotice
                    checked={formValue[activeTab].notice_show.value}
                    checkBoxOnChange={e =>
                      handleCheckBoxChange(e, "notice_show")
                    }
                    titleValue={formValue[activeTab].notice_title.value}
                    titleOnChange={e => {
                      handleInputChange(e, "notice_title")
                    }}
                    titleInvalid={formValue[activeTab].notice_title.hasError}
                    titleErrorMsg={
                      formValue[activeTab].notice_title.errorMessage
                    }
                    bodyValue={formValue[activeTab].notice_body.value}
                    bodyOnChange={e => {
                      handleInputChange(e, "notice_body")
                    }}
                    bodyInvalid={formValue[activeTab].notice_body.hasError}
                    bodyErrorMsg={formValue[activeTab].notice_body.errorMessage}
                  />

                  <CardTitle className="mb-3 font-size-20">
                    Ticket Remarks
                  </CardTitle>
                  <div className="ml-2">
                    <LabelCheckBox
                      label="Show Ticket Remarks"
                      id="remark_show"
                      checked={formValue[activeTab].remarks_show.value}
                      onChange={e => handleCheckBoxChange(e, "remarks_show")}
                    />
                    <FormGroup className="mt-3">
                      <Label htmlFor="remarks">Ticket Remarks</Label>
                      <Input
                        name="remarks"
                        type="textarea"
                        className="form-control"
                        value={formValue[activeTab].remarks.value}
                        onChange={e => handleInputChange(e, "remarks")}
                        invalid={formValue[activeTab].remarks.hasError}
                      />
                      <FormFeedback>
                        {formValue[activeTab].remarks.errorMessage}
                      </FormFeedback>
                    </FormGroup>
                  </div>

                  <ContentModules
                    key={`${activeTab}_${validateID}`}
                    defaultModulesTypesArr={
                      formValue[`${activeTab}_contentModules`]
                    }
                    defaultModulesFileObj={getDefaultContentFilesObj()}
                    onChange={handleContentModuleChange}
                    onFilesChange={handleContentModuleFilesChange}
                  ></ContentModules>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <NotificationContainer />
          <div>
            <Button
              className="mr-3 w-md"
              color="primary"
              onClick={handlePreSave}
            >
              Save
            </Button>
            <Link to="/ticketings" className="btn btn-secondary mr-2 w-md">
              Cancel
            </Link>
          </div>
        </Container>
      </div>
      <PasscodeModal
        isOpen={passcodeModalVisible}
        onConfirm={passcode =>
          handleSavePress({ passcode, isGenerateTickets: false })
        }
        onCancel={onClosePasscode}
      ></PasscodeModal>
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          handleGenerateTickets()
          setGenerateAlertShow(false)
        }}
        onCancel={() => setGenerateAlertShow(false)}
        focusCancelBtn
        show={generateAlertShow}
      >
        Are you sure you want to generate tickets?
      </SweetAlert>
    </React.Fragment>
  )
}

export default createTicket

const settingEditTicketingToState = editTicketing => {
  const defaultFormValue = produce(editTicketing, draftTicketing => {
    Object.keys(draftTicketing).forEach(key => {
      const keysToignore = ["updated_at", "created_at", "id"]
      // keys to ignore
      if (keysToignore.includes(key)) {
        delete draftTicketing[key]
      }
      // mormal keys
      else if (
        !isLangKey(key) &&
        !["initialTicketSetting", "sessions", "ticketSettings"].includes(key) &&
        !startsWithContentModules(key)
      ) {
        draftTicketing[key] = {
          value: draftTicketing[key],
          hasError: false,
          errorMessage: "",
        }
      }
      // keys that is lang
      else if (isLangKey(key)) {
        Object.keys(draftTicketing[key]).forEach(
          _key =>
            (draftTicketing[key][_key] = {
              value: draftTicketing[key][_key],
              hasError: false,
              errorMessage: "",
            })
        )
      }
      // keys that is _contentModules
      else if (startsWithContentModules(key)) {
        draftTicketing[key].forEach(_module => {
          Object.keys(_module).forEach(_key => {
            const specialKeys = ["__component", "id"]
            _module[_key] = specialKeys.includes(_key)
              ? _module[_key]
              : {
                  value: _module[_key],
                  hasError: false,
                  errorMessage: "",
                }
          })
        })
      } else if (key === "sessions") {
        draftTicketing[key].forEach((session, index) => {
          Object.keys(session).forEach(
            _key =>
              (draftTicketing[key][index][_key] = {
                value: draftTicketing[key][index][_key],
                hasError: false,
                errorMessage: "",
              })
          )
        })
      } else if (key === "initialTicketSetting") {
        Object.keys(draftTicketing[key].pricesAndQuotas).forEach(_key => {
          draftTicketing[key].pricesAndQuotas[_key] = {
            value: editTicketing[key].pricesAndQuotas[_key],
            checked:
              editTicketing[key].pricesAndQuotas[_key]?.weekday.quota ||
              editTicketing[key].pricesAndQuotas[_key]?.weekend.quota
                ? true
                : false,
            hasError: false,
            errorMessage: "",
          }
        })
      } else if (key === "ticketSettings") {
        if (draftTicketing[key]?.pricesAndQuotas) {
          const lastIndex = draftTicketing[key].length - 1
          const lastItem = draftTicketing[key][lastIndex]
          draftTicketing[key] = lastItem
          Object.keys(draftTicketing[key]?.pricesAndQuotas)?.forEach(_key => {
            draftTicketing[key].pricesAndQuotas[_key] = {
              value: draftTicketing[key].pricesAndQuotas[_key],
              checked:
                draftTicketing[key].pricesAndQuotas[_key]?.weekday.quota ||
                draftTicketing[key].pricesAndQuotas[_key]?.weekend.quota
                  ? true
                  : false,
              hasError: false,
              errorMessage: "",
            }
          })
        }
      }
    })
  })
  return {
    ...initialState,
    formValue: defaultFormValue,
    validateID: uuidV4(),
  }
}
