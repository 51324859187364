export const GET_TICKETINGS = "GET_TICKETINGS"
export const GET_TICKETINGS_SUCCESS = "GET_TICKETINGS_SUCCESS"
export const GET_TICKETINGS_FAIL = "GET_TICKETINGS_FAIL"

export const GET_TICKETING_BY_ID = "GET_TICKETING_BY_ID"
export const GET_TICKETING_BY_ID_SUCCESS = "GET_TICKETING_BY_ID_SUCCESS"
export const GET_TICKETING_BY_ID_FAIL = "GET_TICKETING_BY_ID_FAIL"

export const POST_TICKETING = "POST_TICKETING"
export const POST_TICKETING_SUCCESS = "POST_TICKETING_SUCCESS"
export const POST_TICKETING_FAIL = "POST_TICKETING_FAIL"

export const DELETE_TICKETING = "DELETE_TICKETING"
export const DELETE_TICKETING_SUCCESS = "DELETE_TICKETING_SUCCESS"
export const DELETE_TICKETING_FAIL = "DELETE_TICKETING_FAIL"

export const EDIT_TICKETING = "EDIT_TICKETING"
export const EDIT_TICKETING_SUCCESS = "EDIT_TICKETING_SUCCESS"
export const EDIT_TICKETING_FAIL = "EDIT_TICKETING_FAIL"

export const GET_TICKETINGS_COUNT = "GET_TICKETINGS_COUNT"
export const GET_TICKETINGS_COUNT_SUCCESS = "GET_TICKETINGS_COUNT_SUCCESS"
export const GET_TICKETINGS_COUNT_FAIL = "GET_TICKETINGS_COUNT_FAIL"

export const GENERATE_TICKETINGS = "GENERATE_TICKETINGS"
export const GENERATE_TICKETINGS_SUCCESS = "GENERATE_TICKETINGS_SUCCESS"
export const GENERATE_TICKETINGS_FAIL = "GENERATE_TICKETINGS_FAIL"

export const EXTEND_TICKETINGS = "EXTEND_TICKETINGS"
export const EXTEND_TICKETINGS_SUCCESS = "EXTEND_TICKETINGS_SUCCESS"
export const EXTEND_TICKETINGS_FAIL = "EXTEND_TICKETINGS_FAIL"

export const GET_TICKETING_QUOTAS = "GET_TICKETING_QUOTAS"
export const GET_TICKETING_QUOTAS_SUCCESS = "GET_TICKETING_QUOTAS_SUCCESS"
export const GET_TICKETING_QUOTAS_FAIL = "GET_TICKETING_QUOTAS_FAIL"

export const EDIT_TICKETING_QUOTAS = "EDIT_TICKETING_QUOTAS"
export const EDIT_TICKETING_QUOTAS_SUCCESS = "EDIT_TICKETING_QUOTAS_SUCCESS"
export const EDIT_TICKETING_QUOTAS_FAIL = "EDIT_TICKETING_QUOTAS_FAIL"

export const GET_TICKETING_QUOTAS_COUNT = "GET_TICKETING_QUOTAS_COUNT"
export const GET_TICKETING_QUOTAS_COUNT_SUCCESS =
  "GET_TICKETING_QUOTAS_COUNT_SUCCESS"
export const GET_TICKETING_QUOTAS_COUNT_FAIL = "GET_TICKETING_QUOTAS_COUNT_FAIL"

export const GET_MULTIPLE_TICKETING_QUOTAS = "GET_MULTIPLE_TICKETING_QUOTAS"
export const GET_MULTIPLE_TICKETING_QUOTAS_SUCCESS =
  "GET_MULTIPLE_TICKETING_QUOTAS_SUCCESS"
export const GET_MULTIPLE_TICKETING_QUOTAS_FAIL =
  "GET_MULTIPLE_TICKETING_QUOTAS_FAIL"
