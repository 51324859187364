import { takeEvery, put, all } from "redux-saga/effects"
import * as FileActionTypes from "./actionTypes"
import * as FileActions from "./actions"
import { axiosMp } from "../../utils"
import TcVod from "vod-js-sdk-v6"

function* uploadImages({ payload: { images, onSuccess, onError } }) {
  try {
    const imagesArr = images.map(image => {
      const formData = new FormData()
      formData.append("image", image)
      return axiosMp.post("/upload/images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    })
    const res = yield all(imagesArr)
    const imageUrls = res.map(_res => _res.data)
    yield put(FileActions.uploadImagesSuccess(imageUrls))
    onSuccess && onSuccess(imageUrls)
  } catch (error) {
    yield put(FileActions.uploadImagesFail(error))
    onError && onError(error)
  }
}

function* uploadVideos({ payload: { videos, onSuccess, onError } }) {
  try {
    const getSignature = () => {
      return axiosMp
        .post("/upload/videos/signature")
        .then(res => res.data.signature)
    }

    const videosArr = videos.map(video => {
      const tcVod = new TcVod({
        getSignature: getSignature, // The function to get the upload signature described above
      })
      const uploader = tcVod.upload({
        mediaFile: video, // Media file (video, audio, or image) in File type
      })
      uploader.on("media_progress", function (info) {
        console.log(`${video.name} media_progress`, `${info.percent * 100}%`)
      })
      return uploader.done()
    })

    const doneResult = yield all(videosArr)
    const videoUrls = doneResult.map(_res => ({
      url: _res.video.url?.replace(
        "1259246212.vod2.myqcloud.com",
        "1259246212.vod2.myqcloud.com" // might need to change to "mp-vod-mplus.org.hk"
      ),
    }))
    yield put(FileActions.uploadVideosSuccess(videoUrls))
    onSuccess && onSuccess(videoUrls)
  } catch (error) {
    console.log("error", error)
    yield put(FileActions.uploadVideosFail(error))
    onError && onError(error)
  }
}

function* uploadImagesAndVideos({ payload: { files, onSuccess, onError } }) {
  try {
    const getSignature = () => {
      return axiosMp
        .post("/upload/videos/signature")
        .then(res => res.data.signature)
    }
    const filesArr = files.map(file => {
      if (file instanceof File && file.type.startsWith("image/")) {
        // uploading image
        const formData = new FormData()
        formData.append("image", file)
        return axiosMp
          .post("/upload/images", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(res => ({ ...res.data, type: "image" }))
      } else if (file instanceof File) {
        // uploading video
        const tcVod = new TcVod({
          getSignature: getSignature, // The function to get the upload signature described above
        })
        const uploader = tcVod.upload({
          mediaFile: file, // Media file (video, audio, or image) in File type
        })
        uploader.on("media_progress", function (info) {
          console.log(`${file.name} media_progress`, `${info.percent * 100}%`)
        })
        return uploader.done().then(res => ({
          url: res.video.url?.replace(
            "1259246212.vod2.myqcloud.com",
            "1259246212.vod2.myqcloud.com" // might need to change to "mp-vod-mplus.org.hk"
          ),
          type: "video",
        }))
      } else {
        // already has url and type
        return Promise.resolve(file)
      }
    })
    const doneResult = yield all(filesArr)
    yield put(FileActions.uploadImagesSuccess(doneResult))
    onSuccess && onSuccess(doneResult)
  } catch (error) {
    yield put(FileActions.uploadImagesFail(error))
    onError && onError(error)
  }
}

function* filesSaga() {
  yield takeEvery(FileActionTypes.UPLOAD_IMAGES, uploadImages)
  yield takeEvery(FileActionTypes.UPLOAD_VIDEOS, uploadVideos)
  yield takeEvery(
    FileActionTypes.UPLOAD_IMAGES_AND_VIDEOS,
    uploadImagesAndVideos
  )
}

export default filesSaga
