import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap"
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert"
import { articleStatus, articleTabCategories } from "../../utils"

const EcommerceOrderColumns = ({
  handleDeleteArticle,
  handleEditArticle,
  handlePreviewArticle,
}) => [
  {
    dataField: "id",
    text: "ID",
    formatter: cellContent => {
      return (
        <Link to="#" className="text-body font-weight-bold">
          {cellContent}
        </Link>
      )
    },
  },
  {
    dataField: "sc",
    text: "Title",
    sort: true,
    formatter: cellContent => cellContent.title,
  },
  {
    dataField: "article_tab",
    text: "Category",
    sort: true,
    formatter: cellContent => articleTabCategories[cellContent.category]?.title,
  },
  {
    dataField: "category_sortIndex",
    text: "Index",
    sort: true,
  },
  {
    dataField: "created_at",
    text: "Creation Date",
    sort: true,
    formatter: cellContent => moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "updated_at",
    text: "Modified Date",
    sort: true,
    formatter: cellContent => moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "status",
    text: "Status",
    sort: true,
    formatter: cellContent => articleStatus[cellContent].title,
  },
  {
    dataField: "featured_eventPage",
    text: "Featuring",
    sort: true,
    formatter: (cellContent, row) => {
      const featureArr = []
      if (row.featured_homepage) featureArr.push("Home")
      if (row.featured_eventPage) featureArr.push("Event")
      return featureArr.join(" , ")
    },
  },
  {
    dataField: "",
    text: "",
    sort: true,
    formatter: (cellContent, row) => {
      return (
        <ArticleDropDown
          handleEditArticle={() => handleEditArticle(row)}
          handleDeleteArticle={() => handleDeleteArticle(row.id)}
          handlePreviewArticle={() => handlePreviewArticle(row.id)}
        />
      )
    },
  },
]

export default EcommerceOrderColumns

const ArticleDropDown = ({
  handleEditArticle,
  handleDeleteArticle,
  handlePreviewArticle,
}) => {
  const [alertShow, setAlertShow] = useState(false)
  return (
    <>
      <UncontrolledDropdown className="dropdown">
        <DropdownToggle className="text-muted font-size-16" color="white">
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right" direction="right">
          <DropdownItem onClick={handlePreviewArticle}>preview</DropdownItem>
          <DropdownItem onClick={handleEditArticle}>edit</DropdownItem>
          <DropdownItem onClick={() => setAlertShow(true)}>delete</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          handleDeleteArticle()
          setAlertShow(false)
        }}
        onCancel={() => setAlertShow(false)}
        focusCancelBtn
        show={alertShow}
      >
        Are you sure you want to delete this article?
      </SweetAlert>
    </>
  )
}
