import React, { useEffect, useReducer } from "react"
import { Container, Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"
import produce from "immer"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import ArticlesOrderColumns from "./ArticlesOrderColumns"
import BootstrapTable from "react-bootstrap-table-next"
import { NotificationContainer, NotificationManager } from "react-notifications"

import * as ArticlesActions from "../../store/articles/actions"
import { articleTabCategories, articleStatus } from "../../utils"
import { axiosMp } from "../../utils/"
import { QrcodeModal } from "../../components/Modals"

const initialState = {
  curretPage: 1,
  sizePerPage: 10,
  category: "",
  status: "",
  featuring: "",
  qrcodeModalVisible: false,
  qrcodeBase64: "",
}

const reducer = produce((draft, action) => {
  const { page, value, qrcode } = action.payload
  switch (action.type) {
    case "onPrevPage":
      draft.curretPage = draft.curretPage - 1 || 1
      break
    case "OnSpecificPage":
      draft.curretPage = page
      break
    case "OnCategoryChange":
      draft.category = value
      break
    case "OnStatusChange":
      draft.status = value
      break
    case "OnFeaturingChange":
      draft.featuring = value
      break
    case "OnQrcodeModalToggle":
      draft.qrcodeModalVisible = value
      draft.qrcodeBase64 = value ? qrcode : ""
      break
    default:
      break
  }
})

const Articles = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { articles, articlesTotalCount, history } = props
  const pageOptions = {
    page: state.curretPage,
    sizePerPage: state.sizePerPage,
    totalSize: articlesTotalCount,
    custom: true,
    onPageChange: page =>
      dispatch({ type: "OnSpecificPage", payload: { page } }),
  }
  console.log("state", state)
  useEffect(() => {
    getArticlesAndArticlesCount()
  }, [state.curretPage])

  const getArticlesAndArticlesCount = resetToPage1 => {
    if (resetToPage1 && state.curretPage !== 1) {
      dispatch({ type: "OnSpecificPage", payload: { page: 1 } })
      return
    }
    const articleCountFilters = {
      ...(state.category && { "article_tab.category": state.category }),
      ...(state.status && { status: state.status }),
      ...(state.featuring && { [state.featuring]: true }),
    }
    const articleFilters = {
      _limit: state.sizePerPage,
      _start: (state.curretPage - 1) * state.sizePerPage,
      _sort: "category_sortIndex:desc,created_at:desc",
      ...articleCountFilters,
    }
    props.onGetArticles(articleFilters)
    props.onGetArticlesCount(articleCountFilters)
  }
  const handlePreviewArticle = async articleId => {
    const res = await axiosMp.get(`/qrcode`, {
      params: {
        id: articleId,
        path: "article",
      },
    })
    if (res.data?.errcode) {
      NotificationManager.error("Getting Article preview failed", "Error", 3000)
    } else {
      dispatch({
        type: "OnQrcodeModalToggle",
        payload: { value: true, qrcode: res.data?.base64String },
      })
    }
    console.log("!H!H!H!Hres", res.data)
  }
  const handleDeleteArticle = async articleId => {
    await new Promise((resolve, reject) =>
      props.onDeleteArticle(articleId, resolve, reject)
    )
    // spectial situation ***
    if ((state.curretPage - 1) * state.sizePerPage + 1 === articlesTotalCount) {
      // if the currenet page's first item is the last item
      // after doing delete need to go to previous page
      dispatch({ type: "onPrevPage", payload: {} })
    } else {
      // normal situation refresh
      getArticlesAndArticlesCount()
    }
  }
  const handleEditArticle = article => {
    history.push({
      pathname: `/article/${article.id}/edit`,
    })
  }
  const handleSelectChange = (e, key) => {
    dispatch({
      type: key,
      payload: {
        value: e.target.value,
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Articles</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md="1">
                      <Label className="mb-3">Filter :</Label>
                    </Col>
                    <Col md="3">
                      <select
                        className="form-control select2 mb-3"
                        value={state.category}
                        onChange={e =>
                          handleSelectChange(e, "OnCategoryChange")
                        }
                      >
                        <option value="">Category</option>
                        {Object.values(articleTabCategories).map(category => (
                          <option key={category.value} value={category.value}>
                            {category.title}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="3">
                      <select
                        className="form-control select2 mb-3"
                        value={state.featuring}
                        onChange={e =>
                          handleSelectChange(e, "OnFeaturingChange")
                        }
                      >
                        <option value="">Featuring</option>
                        <option value="featured_homepage">
                          Feature in Home Page
                        </option>
                      </select>
                    </Col>

                    <Col md="2">
                      <select
                        className="form-control select2 mb-3"
                        value={state.status}
                        onChange={e => handleSelectChange(e, "OnStatusChange")}
                      >
                        <option value="">Status</option>
                        {Object.values(articleStatus).map(as => (
                          <option key={as.value} value={as.value}>
                            {as.title}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col md="2">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md mb-3"
                        onClick={() => getArticlesAndArticlesCount(true)}
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <Link
                        to="/create-article"
                        className="btn btn-primary mr-2 w-md mb-3 mt-4"
                      >
                        Create
                      </Link>
                      {/* <Button
                        type="button"
                        color="secondary"
                        className="w-md mr-2 mb-3 mt-4"
                      >
                        Export
                      </Button> */}
                    </Col>
                  </Row>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={articles}
                        columns={ArticlesOrderColumns({
                          handleDeleteArticle,
                          handleEditArticle,
                          handlePreviewArticle,
                        })}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={() =>
                                      console.log("onTable CHange????")
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <QrcodeModal
            isOpen={state.qrcodeModalVisible}
            qrcodeBase64={state.qrcodeBase64}
            onCancel={() =>
              dispatch({
                type: "OnQrcodeModalToggle",
                payload: { value: false },
              })
            }
          ></QrcodeModal>
          <NotificationContainer />
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ articles }) => ({
  articles: articles.articles,
  articlesTotalCount: articles.articlesTotalCount,
})

const mapDispatchToProps = dispatch => ({
  onGetArticles: (filters, onSuccess, onError) =>
    dispatch(ArticlesActions.getArticles({ filters, onSuccess, onError })),
  onDeleteArticle: (articleId, onSuccess, onError) =>
    dispatch(ArticlesActions.deleteArticle({ articleId, onSuccess, onError })),
  onGetArticlesCount: (filters, onSuccess, onError) =>
    dispatch(ArticlesActions.getArticlesCount({ filters, onSuccess, onError })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Articles)
