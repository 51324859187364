import * as CouponActionTypes from "./actionTypes"

//--------------------Article associated--------------------------------
// getCoupons
export const getCoupons = filtersWithCallbacks => ({
  type: CouponActionTypes.GET_COUPONS,
  payload: filtersWithCallbacks,
})

export const getCouponsSuccess = coupons => ({
  type: CouponActionTypes.GET_COUPONS_SUCCESS,
  payload: coupons,
})

export const getCouponsFail = error => ({
  type: CouponActionTypes.GET_COUPONS_FAIL,
  payload: error,
})

// getCouponById
export const getCouponById = couponIdWithCallbacks => ({
  type: CouponActionTypes.GET_COUPON_BY_ID,
  payload: couponIdWithCallbacks,
})

export const getCouponByIdSuccess = coupons => ({
  type: CouponActionTypes.GET_COUPON_BY_ID_SUCCESS,
  payload: coupons,
})

export const getCouponByIdFail = error => ({
  type: CouponActionTypes.GET_COUPON_BY_ID_FAIL,
  payload: error,
})

// postCoupon
export const postCoupon = couponWithCallbacks => ({
  type: CouponActionTypes.POST_COUPON,
  payload: couponWithCallbacks,
})

export const postCouponSuccess = res => ({
  type: CouponActionTypes.POST_COUPON_SUCCESS,
  payload: res,
})

export const postCouponFail = error => ({
  type: CouponActionTypes.POST_COUPON_FAIL,
  payload: error,
})

// deleteCoupon
export const deleteCoupon = couponIdWithCallbacks => ({
  type: CouponActionTypes.DELETE_COUPON,
  payload: couponIdWithCallbacks,
})

export const deleteCouponSuccess = res => ({
  type: CouponActionTypes.DELETE_COUPON_SUCCESS,
  payload: res,
})

export const deleteCouponFail = error => ({
  type: CouponActionTypes.DELETE_COUPON_FAIL,
  payload: error,
})

// editCoupon
export const editCoupon = couponWithCallbacks => ({
  type: CouponActionTypes.EDIT_COUPON,
  payload: couponWithCallbacks,
})

export const editCouponSuccess = res => ({
  type: CouponActionTypes.EDIT_COUPON_SUCCESS,
  payload: res,
})

export const editCouponFail = error => ({
  type: CouponActionTypes.EDIT_COUPON_FAIL,
  payload: error,
})

// getCoupons count
export const getCouponsCount = filtersWithCallbacks => ({
  type: CouponActionTypes.GET_COUPONS_COUNT,
  payload: filtersWithCallbacks,
})

export const getCouponsCountSuccess = couponsCount => ({
  type: CouponActionTypes.GET_COUPONS_COUNT_SUCCESS,
  payload: couponsCount,
})

export const getCouponsCountFail = error => ({
  type: CouponActionTypes.GET_COUPONS_COUNT_FAIL,
  payload: error,
})
