import * as ArticleActionTypes from "./actionTypes"

const INIT_STATE = {
  articles: [],
  articlesTotalCount: 0,
  error: {},
  articleTabs: [],
  articleTabsTotalCount: 0,
}

const articles = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ArticleActionTypes.GET_ARTICLES:
      return {
        ...state,
        error: {},
      }
    case ArticleActionTypes.GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.payload,
      }
    case ArticleActionTypes.GET_ARTICLES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ArticleActionTypes.GET_ARTICLE_TAB:
      return {
        ...state,
        error: {},
      }
    case ArticleActionTypes.GET_ARTICLE_TAB_SUCCESS:
      return {
        ...state,
        articleTabs: action.payload,
      }
    case ArticleActionTypes.GET_ARTICLE_TAB_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ArticleActionTypes.GET_ARTICLES_COUNT:
      return {
        ...state,
        error: {},
      }
    case ArticleActionTypes.GET_ARTICLES_COUNT_SUCCESS:
      return {
        ...state,
        articlesTotalCount: action.payload,
      }
    case ArticleActionTypes.GET_ARTICLES_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ArticleActionTypes.GET_ARTICLE_TAB_COUNT:
      return {
        ...state,
        error: {},
      }
    case ArticleActionTypes.GET_ARTICLE_TAB_COUNT_SUCCESS:
      return {
        ...state,
        articleTabsTotalCount: action.payload,
      }
    case ArticleActionTypes.GET_ARTICLE_TAB_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default articles
