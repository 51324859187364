import React, { useReducer, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
} from "reactstrap"
import produce from "immer"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"
import moment from "moment"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import * as UserActions from "../../store/users/actions"
import * as LoginActions from "../../store/login/actions"

const initialState = {
  formValue: {
    avatarUrl: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    dob: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    created_at: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    nickname: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    phone_countryCode: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    phone_number: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    wx_openId: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
  },
}

const editModeInitialState = editUser => {
  const defaultFormValue = produce(editUser, draftEditUser => {
    Object.keys(draftEditUser).forEach(key => {
      const keysToignore = [
        "updated_at",
        "email",
        "id",
        "wkcda_masterCustomerId",
        "phone",
      ]
      if (keysToignore.includes(key)) {
        delete draftEditUser[key]
      } else {
        draftEditUser[key] = {
          value: draftEditUser[key],
          hasError: false,
          errorMessage: "",
        }
      }
    })
  })
  return {
    ...initialState,
    formValue: defaultFormValue,
  }
}

const reducer = produce((draft, action) => {
  const { key, value } = action.payload
  switch (action.type) {
    case "OnInputChange":
    case "OnSelectChange":
    case "OnDateChange":
      draft.formValue[key].value = value
      draft.formValue[key].hasError = false
      break
    case "OnUpdateFormValue":
      draft.formValue = value
      break
    case "OnSetAllState":
      return value
    default:
      break
  }
})

const EditUser = props => {
  const { match } = props
  const { path, params } = match
  const isEditMode = path === "/user/:id/edit"
  const [state, dispatch] = useReducer(reducer, initialState)
  const { formValue } = state
  console.log("state", state)
  useEffect(async () => {
    if (isEditMode && params.id) {
      const userRes = await new Promise((resolve, reject) =>
        props.onGetUserById(params.id, resolve, reject)
      )
      dispatch({
        type: "OnSetAllState",
        payload: { value: editModeInitialState(userRes) },
      })
    }
  }, [])
  const handleInputChange = (e, key) => {
    dispatch({
      type: "OnInputChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }
  const handleDateChange = (val, key) => {
    dispatch({
      type: "OnDateChange",
      payload: {
        value: val,
        key: key,
      },
    })
  }
  const handleSelectChange = (e, key) => {
    dispatch({
      type: "OnSelectChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }
  const handleSavePress = async () => {
    if (!validateFields()) return

    const { history, onEditUser, onShowLoading, onHideLoading } = props

    try {
      const { formValue } = state
      onShowLoading()

      const submitForm = produce(formValue, draftFormValue => {
        Object.keys(draftFormValue).forEach(key => {
          const keysToSubmit = ["dob", "phone_countryCode", "phone_number"]
          if (keysToSubmit.includes(key))
            draftFormValue[key] = draftFormValue[key].value
          else delete draftFormValue[key]
        })
      })

      if (isEditMode) {
        await new Promise((resolve, reject) =>
          onEditUser(params?.id, submitForm, resolve, reject)
        )
      }
      onHideLoading()
      history.push("/users")
    } catch (err) {
      console.log(err)
      onHideLoading()
    }
  }
  const validateFields = () => {
    let isAllValidate = true
    return isAllValidate
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>{isEditMode ? "Edit Users" : "Create Users"}</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">User Info</CardTitle>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="name">Name (from WeChat Login)</Label>
                        <Input
                          name="name"
                          type="text"
                          className="form-control bg-light"
                          value={formValue.nickname.value}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="title">
                          User Thumbnail (from WeChat Login)
                        </Label>
                        <img
                          src={formValue.avatarUrl.value}
                          alt=""
                          style={{
                            width: 70,
                            height: 70,
                            display: "block",
                            backgroundColor: "#f8f9fa",
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="shortDescription">
                          Open ID (from WeChat Login)
                        </Label>
                        <Input
                          name="shortDescription"
                          type="text"
                          className="form-control bg-light"
                          value={formValue.wx_openId.value}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="shortDescription">Creation Date</Label>
                        <Input
                          name="shortDescription"
                          type="text"
                          className="form-control bg-light"
                          value={moment(formValue.created_at.value).format(
                            "MMM DD, YYYY"
                          )}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="shortDescription">Phone Number</Label>
                        <Row>
                          <Col sm="5">
                            <select
                              className="form-control select2"
                              value={formValue.phone_countryCode.value}
                              onChange={e =>
                                handleSelectChange(e, "phone_countryCode")
                              }
                            >
                              <option value={""} selected>
                                Select code
                              </option>
                              <option key={"86"} value={"86"}>
                                {"+86"}
                              </option>
                              <option key={"852"} value={"852"}>
                                {"+852"}
                              </option>
                            </select>
                          </Col>
                          <Col sm="7">
                            <Input
                              name="shortDescription"
                              type="text"
                              className="form-control"
                              value={formValue.phone_number.value}
                              onChange={e => {
                                handleInputChange(e, "phone_number")
                              }}
                              invalid={formValue.phone_number.hasError}
                            />
                          </Col>
                        </Row>
                        <FormFeedback>
                          {formValue.phone_number.errorMessage}
                          ggg
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="shortDescription">Date of Birth</Label>
                        <DatePicker
                          selected={
                            formValue.dob.value
                              ? new Date(moment(formValue.dob.value).valueOf())
                              : formValue.dob.value
                          }
                          onChange={val => {
                            handleDateChange(
                              val ? moment(val?.valueOf()).format() : null,
                              "dob"
                            )
                          }}
                          className="form-control"
                          placeholderText="Select date"
                          dateFormat="MMM dd, yyyy"
                        />
                        <FormFeedback>
                          {formValue.dob.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>
            <Button
              className="mr-3 w-md"
              color="primary"
              onClick={handleSavePress}
            >
              Save
            </Button>
            <Link to="/users" className="btn btn-secondary mr-2 w-md">
              Cancel
            </Link>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ articles }) => ({
  articleTabs: articles.articleTabs,
})

const mapDispatchToProps = dispatch => ({
  onGetUserById: (userId, onSuccess, onError) =>
    dispatch(
      UserActions.getUserById({
        userId,
        onSuccess,
        onError,
      })
    ),
  onEditUser: (userId, user, onSuccess, onError) =>
    dispatch(
      UserActions.editUser({
        userId,
        user,
        onSuccess,
        onError,
      })
    ),
  onShowLoading: () => dispatch(LoginActions.showLoading()),
  onHideLoading: () => dispatch(LoginActions.hideLoading()),
})
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(EditUser)
