import React from "react"
import { Redirect } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Articles
import Articles from "../pages/Articles/index"
import CreateArticle from "../pages/Articles/create-article"
import ArticleTabs from "../pages/ArticleTabs/index"
import CreateArticleTab from "../pages/ArticleTabs/create-article-tab"

import HomepageBanners from "../pages/HomepageBanners/index"

import Coupons from "../pages/Coupons/index"
import CreateCoupon from "../pages/Coupons/create-coupon"

import Ticketings from "../pages/Ticketings/index"
import CreateTicketing from "../pages/Ticketings/create-ticket"
import EditTicketingQuotas from "../pages/Ticketings/edit-ticket-quotas"

import Transactions from "../pages/Transactions/index"
import ViewTransaction from "../pages/Transactions/view-transaction"

import Reports from "../pages/Reports/index"

import Users from "../pages/Users/index"
import EditUser from "../pages/Users/edit-user"

import Admins from "../pages/Admins/index"
import CreateAdmin from "../pages/Admins/create-admin"

const userRoutes = [
  // { path: "/dashboard", component: Dashboard },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/articles" />,
  },

  // articles
  { path: "/articles", component: Articles, accessType: ["super", "normal"] },
  {
    path: "/create-article",
    component: CreateArticle,
    accessType: ["super", "normal"],
  },
  {
    path: "/article/:id/edit",
    component: CreateArticle,
    accessType: ["super", "normal"],
  },
  {
    path: "/article-tabs",
    component: ArticleTabs,
    accessType: ["super", "normal"],
  },
  {
    path: "/create-article-tab",
    component: CreateArticleTab,
    accessType: ["super", "normal"],
  },
  {
    path: "/article-tab/:id/edit",
    component: CreateArticleTab,
    accessType: ["super", "normal"],
  },
  // homepage banners
  {
    path: "/homepage-banners",
    component: HomepageBanners,
    accessType: ["super", "normal"],
  },
  // coupons
  { path: "/coupons", component: Coupons, accessType: ["super", "ticketing"] },
  {
    path: "/create-coupon",
    component: CreateCoupon,
    accessType: ["super", "ticketing"],
  },
  {
    path: "/coupon/:id/edit",
    component: CreateCoupon,
    accessType: ["super", "ticketing"],
  },
  //ticketings
  {
    path: "/ticketings",
    component: Ticketings,
    accessType: ["super", "ticketing"],
  },
  {
    path: "/create-ticketing",
    component: CreateTicketing,
    accessType: ["super", "ticketing"],
  },
  {
    path: "/ticketing/:id/edit",
    component: CreateTicketing,
    accessType: ["super", "ticketing"],
  },
  {
    path: "/ticketing-quotas/:id/edit",
    component: EditTicketingQuotas,
    accessType: ["super", "ticketing"],
  },
  // transactions
  {
    path: "/transactions",
    component: Transactions,
    accessType: ["super", "ticketing"],
  },
  {
    path: "/transaction/:id",
    component: ViewTransaction,
    accessType: ["super", "ticketing"],
  },
  // reports
  { path: "/reports", component: Reports, accessType: ["super", "ticketing"] },
  // users
  { path: "/users", component: Users, accessType: ["super"] },
  { path: "/user/:id/edit", component: EditUser, accessType: ["super"] },
  // admins
  { path: "/admins", component: Admins, accessType: ["super"] },
  { path: "/create-admin", component: CreateAdmin, accessType: ["super"] },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }
