const transactionStatus = {
  paid: {
    value: "paid",
    title: "Paid",
  },
  failed: {
    value: "failed",
    title: "Failed",
  },
  voided: {
    value: "voided",
    title: "Voided",
  },
  timeout: {
    value: "timeout",
    title: "Timeout",
  },
  reserving: {
    value: "reserving",
    title: "Reserving",
  },
  reserved: {
    value: "reserved",
    title: "Reserved",
  },
}

export default transactionStatus
