import axios from "axios"
import * as localStorageHelper from "./localStorageHelper"

const responsePrinter = response => {
  const isSuccess = response.status >= 200 && response.status < 300
  isSuccess
    ? console.group(
        `%cResponse ${response.status} from ${response.config.url}`,
        "color: white; font-style: italic; background-color: green;padding: 2px;border-radius: 4px;"
      )
    : console.group(
        `%cResponse Error ${response.status} from ${response.config.url}`,
        "color: white; font-style: italic; background-color: red;padding: 2px;border-radius: 4px;"
      )
  console.log(response)
  console.groupEnd()
}

const axiosMp = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
})

// Add a request interceptor
axiosMp.interceptors.request.use(
  function (config) {
    const token = localStorageHelper.getUserToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    console.log("Request", config)
    return config
  },
  function (error) {
    console.log("Request error", error)
    return Promise.reject(error)
  }
)

// Add a response interceptor
axiosMp.interceptors.response.use(
  function (response) {
    // is login api
    if (response.config.url.includes("/admins/login")) {
      localStorageHelper.setUserToken(response.data.token)
    }
    responsePrinter(response) // for debuging
    return response
  },
  function (error) {
    const { response } = error
    responsePrinter(response) // for debuging
    if (response.status === 401) {
      // unAuthorize
      localStorageHelper.clearUserToken()
    }
    return Promise.reject(error)
  }
)

export default axiosMp
