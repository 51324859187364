export const getUserToken = () => {
  return localStorage.getItem("mPlusToken")
}

export const setUserToken = token => {
  localStorage.setItem("mPlusToken", token)
  return token
}

export const clearUserToken = () => {
  localStorage.removeItem("mPlusToken")
}
