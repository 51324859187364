import React from "react"
import { CloseButton } from "../Buttons"

const CloseButtonWrapper = ({ onClose, children, style }) => {
  return (
    <div style={{ ...style, position: "relative" }}>
      {children}
      <CloseButton
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 20,
          height: 20,
          fontSize: "17px",
        }}
        onClose={onClose}
      ></CloseButton>
    </div>
  )
}

export default CloseButtonWrapper
