import { takeEvery, put } from "redux-saga/effects"
import * as UserActionTypes from "./actionTypes"
import * as UserActions from "./actions"
import * as LoginActions from "../login/actions"
import { axiosMp } from "../../utils"
import querystring from "querystring"

function* fetchUsers({ payload: { filters, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(queries ? `/users?${queries}` : `/users`)
    yield put(UserActions.getUsersSuccess(res.data.results))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(UserActions.getUsersFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* getUserById({ payload: { userId, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.get(`/users/${userId}`)
    yield put(UserActions.getUserByIdSuccess(res.data))
    onSuccess && onSuccess(res.data)
  } catch (error) {
    yield put(UserActions.getUserByIdFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* editUser({ payload: { userId, user, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.put(`/users/${userId}`, user)
    yield put(UserActions.editUserSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(UserActions.editUserFail(error))
    onError && onError(error)
  }
}

function* getUsersCount({ payload: { filters, onSuccess, onError } }) {
  try {
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/users/count?${queries}` : `/users/count`
    )
    yield put(UserActions.getUsersCountSuccess(res.data.count))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(UserActions.getUsersCountFail(error))
    onError && onError(error)
  }
}

function* getUsersReport({ payload: { filters, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const countQueries = querystring.stringify(filters)
    const countRes = yield axiosMp.get(
      countQueries ? `/users/count?${countQueries}` : `/users/count`
    )
    const queries = querystring.stringify({
      _limit: countRes.data.count,
      ...filters,
    })
    console.log("countRes", countRes, countRes.data.count)
    const res = yield axiosMp.get(queries ? `/users?${queries}` : `/users`)
    yield put(UserActions.getUsersReportSuccess(res.data.results))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(UserActions.getUsersReportFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* articlesSaga() {
  yield takeEvery(UserActionTypes.GET_USERS, fetchUsers)
  yield takeEvery(UserActionTypes.GET_USER_BY_ID, getUserById)
  yield takeEvery(UserActionTypes.EDIT_USER, editUser)
  yield takeEvery(UserActionTypes.GET_USERS_COUNT, getUsersCount)
  yield takeEvery(UserActionTypes.GET_USERS_REPORT, getUsersReport)
}

export default articlesSaga
