const listingCategories = {
  homepage: {
    title: "HomePage",
    value: "homepage",
    destination: "/index/index",
  },
  eventListing: {
    title: "Event listing",
    value: "eventListing",
    destination: "/events/events",
  },
  ticketListing: {
    title: "Ticket listing",
    value: "ticketListing",
    destination: "/ticketing/ticketing",
  },
  shopListing: {
    title: "Shop listing",
    value: "shopListing",
    destination: "/articles/articles?id=shop",
  },
  restaurantListing: {
    title: "Restaurant Listing",
    value: "restaurantListing",
    destination: "/articles/articles?id=dining",
  },
}

export default listingCategories
