import React from "react"
import { CloseButton } from "../Buttons"

const FileImageDisplayer = ({ file, fileUrlObj, onClose }) => {
  const imageUrl = file && URL.createObjectURL(file)
  return (
    <>
      {file?.type.startsWith("image/") && (
        <ImageDisplayer url={imageUrl} onClose={onClose}></ImageDisplayer>
      )}
      {file?.type.startsWith("video/") && (
        <VideoDisplayer url={imageUrl} onClose={onClose}></VideoDisplayer>
      )}
      {fileUrlObj?.type === "image" && fileUrlObj?.url && (
        <ImageDisplayer
          url={fileUrlObj?.url}
          onClose={onClose}
        ></ImageDisplayer>
      )}
      {fileUrlObj?.type === "video" && fileUrlObj?.url && (
        <VideoDisplayer
          url={fileUrlObj?.url}
          onClose={onClose}
        ></VideoDisplayer>
      )}
      {/* <div>{imageUrl || fileUrlObj?.url}</div> */}
    </>
  )
}

export default React.memo(FileImageDisplayer)

const ImageDisplayer = ({ url, onClose }) => {
  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        marginTop: 6,
        marginRight: 6,
      }}
    >
      <div
        style={{
          width: 60,
          height: 60,
          border: "1px solid rgb(206, 212, 218)",
          borderRadius: "4px",
          backgroundImage: `url(${url})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <CloseButton
        style={{ position: "absolute", top: -6, right: -6 }}
        onClose={onClose}
      ></CloseButton>
    </div>
  )
}

const VideoDisplayer = ({ url, onClose }) => {
  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
        marginTop: 6,
        marginRight: 6,
      }}
    >
      <video src={url} controls width="300"></video>
      <CloseButton
        style={{ position: "absolute", top: -6, right: -6 }}
        onClose={onClose}
      ></CloseButton>
    </div>
  )
}
