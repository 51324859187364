import React, { useState, useEffect, useRef, useReducer } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  //   FormFeedback,
} from "reactstrap"
import DatePicker from "react-datepicker"
import { CSVLink } from "react-csv"
import produce from "immer"
import moment from "moment"

import { NotificationContainer } from "react-notifications"
import * as TransactionsActions from "../../store/transactions/actions"
import * as TicketingsActions from "../../store/ticketings/actions"
import * as LoginActions from "../../store/login/actions"

const initialState = {
  eventId: "",
  eventStartDate: "",
  eventEndDate: "",
  orderStartDate: "",
  orderEndDate: "",
  reportType: "",
}

const reducer = produce((draft, action) => {
  const { value, key } = action.payload
  switch (action.type) {
    case "OnEventIdChange":
      draft.eventId = value
      break
    case "OnReportTypeChange":
      draft.reportType = value
      if (value !== "Patron") {
        draft.orderStartDate = ""
        draft.orderEndDate = ""
      }
      break
    case "OnDateChange":
      draft[key] = !value ? null : moment(value?.valueOf()).format("YYYY-MM-DD")
      break
    default:
      break
  }
})

const Reports = props => {
  const [csvReport, setCsvReport] = useState(undefined)
  const [state, dispatch] = useReducer(reducer, initialState)
  const csvInstance = useRef()
  console.log("!!!state!!!", state)
  useEffect(() => {
    if (csvReport && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click()
        setCsvReport(undefined)
      })
    }
  }, [csvReport])
  const handleInputChange = (e, key) => {
    dispatch({
      type: key,
      payload: {
        value: e.target.value,
      },
    })
  }

  const handleSelectChange = (e, key) => {
    dispatch({
      type: key,
      payload: {
        value: e.target.value,
      },
    })
  }

  const handleDateChange = (val, key) => {
    dispatch({
      type: "OnDateChange",
      payload: {
        value: val,
        key: key,
      },
    })
  }
  const handleSavePress = async () => {
    const transactionFilters = {
      // basics
      // _limit: 10, // for debug
      // _start: 10, // for debug
      _sort: "ctime:desc",
      // filters
      ...(state.eventId && { eventId: state.eventId }),
      ...(state.eventStartDate && { startDate: state.eventStartDate }),
      ...(state.eventEndDate && { endDate: state.eventEndDate }),
      ...(state.orderStartDate && {
        transactionStartDateTime: state.orderStartDate,
      }),
      ...(state.orderEndDate && {
        transactionEndDateTime: state.orderEndDate,
      }),
    }
    console.log("transactionFilters", transactionFilters)
    const res = await new Promise((resolve, reject) =>
      props.onGetTransactionReport(transactionFilters, resolve, reject)
    )
    console.log("res", res)
    const res2 = res.map(transaction => {
      const commonInfo = {
        ticketStatus: transaction.state,
        eventId: transaction.eventId,
        eventName: transaction.event?.sc?.title,
        eventStatus: transaction.event?.status,
        eventDate: transaction.date,
        eventSession: transaction?.event?.sessions.find(
          session => session.id === transaction?.sessionId
        )?.sc_name,
        eventSessionId: transaction?.sessionId,
        noOfTicketsEachTransaction: transaction?.items?.reduce(
          (acc, cur) => acc + cur.quantity,
          0
        ),
        totalTransactionValue: transaction?.items?.reduce(
          (acc, cur) => acc + cur.quantity * cur.grossPrice,
          0
        ),
        name: transaction?.contact?.name,
        phoneNumber: `${transaction?.contact?.phone_countryCode}-${transaction?.contact?.phone_number}`,
        transactionDateTime: transaction?.ctime,
        transactionNumber: transaction?.oceanpayment?.payment_id,
        formPayment: transaction?.oceanpayment?.methods,
        merchantReference: transaction?._id,
      }
      return transaction.items.map((item, index) => {
        const ticketInfo = {
          ticketType: item.ticketType,
          ticketTypeId: item.inventoryId,
          // quantity: item.quantity,
          pricePerTicket: item.grossPrice,
          ticketCode: item?.tickets?.[index],
          ...commonInfo,
        }
        return new Array(item.quantity).fill(1).map((_, _index) => ({
          ...ticketInfo,
          ticketCode: item?.tickets?.[_index],
        }))
      })
    })
    const flattenRes = res2.flat(2)
    console.log("flattenRes", flattenRes)
    if (state.reportType === "Patron") {
      generatePatronReport(flattenRes)
    } else if (state.reportType === "Sales") {
      generateSalesInventoryReport(flattenRes) // contains old inventory report
    } else {
      generateInventoryReport()
    }
  }

  const generatePatronReport = flattenTransactions => {
    const csvReportName = ["Patron Reports"]
    const csvCreateTime = [
      `Generated Date/Time: ${moment().format("DD-MMM-YYYY HH:mm:ss")}`,
    ]
    const csvColumns = [
      "Merchant Reference (Out trade no)",
      "Transaction Number",
      "Phone Number",
      "Name",
      "Transcation Date/Time",
      "Form of Payment",
      "Event ID",
      "Event Name",
      "Event Date",
      "Event Session ID",
      "Event Session",
      "Ticket Type ID",
      "Ticket Type (Price Class)",
      "Price Value Per Ticket (HKD)",
      "No. of Tickets of Each Transaction",
      "Total Transacted Value of Each Transaction",
      "Ticket Code of Each Ticket (QR Code)",
      'Ticket Status (add "Failed" status)',
    ]
    const flattenRes2CsvRow = flattenTransactions.map(_res => [
      _res.merchantReference,
      _res.transactionNumber,
      _res.phoneNumber,
      _res.name,
      moment(_res.transactionDateTime).format("MM/DD/YY HH:mm"),
      _res.formPayment,
      _res.eventId,
      _res.eventName,
      _res.eventDate,
      _res.eventSessionId,
      _res.eventSession,
      _res.ticketTypeId,
      _res.ticketType,
      _res.pricePerTicket,
      _res.noOfTicketsEachTransaction,
      _res.totalTransactionValue,
      _res.ticketCode,
      _res.ticketStatus,
    ])
    const csvOutput = [
      csvReportName,
      csvCreateTime,
      csvColumns,
      ...flattenRes2CsvRow,
    ]
    console.log("csvOutput!!!!", csvOutput)
    setCsvReport(csvOutput)
  }
  // contains old inventory report
  const generateSalesInventoryReport = async flattenTransactions => {
    console.log("flattenTransactions", flattenTransactions)
    const isSalesReport = state.reportType === "Sales"
    const eventIdArrs = []
    const ticketTypeAmountMap = flattenTransactions.reduce((acc, cur) => {
      if (cur.ticketStatus !== "paid") return acc
      const soldAccumulate = (acc[cur.ticketTypeId]?.noOfTicketsSold ?? 0) + 1
      acc[cur.ticketTypeId] = {
        eventId: cur.eventId,
        eventStatus: cur.eventStatus,
        eventName: cur.eventName,
        eventDate: cur.eventDate,
        eventSessionId: cur.eventSessionId,
        eventSession: cur.eventSession,
        ticketTypeId: cur.ticketTypeId,
        ticketType: cur.ticketType,
        totalQuota: "",
        noOfTicketsSold: soldAccumulate,
        noOfTicketsAvailable: "",
        pricePerTicket: cur.pricePerTicket,
        totalSalesAmount: soldAccumulate * cur.pricePerTicket,
      }

      if (!eventIdArrs.includes(cur.eventId.toString())) {
        eventIdArrs.push(cur.eventId.toString())
      }
      return acc
    }, {})
    console.log("ticketAmountMap", ticketTypeAmountMap)
    console.log(eventIdArrs)
    const filterArrs = eventIdArrs.map(eventId => {
      return {
        eventId: eventId,
        ...(state.eventStartDate && { startDate: state.eventStartDate }),
        ...(state.eventEndDate && { endDate: state.eventEndDate }),
      }
    })
    console.log("filterArrs", filterArrs)
    const eventQuotasRes = await new Promise((resolve, reject) =>
      props.onGetMultipleTicketingQuotas(filterArrs, resolve, reject)
    )
    console.log("eventQuotasRes", eventQuotasRes)
    const csvRows = Object.keys(ticketTypeAmountMap).map(ticketId => {
      const ticketInfo = ticketTypeAmountMap[ticketId]
      const eventId = ticketInfo.eventId
      const ticketQuota = eventQuotasRes[eventId].find(
        _ticketQuota => _ticketQuota._id === ticketId
      )
      console.log("ticketQuota", ticketQuota)
      ticketInfo.totalQuota = ticketQuota.quota + (ticketQuota.sold ?? 0)
      ticketInfo.noOfTicketsAvailable = ticketQuota.quota
      ticketInfo.defaultQuota = ticketQuota.initialQuota
      ticketInfo.faceValue = ticketQuota.quota * ticketInfo.pricePerTicket
      return [
        ticketInfo.eventId,
        ticketInfo.eventStatus,
        ticketInfo.eventName,
        ticketInfo.eventDate,
        ticketInfo.eventSessionId,
        ticketInfo.eventSession,
        ticketInfo.ticketTypeId,
        ticketInfo.ticketType,
        ticketInfo.pricePerTicket,
        isSalesReport ? ticketInfo.totalQuota : ticketInfo.defaultQuota,
        ticketInfo.noOfTicketsSold,
        ticketInfo.noOfTicketsAvailable,
        isSalesReport ? ticketInfo.totalSalesAmount : ticketInfo.faceValue, // face Value
      ]
    })
    console.log("new ticketAmountMap", ticketTypeAmountMap)
    console.log("csvRows", csvRows)

    const csvReportName = [isSalesReport ? "Sales Reports" : "Inventory Report"]
    const csvCreateTime = [
      `Generated Date/Time: ${moment().format("DD-MMM-YYYY HH:mm:ss")}`,
    ]
    const csvColumns = [
      "Event ID",
      "Event Status",
      "Event Name",
      "Event Date",
      "Event Session ID",
      "Event Session",
      "Ticket Type ID",
      "Ticket Type (Price Class)",
      isSalesReport ? "Ticket Type Amount (HKD)" : "Price",
      isSalesReport ? "Total Quota" : "Default Quota",
      "No. of Tickets Sold",
      isSalesReport
        ? "No. of Tickets Available (Remaining Quota)"
        : "Remaining Quota",
      isSalesReport ? "Total Sales Amount" : "Face Value",
    ]

    const csvOutput = [csvReportName, csvCreateTime, csvColumns, ...csvRows]
    console.log("csvOutput!!!!", csvOutput)
    setCsvReport(csvOutput)
  }
  // contains new inventory report
  const generateInventoryReport = async () => {
    props.onShowLoading()

    const ticketingCountFilters = {
      ...(state.startDate && { endDate_gte: state.eventStartDate }),
      ...(state.endDate && { startDate_lte: state.eventEndDate }),
      ...(state.eventId && { id_in: state.eventId.split(",") }), //[67, 48],
    }
    const ticketingsCountRes = await new Promise((resolve, reject) =>
      props.onGetTicketingsCount(ticketingCountFilters, resolve, reject)
    )
    // console.log("ticketingsCountRes", ticketingsCountRes)
    const ticketingFilters = {
      _limit: ticketingsCountRes,
      _sort: "category_sortIndex:desc,created_at:desc",
      ...ticketingCountFilters,
    }
    const ticketingsRes = await new Promise((resolve, reject) =>
      props.onGetTicketings(ticketingFilters, resolve, reject)
    )
    // console.log("ticketingsRes", ticketingsRes)
    const eventInfoMap = ticketingsRes.reduce((acc, cur) => {
      acc[cur.id] = {
        eventId: cur.id,
        eventStatus: cur.status,
        eventName: cur?.sc?.title,
        eventSessions: cur?.sessions,
      }
      return acc
    }, {})
    // console.log("eventInfoMap", eventInfoMap)
    const eventIdArrs = ticketingsRes.map(event => event.id)
    // console.log("eventInfoMap", eventIdArrs)
    const filterArrs = eventIdArrs.map(eventId => {
      return {
        eventId: eventId,
        ...(state.eventStartDate && { startDate: state.eventStartDate }),
        ...(state.eventEndDate && { endDate: state.eventEndDate }),
      }
    })
    // console.log("filterArrs", filterArrs)
    const eventQuotasRes = await new Promise((resolve, reject) =>
      props.onGetMultipleTicketingQuotas(filterArrs, resolve, reject)
    )
    // console.log("eventQuotasRes", eventQuotasRes)
    const eventQuotasData = Object.keys(eventQuotasRes).map(eventId => {
      const eventdatas = eventQuotasRes[eventId].map(eventQuota => {
        const eventInfo = eventInfoMap[eventQuota.eventId]
        return [
          eventQuota.eventId,
          eventInfo.eventStatus,
          eventInfo.eventName,
          eventQuota.date,
          eventQuota.sessionId,
          eventInfo.eventSessions?.find(
            session => session.id === eventQuota.sessionId
          )?.sc_name,
          eventQuota._id,
          eventQuota.ticketType,
          eventQuota.price,
          eventQuota.initialQuota,
          eventQuota.sold ?? 0,
          eventQuota.quota,
          eventQuota.quota * eventQuota.price,
        ]
      })
      return eventdatas
    })
    // console.log("eventQuotasData", eventQuotasData)
    const csvRows = eventQuotasData.flat(1)
    const csvReportName = ["Inventory Report"]
    const csvCreateTime = [
      `Generated Date/Time: ${moment().format("DD-MMM-YYYY HH:mm:ss")}`,
    ]
    const csvColumns = [
      "Event ID",
      "Event Status",
      "Event Name",
      "Event Date",
      "Event Session ID",
      "Event Session",
      "Ticket Type ID",
      "Ticket Type (Price Class)",
      "Price",
      "Default Quota",
      "No. of Tickets Sold",
      "Remaining Quota",
      "Face Value",
    ]

    const csvOutput = [csvReportName, csvCreateTime, csvColumns, ...csvRows]
    console.log("csvOutput!!!!", csvOutput)
    props.onHideLoading()
    setCsvReport(csvOutput)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>{"Reports"}</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Filters</CardTitle>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">Event ID</Label>
                        <Input
                          name="event_id"
                          type="text"
                          className="form-control"
                          value={state?.eventId}
                          onChange={e =>
                            handleInputChange(e, "OnEventIdChange")
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">
                          Event Start Date
                        </Label>
                        <DatePicker
                          selected={
                            state.eventStartDate
                              ? new Date(
                                  moment(state.eventStartDate)?.valueOf()
                                )
                              : null
                          }
                          onChange={val => {
                            handleDateChange(val, "eventStartDate")
                          }}
                          className="form-control"
                          placeholderText="Event Start date"
                          isClearable
                        />
                        {/* <FormFeedback>
                          {formValue.category_sortIndex.errorMessage}
                        </FormFeedback> */}
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="category_sortIndex">
                          Event End Date
                        </Label>
                        <DatePicker
                          selected={
                            state.eventEndDate
                              ? new Date(moment(state.eventEndDate)?.valueOf())
                              : null
                          }
                          onChange={val => {
                            console.log(`val`, val)
                            handleDateChange(val, "eventEndDate")
                          }}
                          className="form-control"
                          placeholderText="Event End date"
                          isClearable
                        />
                        {/* <FormFeedback>
                          {formValue.category_sortIndex.errorMessage}
                        </FormFeedback> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  {state.reportType === "Patron" && (
                    <Row>
                      <Col sm="6">
                        <FormGroup>
                          <Label className="control-label">
                            Order Start Date
                          </Label>
                          <DatePicker
                            selected={
                              state.orderStartDate
                                ? new Date(
                                    moment(state.orderStartDate)?.valueOf()
                                  )
                                : null
                            }
                            onChange={val => {
                              handleDateChange(val, "orderStartDate")
                            }}
                            className="form-control"
                            placeholderText="Order Start date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6">
                        <FormGroup>
                          <Label className="control-label">
                            Order End Date
                          </Label>
                          <DatePicker
                            selected={
                              state.orderEndDate
                                ? new Date(
                                    moment(state.orderEndDate)?.valueOf()
                                  )
                                : null
                            }
                            onChange={val => {
                              console.log(`val`, val)
                              handleDateChange(val, "orderEndDate")
                            }}
                            className="form-control"
                            placeholderText="Order End date"
                            isClearable
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <CardTitle className="mb-3">ReportType</CardTitle>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        {/* <Label className="control-label">Special Flag</Label> */}
                        <select
                          className="form-control select2"
                          value={state.reportType}
                          onChange={e =>
                            handleSelectChange(e, "OnReportTypeChange")
                          }
                        >
                          <option value={""}>Select a Report</option>
                          <option value="Patron">Patron</option>
                          <option value="Sales">Sales</option>
                          <option value="Inventory">Inventory</option>
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <NotificationContainer />
          <div>
            <Button
              className="mr-3 w-md"
              color="primary"
              onClick={handleSavePress}
              disabled={!state.reportType}
            >
              Download
            </Button>
            {csvReport && (
              <CSVLink
                data={csvReport}
                filename={`${state.reportType}-Report.csv`}
                ref={csvInstance}
              >
                Download me
              </CSVLink>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ transactions }) => ({
  transactions: transactions.transactions,
  transactionsTotalCount: transactions.transactionsTotalCount,
})

const mapDispatchToProps = dispatch => ({
  onGetTicketings: (filters, onSuccess, onError) =>
    dispatch(TicketingsActions.getTicketings({ filters, onSuccess, onError })),
  onGetTicketingsCount: (filters, onSuccess, onError) =>
    dispatch(
      TicketingsActions.getTicketingsCount({ filters, onSuccess, onError })
    ),
  onGetTicketingQuotas: (filters, onSuccess, onError) =>
    dispatch(
      TicketingsActions.getTicketingQuotas({ filters, onSuccess, onError })
    ),
  onGetMultipleTicketingQuotas: (filtersArr, onSuccess, onError) =>
    dispatch(
      TicketingsActions.getMultipleTicketingQuotas({
        filtersArr,
        onSuccess,
        onError,
      })
    ),
  onGetTransactionReport: (filters, onSuccess, onError) =>
    dispatch(
      TransactionsActions.getTransactionsReport({ filters, onSuccess, onError })
    ),
  onShowLoading: () => dispatch(LoginActions.showLoading()),
  onHideLoading: () => dispatch(LoginActions.hideLoading()),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(Reports)
