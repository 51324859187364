import { takeEvery, put, takeLatest } from "redux-saga/effects"
import * as AdminActionTypes from "./actionTypes"
import * as AdminActions from "./actions"
import * as LoginActions from "../login/actions"
import { axiosMp } from "../../utils"
import querystring from "querystring"

function* fetchAdmins({ payload: { filters, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(queries ? `/admins?${queries}` : `/admins`)
    yield put(AdminActions.getAdminsSuccess(res.data.results))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(AdminActions.getAdminsFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* postAdmin({ payload: { admin, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.post(`/admins`, admin)
    yield put(AdminActions.postAdminSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(AdminActions.postAdminFail(error))
    onError && onError(error)
  }
}

function* deleteAdmin({ payload: { adminId, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.delete(`/admins/${adminId}`)
    yield put(AdminActions.deleteAdminSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(AdminActions.deleteAdminFail(error))
    onError && onError()
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* editAdmin({ payload: { adminId, admin, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.put(`/admins/${adminId}`, admin)
    yield put(AdminActions.editAdminSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(AdminActions.editAdminFail(error))
    onError && onError(error)
  }
}

function* getAdminsCount({ payload: { filters, onSuccess, onError } }) {
  try {
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/admins/count?${queries}` : `/admins/count`
    )
    yield put(AdminActions.getAdminsCountSuccess(res.data.count))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(AdminActions.getAdminsCountFail(error))
    onError && onError(error)
  }
}

function* getAdminsMe({ payload: { onSuccess, onError } }) {
  try {
    const res = yield axiosMp.get(`/admins/me`)
    yield put(AdminActions.getAdminsMeSuccess(res.data))
    onSuccess && onSuccess(res.data)
  } catch (error) {
    yield put(AdminActions.getAdminsMeFail(error))
    onError && onError(error)
  }
}

function* adminsSaga() {
  yield takeEvery(AdminActionTypes.GET_ADMINS, fetchAdmins)
  yield takeLatest(AdminActionTypes.POST_ADMIN, postAdmin)
  yield takeEvery(AdminActionTypes.DELETE_ADMIN, deleteAdmin)
  yield takeEvery(AdminActionTypes.EDIT_ADMIN, editAdmin)
  yield takeEvery(AdminActionTypes.GET_ADMINS_COUNT, getAdminsCount)
  yield takeEvery(AdminActionTypes.GET_ADMINS_ME, getAdminsMe)
}

export default adminsSaga
