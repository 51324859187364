import * as TransactionActionTypes from "./actionTypes"

// getTransactions
export const getTransactions = filtersWithCallbacks => ({
  type: TransactionActionTypes.GET_TRANSACTIONS,
  payload: filtersWithCallbacks,
})

export const getTransactionsSuccess = transactions => ({
  type: TransactionActionTypes.GET_TRANSACTIONS_SUCCESS,
  payload: transactions,
})

export const getTransactionsFail = error => ({
  type: TransactionActionTypes.GET_TRANSACTIONS_FAIL,
  payload: error,
})

// getTransactionById
export const getTransactionById = transactionIdWithCallbacks => ({
  type: TransactionActionTypes.GET_TRANSACTION_BY_ID,
  payload: transactionIdWithCallbacks,
})

export const getTransactionByIdSuccess = transaction => ({
  type: TransactionActionTypes.GET_TRANSACTION_BY_ID_SUCCESS,
  payload: transaction,
})

export const getTransactionByIdFail = error => ({
  type: TransactionActionTypes.GET_TRANSACTION_BY_ID_FAIL,
  payload: error,
})

// updateTransactionStatusVoid
export const updateTransactionStatusVoid = transactionIdWithCallbacks => ({
  type: TransactionActionTypes.UPDATE_TRANSACTION_STATUS_VOID,
  payload: transactionIdWithCallbacks,
})

export const updateTransactionStatusVoidSuccess = res => ({
  type: TransactionActionTypes.UPDATE_TRANSACTION_STATUS_VOID_SUCCESS,
  payload: res,
})

export const updateTransactionStatusVoidFail = error => ({
  type: TransactionActionTypes.UPDATE_TRANSACTION_STATUS_VOID_FAIL,
  payload: error,
})

// getTransactions count
export const getTransactionsCount = filtersWithCallbacks => ({
  type: TransactionActionTypes.GET_TRANSACTIONS_COUNT,
  payload: filtersWithCallbacks,
})

export const getTransactionsCountSuccess = articlesCount => ({
  type: TransactionActionTypes.GET_TRANSACTIONS_COUNT_SUCCESS,
  payload: articlesCount,
})

export const getTransactionsCountFail = error => ({
  type: TransactionActionTypes.GET_TRANSACTIONS_COUNT_FAIL,
  payload: error,
})

// getTransactionsReports
export const getTransactionsReport = filtersWithCallbacks => ({
  type: TransactionActionTypes.GET_TRANSACTIONS_REPORT,
  payload: filtersWithCallbacks,
})

export const getTransactionsReportSuccess = transactions => ({
  type: TransactionActionTypes.GET_TRANSACTIONS_REPORT_SUCCESS,
  payload: transactions,
})

export const getTransactionsReportFail = error => ({
  type: TransactionActionTypes.GET_TRANSACTIONS_REPORT_FAIL,
  payload: error,
})
