import * as LoginActionTypes from "./actionTypes"

const INIT_STATE = {
  loginData: {},
  error: {},
  isLoading: false,
}

const mplusLogin = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LoginActionTypes.LOGGING_OUT:
    case LoginActionTypes.POST_LOGIN:
      return {
        ...state,
        error: {},
        loginData: {},
      }
    case LoginActionTypes.POST_LOGIN_SUCCESS:
      return {
        ...state,
        loginData: action.payload,
      }
    case LoginActionTypes.POST_LOGIN_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case LoginActionTypes.SHOW_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case LoginActionTypes.HIDE_LOADING:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default mplusLogin
