export const GET_COUPONS = "GET_COUPONS"
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS"
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL"

export const GET_COUPON_BY_ID = "GET_COUPON_BY_ID"
export const GET_COUPON_BY_ID_SUCCESS = "GET_COUPON_BY_ID_SUCCESS"
export const GET_COUPON_BY_ID_FAIL = "GET_COUPON_BY_ID_FAIL"

export const POST_COUPON = "POST_COUPON"
export const POST_COUPON_SUCCESS = "POST_COUPON_SUCCESS"
export const POST_COUPON_FAIL = "POST_COUPON_FAIL"

export const DELETE_COUPON = "DELETE_COUPON"
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS"
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL"

export const EDIT_COUPON = "EDIT_COUPON"
export const EDIT_COUPON_SUCCESS = "EDIT_COUPON_SUCCESS"
export const EDIT_COUPON_FAIL = "EDIT_COUPON_FAIL"

export const GET_COUPONS_COUNT = "GET_COUPONS_COUNT"
export const GET_COUPONS_COUNT_SUCCESS = "GET_COUPONS_COUNT_SUCCESS"
export const GET_COUPONS_COUNT_FAIL = "GET_COUPONS_COUNT_FAIL"
