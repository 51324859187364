import { takeEvery, put } from "redux-saga/effects"
import * as TransactionActionTypes from "./actionTypes"
import * as TransactionActions from "./actions"
import * as LoginActions from "../login/actions"
import { axiosMp } from "../../utils"
import querystring from "querystring"

//--------------------Article associated--------------------------------
function* fetchTransactions({ payload: { filters, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/transactions?${queries}` : `/transactions`
    )
    yield put(TransactionActions.getTransactionsSuccess(res.data.results))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(TransactionActions.getTransactionsFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* getTransactionById({
  payload: { transactionId, onSuccess, onError },
}) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.get(`/transactions/${transactionId}`)
    yield put(TransactionActions.getTransactionByIdSuccess(res.data))
    onSuccess && onSuccess(res.data)
  } catch (error) {
    yield put(TransactionActions.getTransactionByIdFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* updateTransactionStatusVoid({
  payload: { transactionId, onSuccess, onError },
}) {
  try {
    const res = yield axiosMp.put(`/transactions/${transactionId}/state/void`)
    yield put(TransactionActions.updateTransactionStatusVoidSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(TransactionActions.updateTransactionStatusVoidFail(error))
    onError && onError(error)
  }
}

function* getTransactionsCount({ payload: { filters, onSuccess, onError } }) {
  try {
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/transactions/count?${queries}` : `/transactions/count`
    )
    yield put(TransactionActions.getTransactionsCountSuccess(res.data.count))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(TransactionActions.getTransactionsCountFail(error))
    onError && onError(error)
  }
}

function* getTransactionReport({ payload: { filters, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const countQueries = querystring.stringify(filters)
    const countRes = yield axiosMp.get(
      countQueries
        ? `/transactions/count?${countQueries}`
        : `/transactions/count`
    )
    const queries = querystring.stringify({
      _limit: countRes.data.count,
      ...filters,
    })
    console.log("countRes", countRes, countRes.data.count)
    const res = yield axiosMp.get(
      queries ? `/transactions?${queries}` : `/transactions`
    )
    yield put(TransactionActions.getTransactionsReportSuccess(res.data.results))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(TransactionActions.getTransactionsReportFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* articlesSaga() {
  yield takeEvery(TransactionActionTypes.GET_TRANSACTIONS, fetchTransactions)
  yield takeEvery(
    TransactionActionTypes.GET_TRANSACTION_BY_ID,
    getTransactionById
  )
  yield takeEvery(
    TransactionActionTypes.UPDATE_TRANSACTION_STATUS_VOID,
    updateTransactionStatusVoid
  )
  yield takeEvery(
    TransactionActionTypes.GET_TRANSACTIONS_COUNT,
    getTransactionsCount
  )
  yield takeEvery(
    TransactionActionTypes.GET_TRANSACTIONS_REPORT,
    getTransactionReport
  )
}

export default articlesSaga
