import React from "react"
import { Row } from "reactstrap"

const LabelCheckBox = ({
  id,
  label,
  value,
  onChange,
  checked,
  disabled = false,
}) => {
  return (
    <Row className="align-items-center m-0">
      <h5 className="text-truncate font-size-14 m-0 mr-2">{label}</h5>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          onChange={onChange}
          value={value}
          checked={checked}
          disabled={disabled}
        />
        <label className="custom-control-label" htmlFor={id} />
      </div>
    </Row>
  )
}

export default LabelCheckBox
