import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { compose } from "redux"
import * as LoginActions from "../../store/login/actions"

const Logout = props => {
  useEffect(() => {
    handleLogOut()
  }, [])
  const handleLogOut = async () => {
    await new Promise((resolve, reject) => props.onLogout(resolve, reject))
    props.history.push("/login")
  }

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
}

const mapDispatchToProps = dispatch => ({
  onLogout: (onSuccess, onError) =>
    dispatch(LoginActions.loggingOut({ onSuccess, onError })),
})

export default compose(connect(null, mapDispatchToProps), withRouter)(Logout)
