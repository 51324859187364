/* eslint-disable react/prop-types */
import React, { useReducer, useState } from "react"
import { Row, Col, CardTitle, FormGroup, Label, Button } from "reactstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"
import produce from "immer"
import SweetAlert from "react-bootstrap-sweetalert"

import TicketPriceNQuotosInput from "./TicketPriceNQuotosInput"
import { daysOfWeek } from "utils/ticketingsOptions"
import LabelCheckBox from "../../components/LabelCheckBox"
import { CloseButton } from "components/Buttons"

const ExtendTickets = ({
  initialEndDate,
  initialPricesAndQuotas,
  extendTickets,
  handleExtendTickets,
}) => {
  const extendTicketsWithTicketTypes = produce(
    extendTickets,
    draftExtendTickets => {
      draftExtendTickets.endDate = {
        value: null,
        hasError: false,
        errorMessage: "",
      }
      //sync ticket types
      Object.keys(draftExtendTickets.pricesAndQuotas).forEach(key => {
        if (
          draftExtendTickets.pricesAndQuotas[key] &&
          initialPricesAndQuotas[key]
        ) {
          draftExtendTickets.pricesAndQuotas[key].checked =
            initialPricesAndQuotas[key].checked
        }
      })
    }
  )
  const [state, dispatch] = useReducer(reducer, extendTicketsWithTicketTypes)
  const { endDate, blackoutDaysOfWeek, blackoutDates, pricesAndQuotas } =
    state || {}
  const { adult, child, disables, elderly, normal } = pricesAndQuotas || {}
  const [extendAlertShow, setExtendAlertShow] = useState(false)

  const excludeDates = blackoutDates?.map(date => new Date(date)) || []

  console.log(`[ExtendTickets]state`, state)

  const handlePriceNQuotasChange = (
    ticketType,
    weekdayOrWeekend,
    priceOrQuota,
    value
  ) => {
    dispatch({
      type: "OnPricesAndQuotasChange",
      payload: { ticketType, weekdayOrWeekend, priceOrQuota, value },
    })
  }

  const validatePrice = () => {
    let isAllValidate = true
    const newForm = produce(state, draftState => {
      if (!draftState.endDate.value) {
        isAllValidate = false
        draftState.endDate.hasError = true
        draftState.endDate.errorMessage = "Please input extended end date."
      }
      Object.values(draftState.pricesAndQuotas).forEach(pricesAndQuota => {
        if (pricesAndQuota.checked) {
          if (
            pricesAndQuota.value.weekday.price <= 0 ||
            pricesAndQuota.value.weekend.price <= 0
          ) {
            isAllValidate = false
            pricesAndQuota.hasError = true
            pricesAndQuota.errorMessage = "price must bigger than 0"
          }
        } else {
          pricesAndQuota.hasError = false
          pricesAndQuota.errorMessage = ""
        }
      })
    })
    dispatch({ type: "OnSetAllState", payload: { value: newForm } })
    return isAllValidate
  }

  return (
    <React.Fragment>
      <CardTitle className="mb-2 mt-3 font-size-20">
        Extend Tickets
        <small className="text-muted">
          {" "}
          [Cannot be edited after tickets generation]
        </small>
      </CardTitle>
      <div className="border rounded p-3 mt-2">
        <Row>
          <Col sm="2">
            <FormGroup>
              <Label htmlFor="extendedEndDate">Extended Ticket End Date</Label>
              <DatePicker
                minDate={new Date(moment(initialEndDate).add(1, "d"))}
                selected={
                  endDate?.value
                    ? new Date(moment(endDate?.value)?.valueOf())
                    : null
                }
                onChange={val => {
                  dispatch({
                    type: "OnDateChange",
                    payload: {
                      key: "endDate",
                      value: moment(val?.valueOf()).format(),
                    },
                  })
                }}
                className="form-control"
                placeholderText="Select date"
              />
            </FormGroup>
          </Col>
          {endDate.hasError ? (
            <div className="text-danger d-flex align-items-center pt-1">
              {endDate.errorMessage}
            </div>
          ) : null}
        </Row>

        <Label htmlFor="blackoutDate">Blackout Date (days of week):</Label>
        <Row>
          {daysOfWeek.map((day, index) => (
            <Col sm={{ size: "auto" }} key={index}>
              <LabelCheckBox
                label={day.label}
                id={day.id}
                value={day.id}
                checked={blackoutDaysOfWeek?.includes(day.apiValue)}
                onChange={() =>
                  dispatch({
                    type: "OnCheckboxChange",
                    payload: {
                      key: "blackoutDaysOfWeek",
                      value: day.apiValue,
                    },
                  })
                }
              />
            </Col>
          ))}
        </Row>

        <Label htmlFor="endDate" className="mt-3">
          Specific Blackout Dates:
        </Label>
        <Row>
          {blackoutDates.map((date, index) => {
            const display = moment(date).format("MM/DD/YYYY")
            return (
              <React.Fragment key={index}>
                <CloseButton
                  style={{
                    width: 15,
                    height: 15,
                    fontSize: "22px",
                  }}
                  onClose={() => {
                    dispatch({
                      type: "OnDeleteBlackoutDate",
                      payload: {
                        value: date,
                      },
                    })
                  }}
                />
                <div className="ml-1 mr-2 mb-2">
                  {display}
                  {index + 1 != blackoutDates.length ? ", " : null}
                </div>
              </React.Fragment>
            )
          })}
        </Row>

        <Col xs="3" className="p-0">
          <DatePicker
            customInput={<Button className="mb-3">Add Specific Date</Button>}
            minDate={new Date(moment(initialEndDate).add(1, "d"))}
            maxDate={new Date(endDate.value)}
            onChange={value =>
              dispatch({
                type: "OnDateChange",
                payload: {
                  key: "blackoutDates",
                  value,
                },
              })
            }
            className="form-control"
            excludeDates={excludeDates}
          />
        </Col>

        <Row className="my-2">
          <Col className="font-size-14">Available Ticket Type</Col>
          <Col className="font-size-14">Weekday Price (HKD)</Col>
          <Col className="font-size-14">Weekday Quota</Col>
          <Col className="font-size-14">Weekend Price (HKD)</Col>
          <Col className="font-size-14">Weekend Quota</Col>
        </Row>
        {/* adult */}
        <Row className="mb-2">
          <Col className="d-flex align-items-center">
            <Row className="ml-1">
              <LabelCheckBox
                label=""
                id="adult"
                value="adult"
                checked={adult.checked}
                disabled
                onChange={null}
              />
              <span className="font-size-14">Adult</span>
            </Row>
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="adultWeekdayPrice"
              type="adult"
              state={adult}
              weekdayOrWeekend="weekday"
              priceOrQuota="price"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="adultWeekdayQuota"
              type="adult"
              state={adult}
              weekdayOrWeekend="weekday"
              priceOrQuota="quota"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="adultWeekendPrice"
              type="adult"
              state={adult}
              weekdayOrWeekend="weekend"
              priceOrQuota="price"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="adultWeekendQuota"
              type="adult"
              state={adult}
              weekdayOrWeekend="weekend"
              priceOrQuota="quota"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
        </Row>
        {/* child */}
        <Row className="mb-2">
          <Col className="d-flex align-items-center">
            <Row className="ml-1">
              <LabelCheckBox
                label=""
                id="child"
                value="child"
                checked={child.checked}
                disabled
                onChange={null}
              />
              <span className="font-size-14">Child</span>
            </Row>
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="childWeekdayPrice"
              type="child"
              state={child}
              weekdayOrWeekend="weekday"
              priceOrQuota="price"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="childWeekdayQuota"
              type="child"
              state={child}
              weekdayOrWeekend="weekday"
              priceOrQuota="quota"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="childWeekendPrice"
              type="child"
              state={child}
              weekdayOrWeekend="weekend"
              priceOrQuota="price"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="childWeekendQuota"
              type="child"
              state={child}
              weekdayOrWeekend="weekend"
              priceOrQuota="quota"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
        </Row>
        {/* disables */}
        <Row className="mb-2">
          <Col className="d-flex align-items-center">
            <Row className="ml-1">
              <LabelCheckBox
                label=""
                id="disables"
                value="disables"
                checked={disables.checked}
                disabled
                onChange={null}
              />
              <span className="font-size-14">Disables</span>
            </Row>
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="disablesWeekdayPrice"
              type="disables"
              state={disables}
              weekdayOrWeekend="weekday"
              priceOrQuota="price"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="disablesWeekdayQuota"
              type="disables"
              state={disables}
              weekdayOrWeekend="weekday"
              priceOrQuota="quota"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="disablesWeekendPrice"
              type="disables"
              state={disables}
              weekdayOrWeekend="weekend"
              priceOrQuota="price"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="disablesWeekendQuota"
              type="disables"
              state={disables}
              weekdayOrWeekend="weekend"
              priceOrQuota="quota"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
        </Row>
        {/* elderly */}
        <Row className="mb-2">
          <Col className="d-flex align-items-center">
            <Row className="ml-1">
              <LabelCheckBox
                label=""
                id="elderly"
                value="elderly"
                checked={elderly.checked}
                disabled
                onChange={null}
              />
              <span className="font-size-14">Elderly</span>
            </Row>
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="elderlyWeekdayPrice"
              type="elderly"
              state={elderly}
              weekdayOrWeekend="weekday"
              priceOrQuota="price"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="elderlyWeekdayQuota"
              type="elderly"
              state={elderly}
              weekdayOrWeekend="weekday"
              priceOrQuota="quota"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="elderlyWeekendPrice"
              type="elderly"
              state={elderly}
              weekdayOrWeekend="weekend"
              priceOrQuota="price"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="elderlyWeekendQuota"
              type="elderly"
              state={elderly}
              weekdayOrWeekend="weekend"
              priceOrQuota="quota"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
        </Row>
        {/* normal */}
        <Row className="mb-2">
          <Col className="d-flex align-items-center">
            <Row className="ml-1">
              <LabelCheckBox
                label=""
                id="normal"
                value="normal"
                checked={normal.checked}
                disabled
                onChange={null}
              />
              <span className="font-size-14">Normal</span>
            </Row>
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="normalWeekdayPrice"
              type="normal"
              state={normal}
              weekdayOrWeekend="weekday"
              priceOrQuota="price"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="normalWeekdayQuota"
              type="normal"
              state={normal}
              weekdayOrWeekend="weekday"
              priceOrQuota="quota"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="normalWeekendPrice"
              type="normal"
              state={normal}
              weekdayOrWeekend="weekend"
              priceOrQuota="price"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
          <Col className="d-flex align-items-center">
            <TicketPriceNQuotosInput
              id="normalWeekendQuota"
              type="normal"
              state={normal}
              weekdayOrWeekend="weekend"
              priceOrQuota="quota"
              onChange={handlePriceNQuotasChange}
            />
          </Col>
        </Row>

        <Button
          className="mt-3"
          onClick={() => {
            if (validatePrice()) {
              setExtendAlertShow(true)
            }
          }}
        >
          Extend Tickets (Irreversible action)
        </Button>
      </div>
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={() => {
          handleExtendTickets(state)
          setExtendAlertShow(false)
        }}
        onCancel={() => setExtendAlertShow(false)}
        focusCancelBtn
        show={extendAlertShow}
      >
        Are you sure you want to extend tickets?
      </SweetAlert>
    </React.Fragment>
  )
}

const reducer = produce((draft, action) => {
  const { value, key } = action.payload
  console.log("🚀 ~ file: ExtendTickets.js ~ line 475 ~ reducer ~ value", value)
  console.log("🚀 ~ file: ExtendTickets.js ~ line 475 ~ reducer ~ key", key)
  console.log("####", action.type, "####")
  const isValidkey = draft[key]
  switch (action.type) {
    case "OnInputChange":
    case "OnDateChange":
    case "OnOptionChange":
    case "OnCheckboxChange":
      if (key === "blackoutDaysOfWeek") {
        if (draft.blackoutDaysOfWeek.includes(value)) {
          draft.blackoutDaysOfWeek = draft.blackoutDaysOfWeek.filter(
            item => item != value
          )
        } else {
          draft.blackoutDaysOfWeek.push(value)
        }
      } else if (key === "blackoutDates") {
        const formattedDate = moment(value.valueOf()).format("YYYY-MM-DD")
        draft.blackoutDates.push(formattedDate)
      } else if (isValidkey) {
        draft[key].value = value
        draft[key].hasError = false
      } else {
        console.log(`not valid`, key)
      }
      break
    case "OnDeleteBlackoutDate":
      draft.blackoutDates = draft.blackoutDates.filter(date => date != value)
      break
    case "OnPricesAndQuotasChange": {
      const {
        ticketType,
        weekdayOrWeekend,
        priceOrQuota,
        value: input,
      } = action.payload
      draft.pricesAndQuotas[ticketType].value[weekdayOrWeekend][
        priceOrQuota
      ] = parseInt(input)
      break
    }
    case "OnSetAllState":
      return value
  }
})

export default ExtendTickets
