import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { localStorageHelper } from "../../utils"
import _ from "lodash"
import * as AdminsActions from "../../store/admins/actions"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  accessType,
  ...rest
}) => {
  const adminsMe = useSelector(state => state.admins.adminsMe)
  const dispatch = useDispatch()
  if (_.isEmpty(adminsMe) && localStorageHelper.getUserToken()) {
    dispatch(AdminsActions.getAdminsMe({}))
  }
  const adminType = adminsMe.type

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !localStorageHelper.getUserToken()) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        } else if (isAuthProtected && !accessType?.includes(adminType)) {
          return (
            <Layout>
              <div className="page-content">access denied</div>
            </Layout>
          )
        }
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  accessType: PropTypes.arrayOf(["super", "normal", "ticketing"]),
}

export default Authmiddleware
