import { takeEvery, put } from "redux-saga/effects"
import * as LoginActionTypes from "./actionTypes"
import * as LoginActions from "./actions"
import { axiosMp, localStorageHelper } from "../../utils"

function* loginSubmit({ payload: { loginInfo, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.post(`/admins/login`, loginInfo)
    yield put(LoginActions.postLoginSuccess(res.data))
    onSuccess && onSuccess(res.data)
  } catch (error) {
    yield put(LoginActions.postLoginFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function logout({ payload: { onSuccess, onError } }) {
  try {
    localStorageHelper.clearUserToken()
    onSuccess && onSuccess()
  } catch (error) {
    onError && onError()
  }
}

function* loginSaga() {
  yield takeEvery(LoginActionTypes.POST_LOGIN, loginSubmit)
  yield takeEvery(LoginActionTypes.LOGGING_OUT, logout)
}

export default loginSaga
