import * as FileActionTypes from "./actionTypes"

const INIT_STATE = {
  error: {},
  isUpLoadingImage: false,
  isUpLoadingVideo: false,
}

const file = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FileActionTypes.UPLOAD_IMAGES:
      return {
        ...state,
        error: {},
        isUpLoadingImage: true,
      }
    case FileActionTypes.UPLOAD_IMAGES_SUCCESS:
      return {
        ...state,
        isUpLoadingImage: false,
      }
    case FileActionTypes.UPLOAD_IMAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        isUpLoadingImage: false,
      }
    case FileActionTypes.UPLOAD_VIDEOS:
      return {
        ...state,
        error: {},
        isUpLoadingVideo: true,
      }
    case FileActionTypes.UPLOAD_VIDEOS_SUCCESS:
      return {
        ...state,
        isUpLoadingVideo: false,
      }
    case FileActionTypes.UPLOAD_VIDEOS_FAIL:
      return {
        ...state,
        error: action.payload,
        isUpLoadingVideo: false,
      }
    case FileActionTypes.UPLOAD_IMAGES_AND_VIDEOS:
      return {
        ...state,
        error: {},
        isUpLoadingVideo: true,
        isUpLoadingImage: true,
      }
    case FileActionTypes.UPLOAD_IMAGES_AND_VIDEOS_SUCCESS:
      return {
        ...state,
        isUpLoadingVideo: false,
        isUpLoadingImage: false,
      }
    case FileActionTypes.UPLOAD_IMAGES_AND_VIDEOS_FAIL:
      return {
        ...state,
        error: action.payload,
        isUpLoadingVideo: false,
        isUpLoadingImage: false,
      }
    default:
      return state
  }
}

export default file
