import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"

//E-commerce
import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer"

//chat
import chat from "./chat/reducer"

//crypto
import crypto from "./crypto/reducer"

//invoices
import invoices from "./invoices/reducer"

//projects
import projects from "./projects/reducer"

//tasks
import tasks from "./tasks/reducer"

// articles (is using)
import articles from "./articles/reducer"

// logins (in using)
import mplusLogin from "./login/reducer"

// files util (in using)
import file from "./file/reducer"

// coupons (in using)
import coupons from "./coupons/reducer"

// homepage (in using)
import homepage from "./homepage/reducer"

//ticketings (is using)
import ticketings from "./ticketings/reducer"

//transactions (is using)
import transactions from "./transactions/reducer"

//users (is using)
import users from "./users/reducer"

//users (is using)
import admins from "./admins/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  crypto,
  invoices,
  projects,
  tasks,
  // in use
  articles,
  mplusLogin,
  file,
  homepage,
  coupons,
  ticketings,
  transactions,
  users,
  admins,
})

export default rootReducer
