import { takeEvery, put, takeLatest } from "redux-saga/effects"
import * as CouponActionTypes from "./actionTypes"
import * as CouponActions from "./actions"
import * as LoginActions from "../login/actions"
import { axiosMp } from "../../utils"
import querystring from "querystring"

//--------------------Coupon associated--------------------------------
function* fetchCoupons({ payload: { filters, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(queries ? `/coupons?${queries}` : `/coupons`)
    yield put(CouponActions.getCouponsSuccess(res.data.results))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(CouponActions.getCouponsCountFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* getCouponById({ payload: { couponId, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.get(`/coupons/${couponId}`)
    yield put(CouponActions.getCouponByIdSuccess(res.data))
    onSuccess && onSuccess(res.data)
  } catch (error) {
    yield put(CouponActions.getCouponByIdFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* postCoupon({ payload: { coupon, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.post(`/coupons`, coupon)
    yield put(CouponActions.postCouponSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(CouponActions.postCouponFail(error))
    onError && onError(error)
  }
}

function* deleteCoupon({ payload: { couponId, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.delete(`/coupons/${couponId}`)
    yield put(CouponActions.deleteCouponSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(CouponActions.deleteCouponFail(error))
    onError && onError()
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* editCoupon({ payload: { couponId, coupon, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.put(`/coupons/${couponId}`, coupon)
    yield put(CouponActions.editCouponSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(CouponActions.editCouponFail(error))
    onError && onError(error)
  }
}

function* getCouponsCount({ payload: { filters, onSuccess, onError } }) {
  try {
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/coupons/count?${queries}` : `/coupons/count`
    )
    yield put(CouponActions.getCouponsCountSuccess(res.data.count))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(CouponActions.getCouponsCountFail(error))
    onError && onError(error)
  }
}

function* couponsSaga() {
  yield takeEvery(CouponActionTypes.GET_COUPONS, fetchCoupons)
  yield takeEvery(CouponActionTypes.GET_COUPON_BY_ID, getCouponById)
  yield takeLatest(CouponActionTypes.POST_COUPON, postCoupon)
  yield takeEvery(CouponActionTypes.DELETE_COUPON, deleteCoupon)
  yield takeEvery(CouponActionTypes.EDIT_COUPON, editCoupon)
  yield takeEvery(CouponActionTypes.GET_COUPONS_COUNT, getCouponsCount)
}

export default couponsSaga
