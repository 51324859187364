import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const Tabs = [
  {
    title: "Homepage banners",
    slug: "/homepage-banners",
    iconClass: "bx bx-book-content",
    accessType: ["super", "normal"],
  },
  {
    title: "Article Tabs",
    slug: "/article-tabs",
    iconClass: "bx bx-window-alt",
    accessType: ["super", "normal"],
  },
  {
    title: "Articles",
    slug: "/articles",
    iconClass: "bx bxs-detail",
    accessType: ["super", "normal"],
  },
  {
    title: "Coupons",
    slug: "/coupons",
    iconClass: "bx bx-money",
    accessType: ["super", "ticketing"],
  },
  {
    title: "Ticketings",
    slug: "/ticketings",
    iconClass: "bx bx-film",
    accessType: ["super", "ticketing"],
  },
  {
    title: "Transactions",
    slug: "/transactions",
    iconClass: "bx bx-transfer",
    accessType: ["super", "ticketing"],
  },
  {
    title: "Reports",
    slug: "/reports",
    iconClass: "bx bxs-report",
    accessType: ["super", "ticketing"],
  },
  {
    title: "Users",
    slug: "/users",
    iconClass: "bx bx-user-circle",
    accessType: ["super"],
  },
  {
    title: "Admins",
    slug: "/admins",
    iconClass: "bx bx-arch",
    accessType: ["super"],
  },
]

const SidebarContent = props => {
  const adminType = useSelector(state => state.admins.adminsMe.type)

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement

    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      return false
    }
    return false
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {Tabs.filter(tab => tab.accessType.includes(adminType)).map(
            (tab, index) => {
              return (
                <li key={index}>
                  <Link to={tab.slug} className=" waves-effect">
                    <i className={tab.iconClass}></i>
                    <span>{props.t(tab.title)}</span>
                  </Link>
                </li>
              )
            }
          )}
        </ul>
      </div>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
