import * as CouponActionTypes from "./actionTypes"

const INIT_STATE = {
  coupons: [],
  couponsTotalCount: 0,
  error: {},
}

const coupons = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CouponActionTypes.GET_COUPONS:
      return {
        ...state,
        error: {},
      }
    case CouponActionTypes.GET_COUPONS_SUCCESS:
      return {
        ...state,
        coupons: action.payload,
      }
    case CouponActionTypes.GET_COUPONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case CouponActionTypes.GET_COUPONS_COUNT:
      return {
        ...state,
        error: {},
      }
    case CouponActionTypes.GET_COUPONS_COUNT_SUCCESS:
      return {
        ...state,
        couponsTotalCount: action.payload,
      }
    case CouponActionTypes.GET_COUPONS_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default coupons
