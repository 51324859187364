import * as TransactionActionTypes from "./actionTypes"

const INIT_STATE = {
  transactions: [],
  transactionsTotalCount: 0,
  error: {},
}

const transactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TransactionActionTypes.GET_TRANSACTIONS:
      return {
        ...state,
        error: {},
      }
    case TransactionActionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
      }
    case TransactionActionTypes.GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case TransactionActionTypes.GET_TRANSACTIONS_COUNT:
      return {
        ...state,
        error: {},
      }
    case TransactionActionTypes.GET_TRANSACTIONS_COUNT_SUCCESS:
      return {
        ...state,
        transactionsTotalCount: action.payload,
      }
    case TransactionActionTypes.GET_TRANSACTIONS_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default transactions
