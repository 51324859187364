import React, { useReducer, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
} from "reactstrap"
import produce from "immer"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"
import _ from "lodash"

import { articleTabCategories } from "../../utils"
import * as ArticlesActions from "../../store/articles/actions"
import * as LoginActions from "../../store/login/actions"

const initialState = {
  formValue: {
    en: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    tc: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    sc: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    sortIndex: {
      value: 100,
      hasError: false,
      errorMessage: "",
    },
    category: {
      value: Object.values(articleTabCategories)[0].value,
      hasError: false,
      errorMessage: "",
    },
  },
}

const editModeInitialState = editArticleTab => {
  const defaultFormValue = produce(editArticleTab, draftArticleTab => {
    Object.keys(draftArticleTab).forEach(key => {
      const keysToignore = ["updated_at", "created_at", "id"]
      if (keysToignore.includes(key)) {
        delete draftArticleTab[key]
      } else {
        draftArticleTab[key] = {
          value: draftArticleTab[key],
          hasError: false,
          errorMessage: "",
        }
      }
    })
  })
  return {
    ...initialState,
    formValue: defaultFormValue,
  }
}

const reducer = produce((draft, action) => {
  const { key, value } = action.payload
  switch (action.type) {
    case "OnInputChange":
    case "OnSelectChange":
      draft.formValue[key].value = value
      draft.formValue[key].hasError = false
      break
    case "OnUpdateFormValue":
      draft.formValue = value
      break
    case "OnSetAllState":
      return value
    default:
      break
  }
})

const CreateArticleTab = props => {
  const { match } = props
  const { path, params } = match
  const isEditMode = path === "/article-tab/:id/edit"
  const [state, dispatch] = useReducer(reducer, initialState)
  const { formValue } = state
  useEffect(async () => {
    if (isEditMode && params.id) {
      const articleTabRes = await new Promise((resolve, reject) =>
        props.onGetArticleTabById(params.id, resolve, reject)
      )
      dispatch({
        type: "OnSetAllState",
        payload: { value: editModeInitialState(articleTabRes) },
      })
    }
  }, [])
  const handleInputChange = (e, key) => {
    dispatch({
      type: "OnInputChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }
  const handleSelectChange = (e, key) => {
    dispatch({
      type: "OnSelectChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }
  const handleSavePress = async () => {
    if (!validateFields()) return
    const {
      history,
      onPostArticleTab,
      onEditArticleTab,
      onShowLoading,
      onHideLoading,
    } = props
    try {
      const { formValue } = state
      onShowLoading()

      const submitForm = produce(formValue, draftFormValue => {
        Object.keys(draftFormValue).forEach(key => {
          draftFormValue[key] = draftFormValue[key].value
        })
      })
      if (isEditMode) {
        await new Promise((resolve, reject) =>
          onEditArticleTab(params.id, submitForm, resolve, reject)
        )
      } else {
        await new Promise((resolve, reject) =>
          onPostArticleTab(submitForm, resolve, reject)
        )
      }
      onHideLoading()
      history.push("/article-tabs")
    } catch (err) {
      console.log(err)
      onHideLoading()
    }
  }
  const validateFields = () => {
    const { formValue } = state
    let isAllValidate = true
    const newForm = produce(formValue, draftFormValue => {
      Object.keys(draftFormValue).forEach(key => {
        const keysToSkip = ["en", "tc"]
        if (!keysToSkip.includes(key)) {
          if (
            _.isNil(draftFormValue[key].value) ||
            draftFormValue[key].value === ""
          ) {
            draftFormValue[key].hasError = true
            draftFormValue[key].errorMessage = "Field cant be empty"
            isAllValidate = false
          }
        }
      })
    })
    dispatch({ type: "OnUpdateFormValue", payload: { value: newForm } })
    return isAllValidate
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>{isEditMode ? "Edit Article Tab" : "Create Article Tab"}</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label className="control-label">Category</Label>
                        <select
                          className="form-control select2"
                          value={formValue.category.value}
                          onChange={e => handleSelectChange(e, "category")}
                        >
                          {Object.values(articleTabCategories).map(category => (
                            <option key={category.value} value={category.value}>
                              {category.title}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="title">Sorting Index</Label>
                        <Input
                          name="title"
                          type="text"
                          className="form-control"
                          value={formValue.sortIndex.value}
                          onChange={e => {
                            handleInputChange(e, "sortIndex")
                          }}
                          invalid={formValue.sortIndex.hasError}
                        />
                        <FormFeedback>
                          {formValue.sortIndex.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="shortDescription">
                          Article Tab (SC)
                        </Label>
                        <Input
                          name="shortDescription"
                          type="text"
                          className="form-control"
                          value={formValue.sc.value}
                          onChange={e => {
                            handleInputChange(e, "sc")
                          }}
                          invalid={formValue.sc.hasError}
                        />
                        <FormFeedback>{formValue.sc.errorMessage}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="shortDescription">
                          Article Tab (TC)
                        </Label>
                        <Input
                          name="shortDescription"
                          type="text"
                          className="form-control"
                          value={formValue.tc.value}
                          onChange={e => {
                            handleInputChange(e, "tc")
                          }}
                          invalid={formValue.tc.hasError}
                        />
                        <FormFeedback>{formValue.tc.errorMessage}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="shortDescription">
                          Article Tab (EN)
                        </Label>
                        <Input
                          name="shortDescription"
                          type="text"
                          className="form-control"
                          value={formValue.en.value}
                          onChange={e => {
                            handleInputChange(e, "en")
                          }}
                          invalid={formValue.en.hasError}
                        />
                        <FormFeedback>{formValue.en.errorMessage}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>
            <Button
              className="mr-3 w-md"
              color="primary"
              onClick={handleSavePress}
            >
              Save
            </Button>
            <Link to="/article-tabs" className="btn btn-secondary mr-2 w-md">
              Cancel
            </Link>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ articles }) => ({
  articleTabs: articles.articleTabs,
})

const mapDispatchToProps = dispatch => ({
  onGetArticleTabById: (articleTabId, onSuccess, onError) =>
    dispatch(
      ArticlesActions.getArticleTabById({ articleTabId, onSuccess, onError })
    ),
  onPostArticleTab: (articleTab, onSuccess, onError) =>
    dispatch(
      ArticlesActions.postArticleTab({ articleTab, onSuccess, onError })
    ),
  onEditArticleTab: (articleTabId, articleTab, onSuccess, onError) =>
    dispatch(
      ArticlesActions.editArticleTab({
        articleTabId,
        articleTab,
        onSuccess,
        onError,
      })
    ),
  onShowLoading: () => dispatch(LoginActions.showLoading()),
  onHideLoading: () => dispatch(LoginActions.hideLoading()),
})
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CreateArticleTab)
