export const ticketingsCategories = {
  general: {
    title: "General Admission",
    value: "general",
  },
  workshop: {
    title: "Workshop",
    value: "workshop",
  },
  exhibition: {
    title: "Exhibition",
    value: "exhibition",
  },
}

export const ticketingsSpecialFlag = {
  default: {
    title: "None",
    value: "",
  },
  extraFeeRequired: {
    title: "Requires Extra Fee",
    value: "extraFeeRequired",
  },
  free: {
    title: "Free Ticketing",
    value: "free",
  },
  includedInGeneralAdmissionTicket: {
    title: "Included in GA Ticket",
    value: "includedInGeneralAdmissionTicket",
  },
}

export const ticketingsStatus = {
  draft: {
    title: "Draft",
    value: "draft",
  },
  published: {
    title: "Published",
    value: "published",
  },
  archived: {
    title: "Archived",
    value: "archived",
  },
}

export const daysOfWeek = [
  {
    id: "blackout_mon",
    label: "Mon",
    apiValue: "1",
  },
  {
    id: "blackout_tue",
    label: "Tue",
    apiValue: "2",
  },
  {
    id: "blackout_wed",
    label: "Wed",
    apiValue: "3",
  },
  {
    id: "blackout_thu",
    label: "Thu",
    apiValue: "4",
  },
  {
    id: "blackout_fri",
    label: "Fri",
    apiValue: "5",
  },
  {
    id: "blackout_sat",
    label: "Sat",
    apiValue: "6",
  },
  {
    id: "blackout_sun",
    label: "Sun",
    apiValue: "7",
  },
]

export const ticketTypes = ["Adult", "Child", "Disables", "Elderly", "Normal"]

//Category: General Admission, Workshop, Exhibition
//Special Flag: Requires Extra Fee, Included in GA Ticket, Free Ticketing
//Status: Draft Published Archived
