import { takeEvery, put, takeLatest } from "redux-saga/effects"
import * as ArticleActionTypes from "./actionTypes"
import * as ArticleActions from "./actions"
import * as LoginActions from "../login/actions"
import { axiosMp } from "../../utils"
import querystring from "querystring"

//--------------------Article associated--------------------------------
function* fetchArticles({ payload: { filters, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/articles?${queries}` : `/articles`
    )
    yield put(ArticleActions.getArticlesSuccess(res.data.results))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(ArticleActions.getArticlesFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* getArticleById({ payload: { articleId, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.get(`/articles/${articleId}`)
    yield put(ArticleActions.getArticleByIdSuccess(res.data))
    onSuccess && onSuccess(res.data)
  } catch (error) {
    yield put(ArticleActions.getArticleByIdFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* postArticle({ payload: { article, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.post(`/articles`, article)
    yield put(ArticleActions.postArticleSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(ArticleActions.postArticleFail(error))
    onError && onError(error)
  }
}

function* deleteArticle({ payload: { articleId, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.delete(`/articles/${articleId}`)
    yield put(ArticleActions.deleteArticleSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(ArticleActions.deleteArticleFail(error))
    onError && onError()
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* editArticle({ payload: { articleId, article, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.put(`/articles/${articleId}`, article)
    yield put(ArticleActions.editArticleSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(ArticleActions.editArticleFail(error))
    onError && onError(error)
  }
}

function* getArticlesCount({ payload: { filters, onSuccess, onError } }) {
  try {
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/articles/count?${queries}` : `/articles/count`
    )
    yield put(ArticleActions.getArticlesCountSuccess(res.data.count))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(ArticleActions.getArticlesCountFail(error))
    onError && onError(error)
  }
}

//--------------------ArticleTab associated--------------------------------
function* fetchArticleTabs({
  payload: { filters, onSuccess, onError, noLoadingEffect },
}) {
  try {
    if (!noLoadingEffect) {
      yield put(LoginActions.showLoading())
    }
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/article-tabs?${queries}` : `/article-tabs`
    )
    yield put(ArticleActions.getArticleTabSuccess(res.data.results))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(ArticleActions.getArticleTabFail(error))
    onError && onError(error)
  } finally {
    if (!noLoadingEffect) {
      yield put(LoginActions.hideLoading())
    }
  }
}

function* getArticleTabById({ payload: { articleTabId, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.get(`/article-tabs/${articleTabId}`)
    yield put(ArticleActions.getArticleTabByIdSuccess(res.data))
    onSuccess && onSuccess(res.data)
  } catch (error) {
    yield put(ArticleActions.getArticleTabByIdFail(error))
    onError && onError(error)
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* postArticleTab({ payload: { articleTab, onSuccess, onError } }) {
  try {
    const res = yield axiosMp.post(`/article-tabs`, articleTab)
    yield put(ArticleActions.postArticleTabSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(ArticleActions.postArticleTabFail(error))
    onError && onError(error)
  }
}

function* deleteArticleTab({ payload: { articleTabId, onSuccess, onError } }) {
  try {
    yield put(LoginActions.showLoading())
    const res = yield axiosMp.delete(`/article-tabs/${articleTabId}`)
    yield put(ArticleActions.deleteArticleTabSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(ArticleActions.deleteArticleTabFail(error))
    onError && onError()
  } finally {
    yield put(LoginActions.hideLoading())
  }
}

function* editArticleTab({
  payload: { articleTabId, articleTab, onSuccess, onError },
}) {
  try {
    const res = yield axiosMp.put(`/article-tabs/${articleTabId}`, articleTab)
    yield put(ArticleActions.editArticleTabSuccess(res.data))
    onSuccess && onSuccess()
  } catch (error) {
    yield put(ArticleActions.editArticleTabFail(error))
    onError && onError(error)
  }
}

function* getArticleTabsCount({ payload: { filters, onSuccess, onError } }) {
  try {
    const queries = querystring.stringify(filters)
    const res = yield axiosMp.get(
      queries ? `/article-tabs/count?${queries}` : `/article-tabs/count`
    )
    yield put(ArticleActions.getArticleTabsCountSuccess(res.data.count))
    onSuccess && onSuccess(res.data.results)
  } catch (error) {
    yield put(ArticleActions.getArticleTabsCountFail(error))
    onError && onError(error)
  }
}

function* articlesSaga() {
  yield takeEvery(ArticleActionTypes.GET_ARTICLES, fetchArticles)
  yield takeEvery(ArticleActionTypes.GET_ARTICLE_BY_ID, getArticleById)
  yield takeLatest(ArticleActionTypes.POST_ARTICLE, postArticle)
  yield takeEvery(ArticleActionTypes.DELETE_ARTICLE, deleteArticle)
  yield takeEvery(ArticleActionTypes.EDIT_ARTICLE, editArticle)
  yield takeEvery(ArticleActionTypes.GET_ARTICLES_COUNT, getArticlesCount)

  yield takeEvery(ArticleActionTypes.GET_ARTICLE_TAB, fetchArticleTabs)
  yield takeEvery(ArticleActionTypes.GET_ARTICLE_TAB_BY_ID, getArticleTabById)
  yield takeLatest(ArticleActionTypes.POST_ARTICLE_TAB, postArticleTab)
  yield takeEvery(ArticleActionTypes.DELETE_ARTICLE_TAB, deleteArticleTab)
  yield takeEvery(ArticleActionTypes.EDIT_ARTICLE_TAB, editArticleTab)
  yield takeEvery(ArticleActionTypes.GET_ARTICLE_TAB_COUNT, getArticleTabsCount)
}

export default articlesSaga
