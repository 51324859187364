const articleStatus = {
  draft: {
    value: "draft",
    title: "Draft",
  },
  published: {
    value: "published",
    title: "Published",
  },
  archived: {
    value: "archived",
    title: "Archived",
  },
}

export default articleStatus
