import * as AdminActionTypes from "./actionTypes"

// getAdmins
export const getAdmins = filtersWithCallbacks => ({
  type: AdminActionTypes.GET_ADMINS,
  payload: filtersWithCallbacks,
})

export const getAdminsSuccess = admins => ({
  type: AdminActionTypes.GET_ADMINS_SUCCESS,
  payload: admins,
})

export const getAdminsFail = error => ({
  type: AdminActionTypes.GET_ADMINS_FAIL,
  payload: error,
})

// postAdmin
export const postAdmin = adminWithCallbacks => ({
  type: AdminActionTypes.POST_ADMIN,
  payload: adminWithCallbacks,
})

export const postAdminSuccess = res => ({
  type: AdminActionTypes.POST_ADMIN_SUCCESS,
  payload: res,
})

export const postAdminFail = error => ({
  type: AdminActionTypes.POST_ADMIN_FAIL,
  payload: error,
})

// deleteAdmin
export const deleteAdmin = adminIdWithCallbacks => ({
  type: AdminActionTypes.DELETE_ADMIN,
  payload: adminIdWithCallbacks,
})

export const deleteAdminSuccess = res => ({
  type: AdminActionTypes.DELETE_ADMIN_SUCCESS,
  payload: res,
})

export const deleteAdminFail = error => ({
  type: AdminActionTypes.DELETE_ADMIN_FAIL,
  payload: error,
})

// editAdmin
export const editAdmin = adminWithCallbacks => ({
  type: AdminActionTypes.EDIT_ADMIN,
  payload: adminWithCallbacks,
})

export const editAdminSuccess = res => ({
  type: AdminActionTypes.EDIT_ADMIN_SUCCESS,
  payload: res,
})

export const editAdminFail = error => ({
  type: AdminActionTypes.EDIT_ADMIN_FAIL,
  payload: error,
})

// getAdmin count
export const getAdminsCount = filtersWithCallbacks => ({
  type: AdminActionTypes.GET_ADMINS_COUNT,
  payload: filtersWithCallbacks,
})

export const getAdminsCountSuccess = adminsCount => ({
  type: AdminActionTypes.GET_ADMINS_COUNT_SUCCESS,
  payload: adminsCount,
})

export const getAdminsCountFail = error => ({
  type: AdminActionTypes.GET_ADMINS_COUNT_FAIL,
  payload: error,
})

// getAdminsMe
export const getAdminsMe = callbacks => ({
  type: AdminActionTypes.GET_ADMINS_ME,
  payload: callbacks,
})

export const getAdminsMeSuccess = adminsMe => ({
  type: AdminActionTypes.GET_ADMINS_ME_SUCCESS,
  payload: adminsMe,
})

export const getAdminsMeFail = error => ({
  type: AdminActionTypes.GET_ADMINS_ME_FAIL,
  payload: error,
})
