import React, { useEffect, useReducer } from "react"
import { Container, Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { compose } from "redux"
import { connect } from "react-redux"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import ArticleTabsOrderColumns from "./ArticleTabsOrderColumns"
import BootstrapTable from "react-bootstrap-table-next"
import produce from "immer"

import * as ArticlesActions from "../../store/articles/actions"
import { articleTabCategories } from "../../utils"

const initialState = {
  curretPage: 1,
  sizePerPage: 10,
  category: "",
}

const reducer = produce((draft, action) => {
  const { page, value } = action.payload
  switch (action.type) {
    case "onPrevPage":
      draft.curretPage = draft.curretPage - 1 || 1
      break
    case "OnSpecificPage":
      draft.curretPage = page
      break
    case "OnCategoryChange":
      draft.category = value
      break
    default:
      break
  }
})

const ArticleTabs = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { articleTabs, history, articleTabsTotalCount } = props
  console.log("articleTabs", articleTabs)
  const pageOptions = {
    page: state.curretPage,
    sizePerPage: state.sizePerPage,
    totalSize: articleTabsTotalCount,
    custom: true,
    onPageChange: page =>
      dispatch({ type: "OnSpecificPage", payload: { page } }),
  }
  useEffect(() => {
    getArticleTabsAndArticleTabsCount()
  }, [state.curretPage])

  const handleDeleteArticleTab = async articleTabId => {
    await new Promise((resolve, reject) =>
      props.onDeleteArticleTab(articleTabId, resolve, reject)
    )
    getArticleTabsAndArticleTabsCount()
  }
  const getArticleTabsAndArticleTabsCount = resetToPage1 => {
    if (resetToPage1 && state.curretPage !== 1) {
      dispatch({ type: "OnSpecificPage", payload: { page: 1 } })
      return
    }
    const articleTabCountFilters = {
      ...(state.category && { category: state.category }),
    }
    const articleTabFilters = {
      _limit: state.sizePerPage,
      _start: (state.curretPage - 1) * state.sizePerPage,
      _sort: "sortIndex:desc,created_at:desc",
      ...articleTabCountFilters,
    }
    props.onGetArticleTabs(articleTabFilters)
    props.onGetArticleTabsCount(articleTabCountFilters)
  }
  const handleEditArticleTab = articleTab => {
    history.push({
      pathname: `/article-tab/${articleTab.id}/edit`,
    })
  }
  const handleSelectChange = (e, key) => {
    dispatch({
      type: key,
      payload: {
        value: e.target.value,
      },
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Article Tabs</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md="1">
                      <Label className="mb-3">Filter :</Label>
                    </Col>
                    <Col md="3">
                      <select
                        className="form-control select2 mb-3"
                        value={state.category}
                        onChange={e =>
                          handleSelectChange(e, "OnCategoryChange")
                        }
                      >
                        <option value="">Category</option>
                        {Object.values(articleTabCategories).map(category => (
                          <option key={category.value} value={category.value}>
                            {category.title}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col md="2">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md mb-3"
                        onClick={() => getArticleTabsAndArticleTabsCount(true)}
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <Link
                        to="/create-article-tab"
                        className="btn btn-primary mr-2 w-md mb-3 mt-4"
                      >
                        Create
                      </Link>
                    </Col>
                  </Row>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={articleTabs}
                        columns={ArticleTabsOrderColumns({
                          handleDeleteArticleTab,
                          handleEditArticleTab,
                        })}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={() =>
                                      console.log("onTable CHange????")
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ articles }) => ({
  articleTabs: articles.articleTabs,
  articleTabsTotalCount: articles.articleTabsTotalCount,
})

const mapDispatchToProps = dispatch => ({
  onGetArticleTabs: (filters, onSuccess, onError) =>
    dispatch(ArticlesActions.getArticleTab({ filters, onSuccess, onError })),
  onDeleteArticleTab: (articleTabId, onSuccess, onError) =>
    dispatch(
      ArticlesActions.deleteArticleTab({ articleTabId, onSuccess, onError })
    ),
  onGetArticleTabsCount: (filters, onSuccess, onError) =>
    dispatch(
      ArticlesActions.getArticleTabsCount({ filters, onSuccess, onError })
    ),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(ArticleTabs)
