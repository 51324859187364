import React, { useEffect, useReducer } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  FormFeedback,
} from "reactstrap"
import { connect } from "react-redux"
import produce from "immer"
import { v4 as uuidV4 } from "uuid"
import _ from "lodash"

import { ImageModules } from "../../components/DnDModules"
import * as HomepageActions from "../../store/homepage/actions"
import * as LoginActions from "../../store/login/actions"
import * as FileActions from "../../store/file/actions"

const Tabs = {
  TC: "tc",
  SC: "sc",
  EN: "en",
}

const startsWithLang = key =>
  key.startsWith(Tabs.EN) || key.startsWith(Tabs.SC) || key.startsWith(Tabs.TC)

const initialState = {
  formValue: {
    aboutMplusID: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    aboutMembershipID: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    directionsID: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    ticketingID: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    mPlusInfoID: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    en: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bannerImages: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      headImages: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      bottomImages: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      specialNotice_title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      specialNotice_body: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      specialNotice_show: {
        value: true,
        hasError: false,
        errorMessage: "",
      },
    },
    tc: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bannerImages: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      headImages: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      bottomImages: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      specialNotice_title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      specialNotice_body: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      specialNotice_show: {
        value: true,
        hasError: false,
        errorMessage: "",
      },
    },
    sc: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      bannerImages: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      headImages: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      bottomImages: {
        value: [],
        hasError: false,
        errorMessage: "",
      },
      specialNotice_title: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      specialNotice_body: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      specialNotice_show: {
        value: true,
        hasError: false,
        errorMessage: "",
      },
    },
  },
  activeTab: Tabs.SC,
  validateID: uuidV4(),
  files: {},
}

const reducer = produce((draft, action) => {
  const { value, key, assignType, destination } = action.payload
  switch (action.type) {
    case "OnTabChange":
      draft.activeTab = value
      break
    case "OnUpdateFormValue":
      draft.formValue = value
      draft.validateID = uuidV4()
      break
    case "OnGetDefaultFormValue":
      draft.formValue = Object.assign({}, draft.formValue, value)
      draft.files = {}
      draft.validateID = uuidV4()
      break
    case "OnUploadImage":
      draft.files[key] = {
        files: value,
        key,
        assignType,
        type: "images",
        destination: destination,
      }
      _.set(draft.formValue, `${key}.hasError`, false)
      break
    case "OnInputChange":
    case "OnCheckboxChange":
    case "OnBannerValueChange":
      if (draft.formValue[key]) {
        draft.formValue[key].value = value
        draft.formValue[key].hasError = false
      } else {
        draft.formValue[draft.activeTab][key].value = value
        draft.formValue[draft.activeTab][key].hasError = false
      }
      break
    case "OnCopyPress":
      Object.values(Tabs)
        .filter(tab => tab !== draft.activeTab) // tabs that are not currenet tab
        .forEach(tab => {
          // copy formValue to other tabs
          draft.formValue[tab] = draft.formValue[draft.activeTab]
          // copying files to other tabs
          Object.keys(draft.files)
            .filter(_key => _key.startsWith(draft.activeTab))
            .forEach(_key => {
              draft.files[_key.replace(draft.activeTab, tab)] = {
                ...draft.files[_key],
                key: _key.replace(draft.activeTab, tab),
              }
            })
        })
      break
    default:
      break
  }
})

const HomepageBanners = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { activeTab, formValue, validateID } = state
  useEffect(async () => {
    getDefaultHomepage()
  }, [])
  const getDefaultHomepage = async () => {
    const res = await new Promise((resolve, reject) =>
      props.onGetHomepage(resolve, reject)
    )
    const defaultFormValue = produce(res, draftFormValue => {
      const keysToignore = [
        "updated_at",
        "created_at",
        "id",
        "aboutMplugID",
        "mPlugInfoID",
      ]
      Object.keys(draftFormValue).forEach(key => {
        if (keysToignore.includes(key)) {
          delete draftFormValue[key]
        }
        // mormal keys
        else if (!startsWithLang(key)) {
          draftFormValue[key] = {
            value: draftFormValue[key],
            hasError: false,
            errorMessage: "",
          }
        }
        // keys that is lang
        else if (startsWithLang(key)) {
          Object.keys(draftFormValue[key]).forEach(_key => {
            if (_key === "id") {
              delete draftFormValue[key][_key]
            } else {
              draftFormValue[key][_key] = {
                value: draftFormValue[key][_key],
                hasError: false,
                errorMessage: "",
              }
            }
          })
        }
      })
    })
    dispatch({
      type: "OnGetDefaultFormValue",
      payload: {
        value: defaultFormValue,
      },
    })
  }
  const hadleTabChange = tabLang => {
    dispatch({
      type: "OnTabChange",
      payload: {
        value: tabLang,
      },
    })
  }
  const handleCopyPress = () => {
    dispatch({ type: "OnCopyPress", payload: {} })
  }
  const handleCheckBoxChange = (e, key) => {
    dispatch({
      type: "OnCheckboxChange",
      payload: {
        value: e.target.checked,
        key: key,
      },
    })
  }
  const handleInputChange = (e, key) => {
    dispatch({
      type: "OnInputChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }
  const handleBannerImageModulesFilesChange = (
    filesWithType,
    key,
    assignType,
    formValueKey
  ) => {
    console.log("filesWithType!!!!", filesWithType)
    dispatch({
      type: "OnUploadImage",
      payload: {
        value: filesWithType
          .map(_files =>
            _files.files.length
              ? _files.files
              : {
                  imageUrl: _files.imageUrl,
                  type: _files.type,
                }
          )
          .flat(),
        key,
        assignType: assignType,
        destination: filesWithType[0]?.destination,
      },
    })
    dispatch({
      type: "OnBannerValueChange",
      payload: {
        value: filesWithType.map(_files => ({
          id: _files.id,
          type: _files.type,
          destination: _files.destination,
          articleId: _files.articleId,
          category: _files.category,
          imageUrl: _files.imageUrl,
        })),
        key: formValueKey,
      },
    })
  }
  const getDefaultBannerImageModules = key => {
    return formValue[activeTab][key].value.map((_banner, _index) => {
      return {
        ..._banner,
        files:
          state.files[`${activeTab}.${key}`]?.files[_index] instanceof File
            ? [state.files[`${activeTab}.${key}`].files[_index]]
            : [],
      }
    })
  }
  const handleSavePress = async () => {
    const { formValue } = state
    const { onShowLoading, onHideLoading, onEditHomepage } = props
    if (!validateFields()) return
    onShowLoading()
    const imageUploadRes = await UploadFilesToCloud()
    console.log("imageUploadRes", imageUploadRes)
    const submitForm = produce(formValue, draftFormValue => {
      // 1.putting image url to form value
      imageUploadRes.forEach(_res => {
        // if (_res.assignType === "onlyValue") {
        //   _.set(draftFormValue, `${_res.key}.value`, _res.value[0].url)
        // } else {
        const theValue = _.get(draftFormValue, `${_res.key}.value`).map(
          (_value, _index) => ({
            ..._value,
            imageUrl: _res.value[_index].url || _res.value[_index].imageUrl,
          })
        )
        _.set(draftFormValue, `${_res.key}.value`, theValue)
        // }
      })

      // 2.flatten the form values
      Object.keys(draftFormValue).forEach(key => {
        // normal keys
        if (!startsWithLang(key)) {
          draftFormValue[key] = draftFormValue[key].value
        }
        // keys that is lang
        if (startsWithLang(key)) {
          Object.keys(draftFormValue[key]).forEach(_key => {
            draftFormValue[key][_key] = draftFormValue[key][_key].value
            if (_key === "id") delete draftFormValue[key][_key]
            if (
              _key === "bannerImages" ||
              _key === "headImages" ||
              _key === "bottomImages"
            ) {
              draftFormValue[key][_key].forEach(_banner => {
                delete _banner.id
                delete _banner.type
              })
            }
          })
        }
      })
    })
    try {
      await new Promise((resolve, reject) =>
        onEditHomepage(submitForm, resolve, reject)
      )
      onHideLoading()
      getDefaultHomepage()
    } catch (err) {
      console.log(err)
      onHideLoading()
    }
  }
  const handleCancelPress = () => {
    getDefaultHomepage()
  }
  const UploadFilesToCloud = async () => {
    // uploading files to cloud
    const filesArr = Object.values(state.files)
    const resArr = []
    for (let file of filesArr) {
      const res = await new Promise((resolve, reject) =>
        props.onUploadImagesAndVideos(file.files, resolve, reject)
      )
      resArr.push({
        value: res,
        key: file.key,
        assignType: file.assignType,
      })
    }
    return resArr
  }
  const validateFields = () => {
    const { formValue, files } = state
    let isAllValidate = true
    const newForm = produce(formValue, draftFormValue => {
      Object.keys(draftFormValue).forEach(key => {
        // normal keys
        if (!startsWithLang(key)) {
          if (
            _.isNil(draftFormValue[key].value) ||
            draftFormValue[key].value === ""
          ) {
            draftFormValue[key].hasError = true
            draftFormValue[key].errorMessage = "Field cant be empty"
            isAllValidate = false
          }
        }
        // keys that is lang,
        if (startsWithLang(key)) {
          Object.keys(draftFormValue[key]).forEach(_key => {
            if (
              _key === "bannerImages" ||
              _key === "bottomImages" ||
              _key === "headImages"
            ) {
              const allBannerHasUrlOrFile = draftFormValue[key][
                _key
              ].value.reduce((acc, cur, _index) => {
                const isUrlEmpty = cur.imageUrl === "" || _.isNil(cur.imageUrl)
                const isFileEmpty = !(
                  files[`${key}.${_key}`]?.files[_index] instanceof File
                )
                return acc && !(isUrlEmpty && isFileEmpty)
              }, true)
              if (!allBannerHasUrlOrFile) {
                draftFormValue[key][_key].hasError = true
                draftFormValue[key][_key].errorMessage = "Field cant be empty"
                isAllValidate = false
              }
            } else if (
              (_key === "specialNotice_body" &&
                !draftFormValue[key].specialNotice_show.value) ||
              (_key === "specialNotice_title" &&
                !draftFormValue[key].specialNotice_show.value)
            ) {
              draftFormValue[key][_key].hasError = false
            } else if (_key === "id") {
              // do nothing
            } else {
              if (
                draftFormValue[key][_key].value === "" ||
                _.isNil(draftFormValue[key][_key].value)
              ) {
                draftFormValue[key][_key].hasError = true
                draftFormValue[key][_key].errorMessage = "Field cant be empty"
                isAllValidate = false
              }
            }
          })
        }
      })
    })
    dispatch({ type: "OnUpdateFormValue", payload: { value: newForm } })
    console.log("isAllValidate", isAllValidate)
    return isAllValidate
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Homepage Banners</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Default Settings</CardTitle>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="mPlusInfoID">
                          {'Article ID for "M+ Intro"'}
                        </Label>
                        <Input
                          name="mPlusInfoID"
                          type="text"
                          className="form-control"
                          value={formValue.mPlusInfoID.value}
                          onChange={e => handleInputChange(e, "mPlusInfoID")}
                          invalid={formValue.mPlusInfoID.hasError}
                        />
                        <FormFeedback>
                          {formValue.mPlusInfoID.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="aboutMplusID">
                          {'Article ID for "About M+"'}
                        </Label>
                        <Input
                          name="aboutMplusID"
                          type="text"
                          className="form-control"
                          value={formValue.aboutMplusID.value}
                          onChange={e => handleInputChange(e, "aboutMplusID")}
                          invalid={formValue.aboutMplusID.hasError}
                        />
                        <FormFeedback>
                          {formValue.aboutMplusID.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="aboutMembershipID">
                          {'Article ID for "About Membership"'}
                        </Label>
                        <Input
                          name="aboutMembershipID"
                          type="text"
                          className="form-control"
                          value={formValue.aboutMembershipID.value}
                          onChange={e =>
                            handleInputChange(e, "aboutMembershipID")
                          }
                          invalid={formValue.aboutMembershipID.hasError}
                        />
                        <FormFeedback>
                          {formValue.aboutMembershipID.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="directionsID">
                          {'Article ID for "Directions"'}
                        </Label>
                        <Input
                          name="directionsID"
                          type="text"
                          className="form-control"
                          value={formValue.directionsID.value}
                          onChange={e => handleInputChange(e, "directionsID")}
                          invalid={formValue.directionsID.hasError}
                        />
                        <FormFeedback>
                          {formValue.directionsID.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="ticketingID">
                          {'Article ID for "Ticketing"'}
                        </Label>
                        <Input
                          name="ticketingID"
                          type="text"
                          className="form-control"
                          value={formValue.ticketingID.value}
                          onChange={e => handleInputChange(e, "ticketingID")}
                          invalid={formValue.ticketingID.hasError}
                        />
                        <FormFeedback>
                          {formValue.ticketingID.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Nav tabs>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.SC}
                    onClick={() => hadleTabChange(Tabs.SC)}
                  >
                    <i className="bx bx-chat font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">簡體中文</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.TC}
                    onClick={() => hadleTabChange(Tabs.TC)}
                  >
                    <i className="bx bx-group font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">繁體中文</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.EN}
                    onClick={() => hadleTabChange(Tabs.EN)}
                  >
                    <i className="bx bx-book-content font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">English</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <Card>
                <CardBody>
                  <Row className="justify-content-between m-0 mb-4">
                    <CardTitle className="mb-3">Special notice</CardTitle>
                    <Button onClick={handleCopyPress}>
                      Copy to other lang
                    </Button>
                  </Row>

                  <Row className="m-0 mb-3">
                    <LabelCheckBox
                      label="Show notice"
                      id="specialNotice_show"
                      checked={formValue[activeTab].specialNotice_show.value}
                      onChange={e =>
                        handleCheckBoxChange(e, "specialNotice_show")
                      }
                    />
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="specialNotice_title">
                          Notice Title
                        </Label>
                        <Input
                          name="specialNotice_title"
                          type="text"
                          className="form-control"
                          value={formValue[activeTab].specialNotice_title.value}
                          onChange={e => {
                            handleInputChange(e, "specialNotice_title")
                          }}
                          invalid={
                            formValue[activeTab].specialNotice_title.hasError
                          }
                        />
                        <FormFeedback>
                          {
                            formValue[activeTab].specialNotice_title
                              .errorMessage
                          }
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="specialNotice_body">Notice Body</Label>
                        <Input
                          name="specialNotice_body"
                          type="text"
                          className="form-control"
                          value={formValue[activeTab].specialNotice_body.value}
                          onChange={e => {
                            handleInputChange(e, "specialNotice_body")
                          }}
                          invalid={
                            formValue[activeTab].specialNotice_body.hasError
                          }
                        />
                        <FormFeedback>
                          {formValue[activeTab].specialNotice_body.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12" className="mb-3">
                      <ImageModules
                        key={`${activeTab}_${validateID}`}
                        label="Banner Images"
                        onChange={filesWithType =>
                          handleBannerImageModulesFilesChange(
                            filesWithType,
                            `${activeTab}.bannerImages`,
                            "arrayWithType",
                            "bannerImages"
                          )
                        }
                        defaultModulesTypesArr={getDefaultBannerImageModules(
                          "bannerImages"
                        )}
                        hasError={formValue[activeTab].bannerImages.hasError}
                        errorMessage={
                          formValue[activeTab].bannerImages.errorMessage
                        }
                      />
                    </Col>
                    <Col sm="12" className="mb-3">
                      <ImageModules
                        key={`${activeTab}_${validateID}`}
                        label="Head Images"
                        onChange={filesWithType =>
                          handleBannerImageModulesFilesChange(
                            filesWithType,
                            `${activeTab}.headImages`,
                            "arrayWithType",
                            "headImages"
                          )
                        }
                        defaultModulesTypesArr={getDefaultBannerImageModules(
                          "headImages"
                        )}
                        hasError={formValue[activeTab].headImages.hasError}
                        errorMessage={
                          formValue[activeTab].headImages.errorMessage
                        }
                      />
                    </Col>
                    <Col sm="12" className="mb-3">
                      <ImageModules
                        key={`${activeTab}_${validateID}`}
                        label="Bottom Images"
                        onChange={filesWithType =>
                          handleBannerImageModulesFilesChange(
                            filesWithType,
                            `${activeTab}.bottomImages`,
                            "arrayWithType",
                            "bottomImages"
                          )
                        }
                        defaultModulesTypesArr={getDefaultBannerImageModules(
                          "bottomImages"
                        )}
                        hasError={formValue[activeTab].bottomImages.hasError}
                        errorMessage={
                          formValue[activeTab].bottomImages.errorMessage
                        }
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>
            <Button
              className="mr-3 w-md"
              color="primary"
              onClick={handleSavePress}
            >
              Save
            </Button>
            <Button
              className="mr-2 w-md"
              color="secondary"
              onClick={handleCancelPress}
            >
              Cancel
            </Button>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ homepage }) => ({
  homepageData: homepage.homepageData,
})

const mapDispatchToProps = dispatch => ({
  onGetHomepage: (onSuccess, onError) =>
    dispatch(HomepageActions.getHomePage({ onSuccess, onError })),
  onEditHomepage: (homepage, onSuccess, onError) =>
    dispatch(HomepageActions.editHomePage({ homepage, onSuccess, onError })),
  onUploadImagesAndVideos: (files, onSuccess, onError) =>
    dispatch(FileActions.uploadImagesAndVideos({ files, onSuccess, onError })),
  onShowLoading: () => dispatch(LoginActions.showLoading()),
  onHideLoading: () => dispatch(LoginActions.hideLoading()),
})
export default connect(mapStateToProps, mapDispatchToProps)(HomepageBanners)

const LabelCheckBox = ({ id, label, value, onChange, checked }) => {
  return (
    <Row className="align-items-center m-0">
      <h5 className="text-truncate font-size-14 m-0 mr-2">{label}</h5>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          onChange={onChange}
          value={value}
          checked={checked}
        />
        <label className="custom-control-label" htmlFor={id} />
      </div>
    </Row>
  )
}
