import * as ArticleActionTypes from "./actionTypes"

//--------------------Article associated--------------------------------
// getArticles
export const getArticles = filtersWithCallbacks => ({
  type: ArticleActionTypes.GET_ARTICLES,
  payload: filtersWithCallbacks,
})

export const getArticlesSuccess = articles => ({
  type: ArticleActionTypes.GET_ARTICLES_SUCCESS,
  payload: articles,
})

export const getArticlesFail = error => ({
  type: ArticleActionTypes.GET_ARTICLES_FAIL,
  payload: error,
})

// getArticleById
export const getArticleById = articleIdWithCallbacks => ({
  type: ArticleActionTypes.GET_ARTICLE_BY_ID,
  payload: articleIdWithCallbacks,
})

export const getArticleByIdSuccess = article => ({
  type: ArticleActionTypes.GET_ARTICLE_TAB_BY_ID_SUCCESS,
  payload: article,
})

export const getArticleByIdFail = error => ({
  type: ArticleActionTypes.GET_ARTICLE_BY_ID_FAIL,
  payload: error,
})

// postArticle
export const postArticle = articleWithCallbacks => ({
  type: ArticleActionTypes.POST_ARTICLE,
  payload: articleWithCallbacks,
})

export const postArticleSuccess = res => ({
  type: ArticleActionTypes.POST_ARTICLE_SUCCESS,
  payload: res,
})

export const postArticleFail = error => ({
  type: ArticleActionTypes.POST_ARTICLE_FAIL,
  payload: error,
})

// deleteArticle
export const deleteArticle = articleIdWithCallbacks => ({
  type: ArticleActionTypes.DELETE_ARTICLE,
  payload: articleIdWithCallbacks,
})

export const deleteArticleSuccess = res => ({
  type: ArticleActionTypes.DELETE_ARTICLE_SUCCESS,
  payload: res,
})

export const deleteArticleFail = error => ({
  type: ArticleActionTypes.DELETE_ARTICLE_FAIL,
  payload: error,
})

// editArticle
export const editArticle = articleWithCallbacks => ({
  type: ArticleActionTypes.EDIT_ARTICLE,
  payload: articleWithCallbacks,
})

export const editArticleSuccess = res => ({
  type: ArticleActionTypes.EDIT_ARTICLE_SUCCESS,
  payload: res,
})

export const editArticleFail = error => ({
  type: ArticleActionTypes.EDIT_ARTICLE_FAIL,
  payload: error,
})

// getArticles count
export const getArticlesCount = filtersWithCallbacks => ({
  type: ArticleActionTypes.GET_ARTICLES_COUNT,
  payload: filtersWithCallbacks,
})

export const getArticlesCountSuccess = articlesCount => ({
  type: ArticleActionTypes.GET_ARTICLES_COUNT_SUCCESS,
  payload: articlesCount,
})

export const getArticlesCountFail = error => ({
  type: ArticleActionTypes.GET_ARTICLES_COUNT_FAIL,
  payload: error,
})

//--------------------ArticleTab associated--------------------------------
// getArticleTab
export const getArticleTab = filtersWithCallbacks => ({
  type: ArticleActionTypes.GET_ARTICLE_TAB,
  payload: filtersWithCallbacks,
})

export const getArticleTabSuccess = articleTabs => ({
  type: ArticleActionTypes.GET_ARTICLE_TAB_SUCCESS,
  payload: articleTabs,
})

export const getArticleTabFail = error => ({
  type: ArticleActionTypes.GET_ARTICLE_TAB_FAIL,
  payload: error,
})

// getArticleById
export const getArticleTabById = articleTabIdWithCallbacks => ({
  type: ArticleActionTypes.GET_ARTICLE_TAB_BY_ID,
  payload: articleTabIdWithCallbacks,
})

export const getArticleTabByIdSuccess = articleTab => ({
  type: ArticleActionTypes.GET_ARTICLE_TAB_BY_ID_SUCCESS,
  payload: articleTab,
})

export const getArticleTabByIdFail = error => ({
  type: ArticleActionTypes.GET_ARTICLE_TAB_BY_ID_FAIL,
  payload: error,
})

// postArticle
export const postArticleTab = articleTabWithCallbacks => ({
  type: ArticleActionTypes.POST_ARTICLE_TAB,
  payload: articleTabWithCallbacks,
})

export const postArticleTabSuccess = res => ({
  type: ArticleActionTypes.POST_ARTICLE_TAB_SUCCESS,
  payload: res,
})

export const postArticleTabFail = error => ({
  type: ArticleActionTypes.POST_ARTICLE_TAB_FAIL,
  payload: error,
})

// deleteArticleTab
export const deleteArticleTab = articleTabIdWithCallbacks => ({
  type: ArticleActionTypes.DELETE_ARTICLE_TAB,
  payload: articleTabIdWithCallbacks,
})

export const deleteArticleTabSuccess = res => ({
  type: ArticleActionTypes.DELETE_ARTICLE_TAB_SUCCESS,
  payload: res,
})

export const deleteArticleTabFail = error => ({
  type: ArticleActionTypes.DELETE_ARTICLE_TAB_FAIL,
  payload: error,
})

// editArticleTab
export const editArticleTab = articleTabWithCallbacks => ({
  type: ArticleActionTypes.EDIT_ARTICLE_TAB,
  payload: articleTabWithCallbacks,
})

export const editArticleTabSuccess = res => ({
  type: ArticleActionTypes.EDIT_ARTICLE_TAB_SUCCESS,
  payload: res,
})

export const editArticleTabFail = error => ({
  type: ArticleActionTypes.EDIT_ARTICLE_TAB_FAIL,
  payload: error,
})

// getArticleTab count
export const getArticleTabsCount = filtersWithCallbacks => ({
  type: ArticleActionTypes.GET_ARTICLE_TAB_COUNT,
  payload: filtersWithCallbacks,
})

export const getArticleTabsCountSuccess = articleTabsCount => ({
  type: ArticleActionTypes.GET_ARTICLE_TAB_COUNT_SUCCESS,
  payload: articleTabsCount,
})

export const getArticleTabsCountFail = error => ({
  type: ArticleActionTypes.GET_ARTICLE_TAB_COUNT_FAIL,
  payload: error,
})
