import React from "react"
import { Row, Col, Input, Label, FormGroup, FormFeedback } from "reactstrap"

const TicketSession = ({
  index,
  session,
  isInitialTicketSettingStateGenerated,
  onChange,
}) => {
  return (
    <div className="border rounded p-2 mt-2">
      <Row>
        <Col sm="4">
          <FormGroup>
            <Label htmlFor="sessions_sc_name">Session Name (簡中)</Label>
            <Input
              name="sessions_sc_name"
              type="text"
              className={`form-control ${
                isInitialTicketSettingStateGenerated ? "bg-light" : ""
              }`}
              value={session.sc_name.value}
              onChange={e => onChange(e, `session-sc_name-${index}`)}
              invalid={session.sc_name.hasError}
              disabled={isInitialTicketSettingStateGenerated}
            />
            <FormFeedback>{session.sc_name.errorMessage}</FormFeedback>
          </FormGroup>
        </Col>
        <Col sm="4">
          <FormGroup>
            <Label htmlFor="sessions_tc_name">Session Name (繁中)</Label>
            <Input
              name="sessions_tc_name"
              type="text"
              className={`form-control ${
                isInitialTicketSettingStateGenerated ? "bg-light" : ""
              }`}
              value={session.tc_name.value}
              onChange={e => onChange(e, `session-tc_name-${index}`)}
              invalid={session.tc_name.hasError}
              disabled={isInitialTicketSettingStateGenerated}
            />
            <FormFeedback>{session.tc_name.errorMessage}</FormFeedback>
          </FormGroup>
        </Col>
        <Col sm="4">
          <FormGroup>
            <Label htmlFor="sessions_en_name">Session Name (Eng)</Label>
            <Input
              name="sessions_en_name"
              type="text"
              className={`form-control ${
                isInitialTicketSettingStateGenerated ? "bg-light" : ""
              }`}
              value={session.en_name.value}
              onChange={e => onChange(e, `session-en_name-${index}`)}
              invalid={session.en_name.hasError}
              disabled={isInitialTicketSettingStateGenerated}
            />
            <FormFeedback>{session.en_name.errorMessage}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <FormGroup>
            <Label htmlFor="sortIndex">Session Sorting Index</Label>
            <Input
              name="sortIndex"
              type="number"
              className={`form-control ${
                isInitialTicketSettingStateGenerated ? "bg-light" : ""
              }`}
              value={session.sortIndex.value}
              onChange={e => onChange(e, `session-sortIndex-${index}`)}
              invalid={session.sortIndex.hasError}
              disabled={isInitialTicketSettingStateGenerated}
            />
            <FormFeedback>{session.sortIndex.errorMessage}</FormFeedback>
          </FormGroup>
        </Col>
      </Row>
    </div>
  )
}

export default TicketSession
