const articleTabCategories = {
  intro: {
    title: "M+ Intro",
    value: "intro",
    businessInfo: false,
  },
  directions: {
    title: "Directions",
    value: "directions",
    businessInfo: false,
  },
  ticketing: {
    title: "Ticketing",
    value: "ticketing",
    businessInfo: false,
  },
  dining: {
    title: "Dining",
    value: "dining",
    businessInfo: true,
  },
  shop: {
    title: "Shop",
    value: "shop",
    businessInfo: true,
  },
  suggestion: {
    title: "Suggestion",
    value: "suggestion",
    businessInfo: false,
  },
  hotspots: {
    title: "Hotspots",
    value: "hotspots",
    businessInfo: false,
  },
  membership: {
    title: "About Membership",
    value: "membership",
    businessInfo: false,
  },
  about: {
    title: "About M+",
    value: "about",
    businessInfo: false,
  },

  story: {
    title: "Story",
    value: "story",
    businessInfo: true,
  },
  event: {
    title: "Event",
    value: "event",
    businessInfo: true,
  },
}

export default articleTabCategories
