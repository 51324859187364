import React, { useEffect, useReducer, useState, useRef } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { compose } from "redux"
import { connect } from "react-redux"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import produce from "immer"
import { CSVLink } from "react-csv"
import moment from "moment"

import UsersOrderColumns from "./UsersOrderColumns"
import * as UsersActions from "../../store/users/actions"

const initialState = {
  curretPage: 1,
  sizePerPage: 10,
  phoneNumber: "",
}

const reducer = produce((draft, action) => {
  const { page, value } = action.payload
  switch (action.type) {
    case "onPrevPage":
      draft.curretPage = draft.curretPage - 1 || 1
      break
    case "OnSpecificPage":
      draft.curretPage = page
      break
    case "OnPhoneNumberChange":
      draft.phoneNumber = value
      break
    default:
      break
  }
})

const Users = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [csvReport, setCsvReport] = useState(undefined)
  const csvInstance = useRef()
  const { users, history, usersTotalCount } = props

  const pageOptions = {
    page: state.curretPage,
    sizePerPage: state.sizePerPage,
    totalSize: usersTotalCount,
    custom: true,
    onPageChange: page =>
      dispatch({ type: "OnSpecificPage", payload: { page } }),
  }
  useEffect(() => {
    getUsersAndUsersCount()
  }, [state.curretPage])

  useEffect(() => {
    if (csvReport && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click()
        setCsvReport(undefined)
      })
    }
  }, [csvReport])

  const handleExportPress = async () => {
    // setCsvReport([["gggg"], ["iiii"]])
    const usersFilters = {
      _limit: state.sizePerPage,
      _start: (state.curretPage - 1) * state.sizePerPage,
      _sort: "created_at:desc",
      ...(state.phoneNumber && { phone_number_contains: state.phoneNumber }),
    }
    console.log("transactionFilters", usersFilters)
    const res = await new Promise((resolve, reject) =>
      props.onGetUsersReport(usersFilters, resolve, reject)
    )
    console.log(res)

    const csvReportName = ["Users Reports"]
    const csvCreateTime = [
      `Generated Date/Time: ${moment().format("DD-MMM-YYYY HH:mm:ss")}`,
    ]
    const csvColumns = [
      "Name",
      "Country Code",
      "Phone Number",
      "Consent of Recieve Prom Msg",
      "Datetime of Registration",
    ]
    const flattenRes2CsvRow = res.map(_res => [
      _res.nickname,
      _res.phone_countryCode,
      _res.phone_number,
      _res.receivePromo ?? "OLD_USER_UNKNOWN",
      moment(_res.created_at).format("MM/DD/YY HH:mm"),
    ])
    const csvOutput = [
      csvReportName,
      csvCreateTime,
      csvColumns,
      ...flattenRes2CsvRow,
    ]
    console.log("csvOutput!!!!", csvOutput)
    setCsvReport(csvOutput)
  }

  const getUsersAndUsersCount = resetToPage1 => {
    if (resetToPage1 && state.curretPage !== 1) {
      dispatch({ type: "OnSpecificPage", payload: { page: 1 } })
      return
    }
    const usersCountFilters = {
      ...(state.phoneNumber && { phone_number_contains: state.phoneNumber }),
    }
    const usersFilters = {
      _limit: state.sizePerPage,
      _start: (state.curretPage - 1) * state.sizePerPage,
      _sort: "created_at:desc",
      ...usersCountFilters,
    }
    props.onGetUsers(usersFilters)
    props.onGetUsersCount(usersCountFilters)
  }
  const handleEditUser = user => {
    history.push({
      pathname: `/user/${user.id}/edit`,
    })
  }
  const handlePhoneChange = e => {
    dispatch({
      type: "OnPhoneNumberChange",
      payload: {
        value: e.target.value,
      },
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Users</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md="1.5">
                      <Label className="mb-3">Search :</Label>
                    </Col>
                    <Col md="4">
                      <Input
                        name="title"
                        type="text"
                        className="form-control select2 mb-3"
                        placeholder="Phone Number"
                        value={state.phoneNumber}
                        onChange={handlePhoneChange}
                      />
                    </Col>

                    <Col md="2">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md mb-3"
                        onClick={() => getUsersAndUsersCount(true)}
                      >
                        Search
                      </Button>
                    </Col>
                    <Col md="2">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md mb-3"
                        onClick={handleExportPress}
                      >
                        export
                      </Button>
                      {csvReport && (
                        <CSVLink
                          data={csvReport}
                          filename={`User-Report.csv`}
                          ref={csvInstance}
                        >
                          Download me
                        </CSVLink>
                      )}
                    </Col>
                  </Row>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={users}
                        columns={UsersOrderColumns({
                          handleEditUser,
                        })}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={() =>
                                      console.log("onTable CHange????")
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ users }) => ({
  users: users.users,
  usersTotalCount: users.usersTotalCount,
})

const mapDispatchToProps = dispatch => ({
  onGetUsers: (filters, onSuccess, onError) =>
    dispatch(UsersActions.getUsers({ filters, onSuccess, onError })),
  onGetUsersCount: (filters, onSuccess, onError) =>
    dispatch(UsersActions.getUsersCount({ filters, onSuccess, onError })),
  onGetUsersReport: (filters, onSuccess, onError) =>
    dispatch(UsersActions.getUsersReport({ filters, onSuccess, onError })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Users)
