import * as FileActionTypes from "./actionTypes"

export const uploadImages = imagesWithCallbacks => ({
  type: FileActionTypes.UPLOAD_IMAGES,
  payload: imagesWithCallbacks,
})

export const uploadImagesSuccess = imageUrls => ({
  type: FileActionTypes.UPLOAD_IMAGES_SUCCESS,
  payload: imageUrls,
})

export const uploadImagesFail = error => ({
  type: FileActionTypes.UPLOAD_IMAGES_FAIL,
  payload: error,
})

export const uploadVideos = videoWithCallbacks => ({
  type: FileActionTypes.UPLOAD_VIDEOS,
  payload: videoWithCallbacks,
})

export const uploadVideosSuccess = videoUrl => ({
  type: FileActionTypes.UPLOAD_VIDEOS_SUCCESS,
  payload: videoUrl,
})

export const uploadVideosFail = error => ({
  type: FileActionTypes.UPLOAD_VIDEOS_FAIL,
  payload: error,
})

export const uploadImagesAndVideos = imagesAndVideosWithCallbacks => ({
  type: FileActionTypes.UPLOAD_IMAGES_AND_VIDEOS,
  payload: imagesAndVideosWithCallbacks,
})

export const uploadImagesAndVideosSuccess = urls => ({
  type: FileActionTypes.UPLOAD_IMAGES_AND_VIDEOS_SUCCESS,
  payload: urls,
})

export const uploadImagesAndVideosFail = error => ({
  type: FileActionTypes.UPLOAD_IMAGES_AND_VIDEOS_FAIL,
  payload: error,
})
