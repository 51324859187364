import React, {
  useReducer,
  useRef,
  useEffect,
  useCallback,
  useState,
} from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {
  CardTitle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  FormFeedback,
} from "reactstrap"
import produce from "immer"
import { v4 as uuidV4 } from "uuid"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"

import { FileImageDisplayer } from "../ImageDisplayers"
import { CloseButtonWrapper } from "../Wrapper"
import {
  articleTabCategories,
  articleStatus,
  listingCategories,
} from "../../utils"
import {
  ticketingsCategories,
  ticketingsStatus,
} from "../../utils/ticketingsOptions"
import * as ArticlesActions from "../../store/articles/actions"
import * as HomepageActions from "../../store/homepage/actions"
import * as TicketingsActions from "../../store/ticketings/actions"

const modulesTypes = {
  ANCHOR: "content-module.anchor",
  TITLE: "content-module.title",
  PARAGRAPH: "content-module.paragraph",
  MAP: "content-module.map",
  IMAGE: "content-module.image",
  VIDEO: "content-module.video",
  BUTTON: "content-module.button",
}

const initModuleForm = moduleType => {
  switch (moduleType) {
    case modulesTypes.ANCHOR:
      return {
        __component: "content-module.anchor",
        id: uuidV4(),
        anchor: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      }
    case modulesTypes.TITLE:
      return {
        __component: "content-module.title",
        id: uuidV4(),
        title: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      }
    case modulesTypes.BUTTON:
      return {
        __component: "content-module.button",
        id: uuidV4(),
        text: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        destination: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        textColor: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        backgroundColor: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        category: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        appId: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        appPath: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        articleId: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      }
    case modulesTypes.PARAGRAPH:
      return {
        __component: "content-module.paragraph",
        id: uuidV4(),
        paragraph: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      }
    case modulesTypes.IMAGE:
      return {
        __component: "content-module.image",
        id: uuidV4(),
        imageUrl: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        destination: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        category: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        articleId: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      }
    case modulesTypes.VIDEO:
      return {
        __component: "content-module.video",
        id: uuidV4(),
        videoUrl: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      }
    case modulesTypes.MAP:
      return {
        __component: "content-module.map",
        id: uuidV4(),
        imageUrl: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        lat: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
        long: {
          value: "",
          hasError: false,
          errorMessage: "",
        },
      }
  }
}

const initialState = (defaultModulesTypesArr, defaultModulesFileObj) => {
  return {
    modulesTypesArr: defaultModulesTypesArr || [], // TODO: here needs to handle category temp and article temp
    modalVisible: false,
    files: defaultModulesFileObj || {},
  }
}

const reducer = produce((draft, action) => {
  const {
    sourceIndex,
    destinationIndex,
    moduleType,
    formKey,
    formValue,
    modulesTypesArr,
    assignType,
    fileType,
  } = action.payload
  switch (action.type) {
    case "ReorderModule": {
      // reorder modulesTypesArr
      const [target] = draft.modulesTypesArr.splice(sourceIndex, 1)
      draft.modulesTypesArr.splice(destinationIndex, 0, target)
      // reorder files
      const [sourceFileKey] = Object.keys(draft.files).filter(_key =>
        _key.startsWith(`[${sourceIndex}]`)
      )
      const [destinationFileKey] = Object.keys(draft.files).filter(_key =>
        _key.startsWith(`[${destinationIndex}]`)
      )
      // if both has file needs to swap
      if (sourceFileKey && destinationFileKey) {
        const sorceFile = draft.files[sourceFileKey]
        const destinationFile = draft.files[destinationFileKey]
        delete draft.files[sourceFileKey]
        delete draft.files[destinationFileKey]
        draft.files[sourceFileKey.replace(sourceIndex, destinationIndex)] = {
          ...sorceFile,
          key: sourceFileKey.replace(sourceIndex, destinationIndex),
        }
        draft.files[
          destinationFileKey.replace(destinationIndex, sourceIndex)
        ] = {
          ...destinationFile,
          key: destinationFileKey.replace(destinationIndex, sourceIndex),
        }
      } else if (sourceFileKey) {
        // only the start dragging module has file
        const sorceFile = draft.files[sourceFileKey]
        delete draft.files[sourceFileKey]
        draft.files[sourceFileKey.replace(sourceIndex, destinationIndex)] = {
          ...sorceFile,
          key: sourceFileKey.replace(sourceIndex, destinationIndex),
        }
      } else if (destinationFileKey) {
        /// only the end of drag module has file
        const destinationFile = draft.files[destinationFileKey]
        delete draft.files[destinationFileKey]
        draft.files[
          destinationFileKey.replace(destinationIndex, sourceIndex)
        ] = {
          ...destinationFile,
          key: destinationFileKey.replace(destinationIndex, sourceIndex),
        }
      }
      break
    }
    case "AddModule":
      draft.modulesTypesArr.push(initModuleForm(moduleType))
      break
    case "ToogleModal":
      draft.modalVisible = !draft.modalVisible
      break
    case "UpdateModuleForm":
      draft.modulesTypesArr[sourceIndex][formKey].value = formValue
      draft.modulesTypesArr[sourceIndex][formKey].hasError = false
      break
    case "ReplaceModule":
      draft.modulesTypesArr = modulesTypesArr
      break
    case "OnUploadFile":
      draft.files[formKey] = {
        files: formValue,
        key: formKey,
        assignType,
        type: fileType, // images, videos
      }
      _.set(draft.modulesTypesArr, `${formKey}.hasError`, false)
      break
    case "OnClearFile":
      delete draft.files[formKey]
      break
    case "OnDeleteModule":
      draft.modulesTypesArr.splice(sourceIndex, 1)
      Object.keys(draft.files)
        .filter(_key => _key.includes(`[${sourceIndex}]`))
        .forEach(_key => delete draft.files[_key])
      break
  }
})

const ContentModules = ({
  onChange,
  defaultModulesTypesArr,
  onFilesChange,
  defaultModulesFileObj,
}) => {
  console.log("defaultModulesFileObj", defaultModulesFileObj)
  const dispatchRedux = useDispatch()
  const [state, dispatch] = useReducer(
    reducer,
    initialState(defaultModulesTypesArr, defaultModulesFileObj)
  )
  const { modulesTypesArr, modalVisible, files } = state
  useEffect(() => {
    dispatchRedux(HomepageActions.getHomePage({ noLoadingEffect: true }))
  }, [])
  useEffect(() => {
    onChange && onChange(modulesTypesArr)
  }, [modulesTypesArr])
  useEffect(() => {
    onFilesChange && onFilesChange(files)
  }, [files])
  // drag end function
  const handleDragEnd = result => {
    const { destination, source } = result
    if (!destination) {
      return
    }
    dispatch({
      type: "ReorderModule",
      payload: {
        sourceIndex: source.index,
        destinationIndex: destination.index,
      },
    })
  }
  const handleModalToogle = () => {
    dispatch({ type: "ToogleModal", payload: {} })
  }
  const handleAddNewModule = moduleType => {
    dispatch({ type: "AddModule", payload: { moduleType } })
  }
  const handleModuleFormChange = (index, formKey, formValue) => {
    dispatch({
      type: "UpdateModuleForm",
      payload: {
        sourceIndex: index,
        formKey,
        formValue,
      },
    })
  }
  const handleFileUpload = (e, key, assignType, fileType) => {
    const filesArr = [...e.target.files]
    e.target.value = ""
    dispatch({
      type: "OnUploadFile",
      payload: {
        formValue: filesArr,
        formKey: key,
        assignType: assignType, // onlyValue arrayWithType
        fileType: fileType,
      },
    })
  }
  const handleFileClear = (key, isFormValue) => {
    dispatch({
      type: "OnClearFile",
      payload: {
        formKey: key,
        isFormValue,
      },
    })
  }
  const handleModuleDelete = index => {
    dispatch({
      type: "OnDeleteModule",
      payload: {
        sourceIndex: index,
      },
    })
  }
  console.log("content module state", state)

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <CardTitle className="mb-3 font-size-20">Content Modules</CardTitle>
      <Droppable droppableId="contentModules">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              backgroundColor: snapshot.isDraggingOver ? "#f3f3f9" : "white",
            }}
          >
            {modulesTypesArr.map((module, index) => (
              <ModuleContent
                key={`${module.__component}_${module.id}`}
                id={`${module.__component}_${module.id}`}
                module={module}
                index={index}
                onChange={handleModuleFormChange}
                onFileUpload={handleFileUpload}
                onFileClear={handleFileClear}
                onDeleteModule={handleModuleDelete}
                files={files}
              ></ModuleContent>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Button className="mt-3" onClick={handleModalToogle}>
        Add module
      </Button>
      <ModuleModal
        isOpen={modalVisible}
        onAdd={handleAddNewModule}
        onCancel={handleModalToogle}
      ></ModuleModal>
    </DragDropContext>
  )
}

export default ContentModules

const ModuleContent = ({
  id,
  index,
  module,
  onChange,
  onFileUpload,
  onFileClear,
  files,
  onDeleteModule,
}) => {
  const dispatch = useDispatch()
  const homepageData = useSelector(state => state.homepage.homepageData)
  const [articleArr, setArticleArr] = useState([])
  const [ticketingArr, setTicketingArr] = useState([])
  const [isFirst, setIsFirst] = useState(true)
  const handleFileClose_imageUrl = useCallback(
    () => onFileClear(`[${index}].imageUrl`),
    [index]
  )
  const handleFileClose_videoUrl = useCallback(
    () => onFileClear(`[${index}].videoUrl`),
    [index]
  )
  const handleFileUrlClear_imageUrl = useCallback(
    () => onChange(index, "imageUrl", ""),
    [index]
  )
  const handleFileUrlClear_videoUrl = useCallback(
    () => onChange(index, "videoUrl", ""),
    [index]
  )
  const categoriesHasArticle = [
    articleTabCategories.dining.value,
    articleTabCategories.shop.value,
    articleTabCategories.story.value,
    articleTabCategories.event.value,
  ]

  const categoriesHasTicketing = [
    ticketingsCategories.general.value,
    ticketingsCategories.workshop.value,
    ticketingsCategories.exhibition.value,
  ]

  useEffect(() => {
    //   dispatch(HomepageActions.getHomePage({}))
    if (!_.isEmpty(homepageData)) {
      setIsFirst(false)
    }
  })
  useEffect(() => {
    const categoryValue = module?.category?.value
    if (categoriesHasArticle.includes(categoryValue)) {
      const filters = {
        "article_tab.category": module?.category?.value,
        status: articleStatus.published.value, // currently is published might be published and draft in the future
      }
      getArticlesByCategory(filters)
    } else if (categoriesHasTicketing.includes(categoryValue)) {
      const filters = {
        category: module?.category?.value,
        status: ticketingsStatus.published.value, // currently is published might be published and draft in the future
      }
      getTicketingByCategory(filters)
    }
  }, [module?.category?.value])
  useEffect(() => {
    const categoryValue = module?.category?.value
    const articleValue = module?.articleId?.value
    if (isFirst) return
    if (listingCategories[categoryValue]) {
      onChange(
        index,
        "destination",
        listingCategories[categoryValue].destination
      )
    } else if (categoriesHasTicketing.includes(categoryValue) && articleValue) {
      onChange(index, "destination", `/event/event?id=${articleValue}`)
    } else if (categoriesHasArticle.includes(categoryValue) && articleValue) {
      onChange(index, "destination", `/article/article?id=${articleValue}`)
    } else if (categoryValue === articleTabCategories.directions.value) {
      onChange(
        index,
        "destination",
        `/article/article?id=${homepageData?.directionsID}`
      )
    } else if (categoryValue === articleTabCategories.ticketing.value) {
      onChange(
        index,
        "destination",
        `/article/article?id=${homepageData?.ticketingID}`
      )
    } else if (categoryValue === articleTabCategories.about.value) {
      onChange(
        index,
        "destination",
        `/article/article?id=${homepageData?.aboutMplusID}`
      )
    } else if (categoryValue === articleTabCategories.intro.value) {
      onChange(
        index,
        "destination",
        `/article/article?id=${homepageData?.mPlusInfoID}`
      )
    } else if (categoryValue === articleTabCategories.membership.value) {
      onChange(
        index,
        "destination",
        `/article/article?id=${homepageData?.aboutMembershipID}`
      )
    } else {
      onChange(index, "destination", "")
    }
  }, [module?.category?.value, module?.articleId?.value])
  const getArticlesByCategory = async filters => {
    const res = await new Promise((resolve, reject) =>
      dispatch(
        ArticlesActions.getArticles({
          filters,
          onSuccess: resolve,
          onError: reject,
        })
      )
    )
    setArticleArr(res)
  }
  const getTicketingByCategory = async filters => {
    const res = await new Promise((resolve, reject) =>
      dispatch(
        TicketingsActions.getTicketings({
          filters: filters,
          onSuccess: resolve,
          onError: reject,
        })
      )
    )
    setTicketingArr(res)
  }
  const getModuleContent = () => {
    switch (module.__component) {
      case modulesTypes.ANCHOR:
        return (
          <CloseButtonWrapper onClose={() => onDeleteModule(index)}>
            <FormGroup className="m-0">
              <Label htmlFor="anchor">Anchor Module</Label>
              <Input
                name="anchor"
                type="text"
                className="form-control"
                value={module.anchor.value}
                onChange={e => onChange(index, "anchor", e.target.value)}
                invalid={module.anchor.hasError}
              />
              <FormFeedback>{module.anchor.errorMessage}</FormFeedback>
            </FormGroup>
          </CloseButtonWrapper>
        )
      case modulesTypes.TITLE:
        return (
          <CloseButtonWrapper onClose={() => onDeleteModule(index)}>
            <FormGroup className="m-0">
              <Label htmlFor="title">Title Module</Label>
              <Input
                name="title"
                type="text"
                className="form-control"
                value={module.title.value}
                onChange={e => onChange(index, "title", e.target.value)}
                invalid={module.title.hasError}
              />
              <FormFeedback>{module.title.errorMessage}</FormFeedback>
            </FormGroup>
          </CloseButtonWrapper>
        )
      case modulesTypes.PARAGRAPH:
        return (
          <CloseButtonWrapper onClose={() => onDeleteModule(index)}>
            <FormGroup className="m-0">
              <Label htmlFor="paragraph">Paragraph Module</Label>
              <Input
                name="paragraph"
                type="textarea"
                className="form-control"
                value={module.paragraph.value}
                onChange={e => onChange(index, "paragraph", e.target.value)}
                invalid={module.paragraph.hasError}
              />
              <FormFeedback>{module.paragraph.errorMessage}</FormFeedback>
            </FormGroup>
          </CloseButtonWrapper>
        )
      case modulesTypes.MAP:
        return (
          <>
            <CloseButtonWrapper
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClose={() => onDeleteModule(index)}
            >
              <FormGroup className="m-0 mr-4">
                <Label htmlFor={`MapModule_${id}`}>Map Module</Label>
                <Input
                  id={`MapModule_${id}`}
                  name={`MapModule_${id}`}
                  type="file"
                  className="form-control btn btn-primary"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={e => {
                    onFileUpload(
                      e,
                      `[${index}].imageUrl`,
                      "onlyValue",
                      "images"
                    )
                  }}
                ></Input>
                <div>
                  <Label
                    className="btn btn-primary m-0"
                    htmlFor={`MapModule_${id}`}
                    for={`MapModule_${id}`}
                  >
                    Upload
                  </Label>
                </div>
              </FormGroup>
              <FormGroup className="m-0 mr-4">
                <Label htmlFor="lat">lat</Label>
                <Input
                  name="lat"
                  type="text"
                  className="form-control"
                  value={module.lat.value}
                  onChange={e => onChange(index, "lat", e.target.value)}
                  onKeyPress={e => {
                    const regex = /^[0-9]*\.?[0-9]*$/
                    if (!regex.test(`${e.target.value}${e.key}`)) {
                      e.preventDefault()
                    }
                  }}
                  invalid={module.lat.hasError}
                />
                <FormFeedback>{module.lat.errorMessage}</FormFeedback>
              </FormGroup>
              <FormGroup className="m-0 mr-4">
                <Label htmlFor="lang">long</Label>
                <Input
                  name="long"
                  type="text"
                  className="form-control"
                  value={module.long.value}
                  onChange={e => onChange(index, "long", e.target.value)}
                  onKeyPress={e => {
                    const regex = /^[0-9]*\.?[0-9]*$/
                    if (!regex.test(`${e.target.value}${e.key}`)) {
                      e.preventDefault()
                    }
                  }}
                  invalid={module.long.hasError}
                />
                <FormFeedback>{module.long.errorMessage}</FormFeedback>
              </FormGroup>
              {files[`[${index}].imageUrl`] ? (
                files[`[${index}].imageUrl`]?.files.map((_file, i) => (
                  <FileImageDisplayer
                    key={i}
                    file={_file}
                    onClose={handleFileClose_imageUrl}
                  />
                ))
              ) : (
                <FileImageDisplayer
                  fileUrlObj={{ url: module.imageUrl.value, type: "image" }}
                  onClose={handleFileUrlClear_imageUrl}
                ></FileImageDisplayer>
              )}
            </CloseButtonWrapper>
            {module.imageUrl.hasError && (
              <div style={{ color: "#F46A71" }}>
                {module.imageUrl.errorMessage}
              </div>
            )}
          </>
        )
      case modulesTypes.IMAGE:
        return (
          <>
            <CloseButtonWrapper
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClose={() => onDeleteModule(index)}
            >
              <FormGroup className="m-0 mr-4">
                <Label htmlFor={`ImageModule_${id}`}>Image Module</Label>
                <Input
                  id={`ImageModule_${id}`}
                  name={`ImageModule_${id}`}
                  type="file"
                  className="form-control btn btn-primary"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={e => {
                    onFileUpload(
                      e,
                      `[${index}].imageUrl`,
                      "onlyValue",
                      "images"
                    )
                  }}
                ></Input>
                <div>
                  <Label
                    className="btn btn-primary m-0"
                    htmlFor={`ImageModule_${id}`}
                    for={`ImageModule_${id}`}
                  >
                    Upload
                  </Label>
                </div>
              </FormGroup>
              {files[`[${index}].imageUrl`] ? (
                files[`[${index}].imageUrl`]?.files.map((_file, i) => (
                  <FileImageDisplayer
                    key={i}
                    file={_file}
                    onClose={handleFileClose_imageUrl}
                  ></FileImageDisplayer>
                ))
              ) : (
                <FileImageDisplayer
                  fileUrlObj={{ url: module.imageUrl.value, type: "image" }}
                  onClose={handleFileUrlClear_imageUrl}
                ></FileImageDisplayer>
              )}
            </CloseButtonWrapper>
            {module.imageUrl.hasError && (
              <div style={{ color: "#F46A71" }}>
                {module.imageUrl.errorMessage}
              </div>
            )}
            <Row style={{ marginTop: "10px" }}>
              <Col sm="6">
                <FormGroup>
                  <Label htmlFor="category">
                    Button Destination (Optional)
                  </Label>
                  <div style={{ display: "flex" }}>
                    <select
                      name="category"
                      className="form-control select2"
                      style={{ flex: 1 }}
                      value={module?.category?.value}
                      onChange={e => {
                        onChange(index, "category", e.target.value)
                        onChange(index, "articleId", "")
                      }}
                    >
                      <option>Select a category</option>
                      {Object.values(listingCategories).map(listingCategory => (
                        <option
                          key={listingCategory.value}
                          value={listingCategory.value}
                        >
                          {listingCategory.title}
                        </option>
                      ))}
                      {Object.values(articleTabCategories).map(category => (
                        <option key={category.value} value={category.value}>
                          {category.title}
                        </option>
                      ))}
                      {Object.values(ticketingsCategories).map(category => (
                        <option key={category.value} value={category.value}>
                          {category.title}
                        </option>
                      ))}
                    </select>
                    {categoriesHasArticle.includes(module?.category?.value) && (
                      <div>
                        <select
                          className="form-control select2"
                          style={{ flex: 1, marginLeft: "6px" }}
                          placeholder="select a article"
                          value={module?.articleId?.value}
                          onChange={e => {
                            onChange(index, "articleId", e.target.value)
                          }}
                        >
                          <option>Select an article</option>
                          {articleArr.map(article => (
                            <option key={article.id} value={article.id}>
                              {article.sc.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {categoriesHasTicketing.includes(
                      module?.category?.value
                    ) && (
                      <div>
                        <select
                          className="form-control select2"
                          style={{ flex: 1, marginLeft: "6px" }}
                          placeholder="select a ticket"
                          value={module?.articleId?.value}
                          onChange={e => {
                            onChange(index, "articleId", e.target.value)
                          }}
                        >
                          <option>Select an ticket</option>
                          {ticketingArr.map(ticket => (
                            <option key={ticket.id} value={ticket.id}>
                              {ticket.sc.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </>
        )
      case modulesTypes.VIDEO:
        return (
          <>
            <CloseButtonWrapper onClose={() => onDeleteModule(index)}>
              <FormGroup className="m-0">
                <Label htmlFor={`VideoModule_${id}`}>Video Module</Label>
                <Input
                  id={`VideoModule_${id}`}
                  name={`VideoModule_${id}`}
                  type="file"
                  className="form-control btn btn-primary"
                  style={{ display: "none" }}
                  accept="video/*"
                  onChange={e => {
                    onFileUpload(
                      e,
                      `[${index}].videoUrl`,
                      "onlyValue",
                      "videos"
                    )
                  }}
                />
                <div className="m-0">
                  <Label
                    className="btn btn-primary"
                    htmlFor={`VideoModule_${id}`}
                    for={`VideoModule_${id}`}
                  >
                    Upload
                  </Label>
                </div>
              </FormGroup>
              {files[`[${index}].videoUrl`] ? (
                files[`[${index}].videoUrl`]?.files.map((_file, i) => (
                  <FileImageDisplayer
                    key={i}
                    file={_file}
                    onClose={handleFileClose_videoUrl}
                  ></FileImageDisplayer>
                ))
              ) : (
                <FileImageDisplayer
                  fileUrlObj={{ url: module.videoUrl.value, type: "video" }}
                  onClose={handleFileUrlClear_videoUrl}
                ></FileImageDisplayer>
              )}
            </CloseButtonWrapper>
            {module.videoUrl.hasError && (
              <div style={{ color: "#F46A71" }}>
                {module.videoUrl.errorMessage}
              </div>
            )}
          </>
        )
      case modulesTypes.BUTTON:
        return (
          <CloseButtonWrapper onClose={() => onDeleteModule(index)}>
            <Label>Button Module</Label>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label htmlFor="text">Button Text</Label>
                  <Input
                    name="text"
                    type="text"
                    className="form-control"
                    value={module.text.value}
                    onChange={e => onChange(index, "text", e.target.value)}
                    invalid={module.text.hasError}
                  />
                  <FormFeedback>{module.text.errorMessage}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label htmlFor="category">
                    Button Destination (Optional)
                  </Label>
                  <div style={{ display: "flex" }}>
                    <select
                      name="category"
                      className="form-control select2"
                      style={{ flex: 1 }}
                      value={module?.category?.value}
                      onChange={e => {
                        onChange(index, "category", e.target.value)
                        onChange(index, "articleId", "")
                      }}
                    >
                      <option value="">Select a category</option>
                      <option key="appLink" value="appLink">
                        App Link
                      </option>
                      {Object.values(listingCategories).map(listingCategory => (
                        <option
                          key={listingCategory.value}
                          value={listingCategory.value}
                        >
                          {listingCategory.title}
                        </option>
                      ))}
                      {Object.values(articleTabCategories).map(category => (
                        <option key={category.value} value={category.value}>
                          {category.title}
                        </option>
                      ))}
                      {Object.values(ticketingsCategories).map(category => (
                        <option key={category.value} value={category.value}>
                          {category.title}
                        </option>
                      ))}
                    </select>
                    {module?.category?.value === "appLink" && (
                      <>
                        <div>
                          <Input
                            name="appId"
                            placeholder="App ID"
                            type="text"
                            className="form-control"
                            style={{ marginLeft: "6px" }}
                            value={module.appId.value}
                            onChange={e =>
                              onChange(index, "appId", e.target.value)
                            }
                            invalid={module.appId.hasError}
                          />
                        </div>
                        <div>
                          <Input
                            name="appPath"
                            placeholder="App Path"
                            type="text"
                            className="form-control"
                            style={{ marginLeft: "6px" }}
                            value={module.appPath.value}
                            onChange={e =>
                              onChange(index, "appPath", e.target.value)
                            }
                            invalid={module.appPath.hasError}
                          />
                        </div>
                      </>
                    )}
                    {categoriesHasArticle.includes(module?.category?.value) && (
                      <div>
                        <select
                          className="form-control select2"
                          style={{ flex: 1, marginLeft: "6px" }}
                          placeholder="select a article"
                          value={module?.articleId?.value}
                          onChange={e => {
                            onChange(index, "articleId", e.target.value)
                          }}
                        >
                          <option value="">Select an article</option>
                          {articleArr.map(article => (
                            <option key={article.id} value={article.id}>
                              {article.sc.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {categoriesHasTicketing.includes(
                      module?.category?.value
                    ) && (
                      <div>
                        <select
                          className="form-control select2"
                          style={{ flex: 1, marginLeft: "6px" }}
                          placeholder="select a ticket"
                          value={module?.articleId?.value}
                          onChange={e => {
                            onChange(index, "articleId", e.target.value)
                          }}
                        >
                          <option>Select an ticket</option>
                          {ticketingArr.map(ticket => (
                            <option key={ticket.id} value={ticket.id}>
                              {ticket.sc.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label htmlFor="textColor">Button Text Colour</Label>
                  <Input
                    name="textColor"
                    type="text"
                    className="form-control"
                    value={module.textColor.value}
                    onChange={e => onChange(index, "textColor", e.target.value)}
                    invalid={module.textColor.hasError}
                  />
                  <FormFeedback>{module.textColor.errorMessage}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup>
                  <Label htmlFor="backgroundColor">
                    Button Background Colour
                  </Label>
                  <Input
                    name="backgroundColor"
                    type="text"
                    className="form-control"
                    value={module.backgroundColor.value}
                    onChange={e =>
                      onChange(index, "backgroundColor", e.target.value)
                    }
                    invalid={module.backgroundColor.hasError}
                  />
                  <FormFeedback>
                    {module.backgroundColor.errorMessage}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </CloseButtonWrapper>
        )
      default:
        return null
    }
  }
  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          style={{
            border: snapshot.isDragging
              ? "1px solid #50A5F3"
              : "1px solid #ced4da",
            borderRadius: "0.25rem",
            margin: "6px 0",
            backgroundColor: "white",
            padding: 8,
            ...provided.draggableProps.style,
          }}
        >
          {getModuleContent()}
        </div>
      )}
    </Draggable>
  )
}

const ModuleModal = ({ isOpen, onAdd, onCancel }) => {
  const selectEl = useRef(null)
  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        <FormGroup>
          <Label className="control-label">Module type</Label>
          <select className="form-control select2" ref={selectEl}>
            {Object.keys(modulesTypes).map(moduleType => (
              <option
                key={modulesTypes[moduleType]}
                value={modulesTypes[moduleType]}
              >
                {moduleType}
              </option>
            ))}
          </select>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            onAdd(selectEl.current.value)
            onCancel()
          }}
        >
          Add
        </Button>{" "}
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  )
}
