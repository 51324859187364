import React from "react"
import { Link } from "react-router-dom"
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap"
import moment from "moment"

const UsersOrderColumns = ({ handleEditUser }) => [
  {
    dataField: "id",
    text: "ID",
    formatter: cellContent => {
      return (
        <Link to="#" className="text-body font-weight-bold">
          {cellContent}
        </Link>
      )
    },
  },
  {
    dataField: "nickname",
    text: "User Name",
    sort: true,
  },
  {
    dataField: "phone_number",
    text: "Phone Number",
    sort: true,
    formatter: cellContent => cellContent || "-",
  },
  {
    dataField: "dob",
    text: "Date of Birth",
    sort: true,
    formatter: cellContent => {
      const dobMoment = moment(cellContent, "YYYY-MM-DD")
      return dobMoment.isValid() ? dobMoment.format("MMM DD, YYYY") : "-"
    },
  },
  {
    dataField: "created_at",
    text: "Creation Date",
    sort: true,
    formatter: cellContent => moment(cellContent).format("MMM DD, YYYY"),
  },
  {
    dataField: "",
    text: "",
    sort: true,
    formatter: (cellContent, row) => {
      return <ArticleDropDown handleEditUser={() => handleEditUser(row)} />
    },
  },
]

export default UsersOrderColumns

const ArticleDropDown = ({ handleEditUser }) => {
  return (
    <>
      <UncontrolledDropdown className="dropdown">
        <DropdownToggle className="text-muted font-size-16" color="white">
          <i className="mdi mdi-dots-horizontal"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right" direction="right">
          <DropdownItem onClick={handleEditUser}>edit</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}
