import React from "react"
import Modal from "react-modal"
import Loading from "react-loading"
import "./LoadingWrapper.scss"
import { useSelector } from "react-redux"

const LoadingWrapper = props => {
  const isLoading = useSelector(state => state.mplusLogin.isLoading)
  return (
    <>
      {props.children}
      <Modal
        isOpen={isLoading}
        className="mplus-loading-wrapper__content"
        overlayClassName="mplus-loading-wrapper__overlay"
        ariaHideApp={false}
      >
        <Loading type="spin" />
      </Modal>
    </>
  )
}

export default LoadingWrapper
