const couponTypes = {
  ticketing: {
    value: "ticketing",
    title: "Ticketing",
  },
  seasonal: {
    value: "seasonal",
    title: "Seasonal",
  },
}

export default couponTypes
