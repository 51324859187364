export const GET_TRANSACTIONS = "GET_TRANSACTIONS"
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS"
export const GET_TRANSACTIONS_FAIL = "GET_TRANSACTIONS_FAIL"

export const GET_TRANSACTION_BY_ID = "GET_TRANSACTION_BY_ID"
export const GET_TRANSACTION_BY_ID_SUCCESS = "GET_TRANSACTION_BY_ID_SUCCESS"
export const GET_TRANSACTION_BY_ID_FAIL = "GET_TRANSACTION_BY_ID_FAIL"

export const UPDATE_TRANSACTION_STATUS_VOID = "UPDATE_TRANSACTION_STATUS_VOID"
export const UPDATE_TRANSACTION_STATUS_VOID_SUCCESS =
  "UPDATE_TRANSACTION_STATUS_VOID_SUCCESS"
export const UPDATE_TRANSACTION_STATUS_VOID_FAIL =
  "UPDATE_TRANSACTION_STATUS_VOID_FAIL"

export const GET_TRANSACTIONS_COUNT = "GET_TRANSACTIONS_COUNT"
export const GET_TRANSACTIONS_COUNT_SUCCESS = "GET_TRANSACTIONS_COUNT_SUCCESS"
export const GET_TRANSACTIONS_COUNT_FAIL = "GET_TRANSACTIONS_COUNT_FAIL"

export const GET_TRANSACTIONS_REPORT = "GET_TRANSACTIONS_REPORT"
export const GET_TRANSACTIONS_REPORT_SUCCESS = "GET_TRANSACTIONS_REPORT_SUCCESS"
export const GET_TRANSACTIONS_REPORT_FAIL = "GET_TRANSACTIONS_REPORT_FAIL"
