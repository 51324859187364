import React, { useEffect, useReducer } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
} from "reactstrap"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"
import produce from "immer"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import TransactionsOrderColumns from "./TransactionsOrderColumns"
import BootstrapTable from "react-bootstrap-table-next"
import DatePicker from "react-datepicker"
import moment from "moment"

import * as TransactionsActions from "../../store/transactions/actions"
import { transactionStatus } from "../../utils"
// import { articleTabCategories, articleStatus } from "../../utils"

const initialState = {
  curretPage: 1,
  sizePerPage: 10,
  eventId: "",
  eventStartDate: "",
  eventEndDate: "",
  orderStartDate: "",
  orderEndDate: "",
  orderStatus: "",
  name: "",
  phone: "",
}

const reducer = produce((draft, action) => {
  const { page, value, key } = action.payload
  switch (action.type) {
    case "onPrevPage":
      draft.curretPage = draft.curretPage - 1 || 1
      break
    case "OnSpecificPage":
      draft.curretPage = page
      break
    case "OnEventIdChange":
      draft.eventId = value
      break
    case "OnOrderStatuesChange":
      draft.orderStatus = value
      break
    case "OnDateChange":
      draft[key] = !value ? null : moment(value?.valueOf()).format("YYYY-MM-DD")
      break
    case "OnNameChange":
      draft.name = value
      break
    case "OnPhoneChange":
      draft.phone = value
      break
    default:
      break
  }
})

const Transactions = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { transactions, transactionsTotalCount, history } = props
  const pageOptions = {
    page: state.curretPage,
    sizePerPage: state.sizePerPage,
    totalSize: transactionsTotalCount,
    custom: true,
    onPageChange: page =>
      dispatch({ type: "OnSpecificPage", payload: { page } }),
  }
  console.log("state", state)
  useEffect(() => {
    getTransactionsAndTransactionsCount()
  }, [state.curretPage])

  const getTransactionsAndTransactionsCount = resetToPage1 => {
    if (resetToPage1 && state.curretPage !== 1) {
      dispatch({ type: "OnSpecificPage", payload: { page: 1 } })
      return
    }
    const articleCountFilters = {
      ...(state.eventId && { eventId: state.eventId }),
      ...(state.eventStartDate && { startDate: state.eventStartDate }),
      ...(state.eventEndDate && { endDate: state.eventEndDate }),
      ...(state.orderStartDate && {
        transactionStartDateTime: state.orderStartDate,
      }),
      ...(state.orderEndDate && {
        transactionEndDateTime: state.orderEndDate,
      }),
      ...(state.orderStatus && { state: state.orderStatus }),
      ...(state.name && { ["contact.name"]: state.name }),
      ...(state.phone && { ["contact.phone_number"]: state.phone }),
    }
    const articleFilters = {
      _limit: state.sizePerPage,
      _start: (state.curretPage - 1) * state.sizePerPage,
      _sort: "ctime:desc",
      ...articleCountFilters,
    }
    props.onGetTransactions(articleFilters)
    props.onGetTransactionsCount(articleCountFilters)
  }

  const handleViewTransaction = transaction => {
    history.push({
      pathname: `/transaction/${transaction._id}`,
    })
  }

  const handleInputChange = (e, key) => {
    dispatch({
      type: key,
      payload: {
        value: e.target.value,
      },
    })
  }

  const handleSelectChange = (e, key) => {
    dispatch({
      type: key,
      payload: {
        value: e.target.value,
      },
    })
  }

  const handleDateChange = (val, key) => {
    dispatch({
      type: "OnDateChange",
      payload: {
        value: val,
        key: key,
      },
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Transactions</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <Label className="mb-3">Filter :</Label>
                    </Col>

                    <Col md="2">
                      <Input
                        name="title"
                        type="text"
                        className="form-control select2 mb-3"
                        placeholder="Event ID"
                        value={state?.eventId}
                        onChange={e => handleInputChange(e, "OnEventIdChange")}
                      />
                    </Col>

                    <Col md="2">
                      <DatePicker
                        selected={
                          state.eventStartDate
                            ? new Date(moment(state.eventStartDate)?.valueOf())
                            : null
                        }
                        onChange={val => {
                          handleDateChange(val, "eventStartDate")
                        }}
                        className="form-control"
                        placeholderText="Event Start date"
                        isClearable
                      />
                    </Col>

                    <Col md="2">
                      <DatePicker
                        selected={
                          state.eventEndDate
                            ? new Date(moment(state.eventEndDate)?.valueOf())
                            : null
                        }
                        onChange={val => {
                          console.log(`val`, val)
                          handleDateChange(val, "eventEndDate")
                        }}
                        className="form-control"
                        placeholderText="Event End date"
                        isClearable
                      />
                    </Col>

                    <Col md="2">
                      <DatePicker
                        selected={
                          state.orderStartDate
                            ? new Date(moment(state.orderStartDate)?.valueOf())
                            : null
                        }
                        onChange={val => {
                          handleDateChange(val, "orderStartDate")
                        }}
                        className="form-control"
                        placeholderText="Order Start date"
                        isClearable
                      />
                    </Col>

                    <Col md="2">
                      <DatePicker
                        selected={
                          state.orderEndDate
                            ? new Date(moment(state.orderEndDate)?.valueOf())
                            : null
                        }
                        onChange={val => {
                          console.log(`val`, val)
                          handleDateChange(val, "orderEndDate")
                        }}
                        className="form-control"
                        placeholderText="Order End date"
                        isClearable
                      />
                    </Col>

                    <Col md="2">
                      <select
                        className="form-control select2 mb-3"
                        value={state.orderStatus}
                        onChange={e =>
                          handleSelectChange(e, "OnOrderStatuesChange")
                        }
                      >
                        <option value="">Order Status</option>
                        {Object.values(transactionStatus).map(status => (
                          <option key={status.value} value={status.value}>
                            {status.title}
                          </option>
                        ))}
                      </select>
                    </Col>

                    <Col md="2">
                      <Input
                        name="name"
                        type="text"
                        className="form-control select2 mb-3"
                        placeholder="Name"
                        value={state?.name}
                        onChange={e => handleInputChange(e, "OnNameChange")}
                      />
                    </Col>

                    <Col md="2">
                      <Input
                        name="phone"
                        type="text"
                        className="form-control select2 mb-3"
                        placeholder="Phone Number"
                        value={state?.phone}
                        onChange={e => handleInputChange(e, "OnPhoneChange")}
                      />
                    </Col>

                    <Col md="2">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md mb-3"
                        onClick={() =>
                          getTransactionsAndTransactionsCount(true)
                        }
                      >
                        Apply
                      </Button>
                    </Col>
                  </Row>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={transactions}
                        columns={TransactionsOrderColumns({
                          handleViewTransaction,
                        })}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={() =>
                                      console.log("onTable CHange????")
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ transactions }) => ({
  transactions: transactions.transactions,
  transactionsTotalCount: transactions.transactionsTotalCount,
})

const mapDispatchToProps = dispatch => ({
  onGetTransactions: (filters, onSuccess, onError) =>
    dispatch(
      TransactionsActions.getTransactions({ filters, onSuccess, onError })
    ),
  onGetTransactionsCount: (filters, onSuccess, onError) =>
    dispatch(
      TransactionsActions.getTransactionsCount({ filters, onSuccess, onError })
    ),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Transactions)
