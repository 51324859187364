import React, { useEffect, useReducer } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
} from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import { compose } from "redux"
import { connect } from "react-redux"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import produce from "immer"

import AdminsOrderColumns from "./AdminsOrderColumns"
import * as AdminsActions from "../../store/admins/actions"

const initialState = {
  curretPage: 1,
  sizePerPage: 10,
  email: "",
}

const reducer = produce((draft, action) => {
  const { page, value } = action.payload
  switch (action.type) {
    case "onPrevPage":
      draft.curretPage = draft.curretPage - 1 || 1
      break
    case "OnSpecificPage":
      draft.curretPage = page
      break
    case "OnEmailChange":
      draft.email = value
      break
    default:
      break
  }
})

const Admins = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { admins, history, adminsTotalCount } = props
  console.log("admins", admins)
  const pageOptions = {
    page: state.curretPage,
    sizePerPage: state.sizePerPage,
    totalSize: adminsTotalCount,
    custom: true,
    onPageChange: page =>
      dispatch({ type: "OnSpecificPage", payload: { page } }),
  }
  useEffect(() => {
    getAdminsAndAdminsCount()
  }, [state.curretPage])

  const getAdminsAndAdminsCount = resetToPage1 => {
    if (resetToPage1 && state.curretPage !== 1) {
      dispatch({ type: "OnSpecificPage", payload: { page: 1 } })
      return
    }
    const adminCountFilters = {
      ...(state.email && { email_contains: state.email }),
    }
    const adminFilters = {
      _limit: state.sizePerPage,
      _start: (state.curretPage - 1) * state.sizePerPage,
      _sort: "created_at:desc",
      ...adminCountFilters,
    }
    props.onGetAdmins(adminFilters)
    props.onGetAdminsCount(adminCountFilters)
  }

  const handleDeleteAdmin = async adminId => {
    await new Promise((resolve, reject) =>
      props.onDeleteAdmin(adminId, resolve, reject)
    )
    // spectial situation ***
    if ((state.curretPage - 1) * state.sizePerPage + 1 === adminsTotalCount) {
      // if the currenet page's first item is the last item
      // after doing delete need to go to previous page
      dispatch({ type: "onPrevPage", payload: {} })
    } else {
      // normal situation refresh
      getAdminsAndAdminsCount()
    }
  }
  const handleEditAdmin = admin => {
    history.push({
      pathname: "/create-admin",
      search: "?mode=edit",
      state: {
        mode: "edit",
        editAdmin: admin,
      },
    })
  }
  const handleEmailChange = e => {
    dispatch({
      type: "OnEmailChange",
      payload: {
        value: e.target.value,
      },
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>Admins</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md="1.5">
                      <Label className="mb-3">Search :</Label>
                    </Col>
                    <Col md="4">
                      <Input
                        name="title"
                        type="text"
                        className="form-control select2 mb-3"
                        placeholder="Email"
                        value={state.email}
                        onChange={handleEmailChange}
                      />
                    </Col>

                    <Col md="2">
                      <Button
                        type="button"
                        color="primary"
                        className="w-md mb-3"
                        onClick={() => getAdminsAndAdminsCount(true)}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="12">
                      <Link
                        to="/create-admin"
                        className="btn btn-primary mr-2 w-md mb-3 mt-4"
                      >
                        Create
                      </Link>
                    </Col>
                  </Row>

                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={admins}
                        columns={AdminsOrderColumns({
                          handleEditAdmin,
                          handleDeleteAdmin,
                        })}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={() =>
                                      console.log("onTable CHange????")
                                    }
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ admins }) => ({
  admins: admins.admins,
  adminsTotalCount: admins.adminsTotalCount,
})

const mapDispatchToProps = dispatch => ({
  onGetAdmins: (filters, onSuccess, onError) =>
    dispatch(AdminsActions.getAdmins({ filters, onSuccess, onError })),
  onGetAdminsCount: (filters, onSuccess, onError) =>
    dispatch(AdminsActions.getAdminsCount({ filters, onSuccess, onError })),
  onDeleteAdmin: (adminId, onSuccess, onError) =>
    dispatch(AdminsActions.deleteAdmin({ adminId, onSuccess, onError })),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(Admins)
