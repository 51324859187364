import React, { useReducer, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  FormFeedback,
} from "reactstrap"
import { connect } from "react-redux"
import { compose } from "redux"
import produce from "immer"
import { Link, withRouter } from "react-router-dom"
import _ from "lodash"
import { v4 as uuidV4 } from "uuid"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

import * as LoginActions from "../../store/login/actions"
import * as CouponsActions from "../../store/coupons/actions"
import * as FileActions from "../../store/file/actions"
import { couponTypes, articleStatus } from "../../utils"
import { FileImageDisplayer } from "../../components/ImageDisplayers"

const Tabs = {
  TC: "tcDetails",
  SC: "scDetails",
  EN: "enDetails",
}

const startsWithSC = key => key.startsWith(Tabs.SC)

const startsWithLang = key =>
  key.startsWith(Tabs.EN) || key.startsWith(Tabs.SC) || key.startsWith(Tabs.TC)

const settingEditCouponToState = editCoupon => {
  const defaultFormValue = produce(editCoupon, draftArticle => {
    Object.keys(draftArticle).forEach(key => {
      const keysToignore = ["updated_at", "created_at", "id"]
      // keys to ignore
      if (keysToignore.includes(key)) {
        delete draftArticle[key]
      }
      // mormal keys
      else if (!startsWithLang(key)) {
        draftArticle[key] = {
          value: draftArticle[key],
          hasError: false,
          errorMessage: "",
        }
      }
      // keys that is lang
      else if (startsWithLang(key)) {
        Object.keys(draftArticle[key]).forEach(
          _key =>
            (draftArticle[key][_key] = {
              value: draftArticle[key][_key],
              hasError: false,
              errorMessage: "",
            })
        )
      }
    })
  })
  return {
    ...initialState,
    formValue: defaultFormValue,
  }
}

const initialState = {
  formValue: {
    couponType: {
      value: "ticketing",
      hasError: false,
      errorMessage: "",
    },
    sortingIndex: {
      value: 100,
      hasError: false,
      errorMessage: "",
    },
    expiryDate: {
      value: moment().format(),
      hasError: false,
      errorMessage: "",
    },
    couponStatus: {
      value: "draft",
      hasError: false,
      errorMessage: "",
    },
    couponCode: {
      value: "",
      hasError: false,
      errorMessage: "",
    },
    enDetails: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponTitle: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponDescription: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponTNC: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponImageUrl: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
    },
    scDetails: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponTitle: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponDescription: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponTNC: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponImageUrl: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
    },
    tcDetails: {
      id: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponTitle: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponDescription: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponTNC: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      couponImageUrl: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
    },
  },
  activeTab: Tabs.SC,
  files: {},
  validateID: uuidV4(),
}

const reducer = produce((draft, action) => {
  const { value, key, assignType } = action.payload
  console.log("!!!!", action.type, "!!!!!")
  const isValidkey =
    draft.formValue[key] || draft.formValue[draft.activeTab][key]
  switch (action.type) {
    case "OnInputChange":
    case "OnDateChange":
    case "OnOptionChange":
      if (isValidkey) {
        if (draft.formValue[key]) {
          draft.formValue[key].value = value
          draft.formValue[key].hasError = false
        } else {
          draft.formValue[draft.activeTab][key].value = value
          draft.formValue[draft.activeTab][key].hasError = false
        }
      }
      break
    case "OnTabChange":
      draft.activeTab = value
      break
    case "OnCopyPress":
      Object.values(Tabs)
        .filter(tab => tab !== draft.activeTab) // tabs that are not currenet tab
        .forEach(tab => {
          // copy formValue to other tabs
          draft.formValue[tab] = draft.formValue[draft.activeTab]
          // copying files to other tabs
          Object.keys(draft.files)
            .filter(_key => _key.startsWith(draft.activeTab))
            .forEach(_key => {
              draft.files[_key.replace(draft.activeTab, tab)] = {
                ...draft.files[_key],
                key: _key.replace(draft.activeTab, tab),
              }
            })
        })
      break
    case "OnUploadImage":
      draft.files[key] = {
        files: value,
        key,
        assignType,
        type: "images",
      }
      _.set(draft.formValue, `${key}.hasError`, false)
      break
    case "OnClearImage":
      delete draft.files[key]
      break
    case "OnSetAllState":
      return value
  }
})

const CreateCoupon = props => {
  const { match } = props
  const { path, params } = match
  console.log("params", params, settingEditCouponToState)
  const isEditMode = path === "/coupon/:id/edit"
  const [state, dispatch] = useReducer(reducer, initialState)
  const { formValue, activeTab } = state
  console.log("save press !!!", state)
  useEffect(async () => {
    if (isEditMode && params.id) {
      const couponRes = await new Promise((resolve, reject) =>
        props.onGetCouponById(params.id, resolve, reject)
      )
      dispatch({
        type: "OnSetAllState",
        payload: { value: settingEditCouponToState(couponRes) },
      })
    }
  }, [])

  const handleOptionChange = (e, key) => {
    dispatch({
      type: "OnOptionChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }
  const handleInputChange = (e, key) => {
    dispatch({
      type: "OnInputChange",
      payload: {
        value: e.target.value,
        key: key,
      },
    })
  }
  const handleDateChange = (val, key) => {
    dispatch({
      type: "OnDateChange",
      payload: {
        value: val,
        key: key,
      },
    })
  }
  const hadleTabChange = tabLang => {
    dispatch({
      type: "OnTabChange",
      payload: {
        value: tabLang,
      },
    })
  }
  const handleCopyPress = () => {
    dispatch({ type: "OnCopyPress", payload: {} })
  }
  const handleImageUpload = (e, key, assignType) => {
    const filesArr = [...e.target.files]
    if (filesArr.length) {
      dispatch({
        type: "OnUploadImage",
        payload: {
          value: filesArr,
          key,
          assignType: assignType, // onlyValue arrayWithType
        },
      })
    } else {
      handleClearImage(key)
    }
  }
  const handleClearImage = (key, isFormValue) => {
    if (isFormValue) {
      dispatch({
        type: "OnInputChange",
        payload: {
          value: "",
          key: key,
        },
      })
    } else {
      dispatch({
        type: "OnClearImage",
        payload: {
          key,
        },
      })
    }
  }

  const handleSavePress = async () => {
    console.log("save press !!!", state)
    const {
      history,
      onShowLoading,
      onHideLoading,
      onEditCoupon,
      onPostCoupon,
    } = props
    if (!validateFields()) return
    onShowLoading()
    const imageUploadRes = await UploadFilesToCloud()
    console.log("imageUploadRes", imageUploadRes)
    const submitForm = produce(formValue, draftFormValue => {
      // 1.putting image url to form value
      imageUploadRes.forEach(_res => {
        if (_res.assignType === "onlyValue") {
          _.set(draftFormValue, `${_res.key}.value`, _res.value[0].url)
        } else {
          _.set(draftFormValue, `${_res.key}.value`, _res.value)
        }
      })
      // 2.flatten the form values
      Object.keys(draftFormValue).forEach(key => {
        // normal keys
        if (!startsWithLang(key)) {
          draftFormValue[key] = draftFormValue[key].value
        }
        // keys that is lang
        if (startsWithLang(key)) {
          Object.keys(draftFormValue[key]).forEach(_key => {
            draftFormValue[key][_key] = draftFormValue[key][_key].value
            if (_key === "id") delete draftFormValue[key][_key]
          })
        }
      })
    })
    console.log("submitForm!!!", submitForm)

    try {
      if (isEditMode) {
        await new Promise((resolve, reject) =>
          onEditCoupon(params?.id, submitForm, resolve, reject)
        )
      } else {
        await new Promise((resolve, reject) =>
          onPostCoupon(submitForm, resolve, reject)
        )
      }
      onHideLoading()
      history.push("/coupons")
    } catch (err) {
      console.log(err)
      onHideLoading()
    }
  }

  const validateFields = () => {
    const { formValue, files } = state
    let isAllValidate = true
    // console.log("validateFields formValue", formValue)
    const newForm = produce(formValue, draftFormValue => {
      Object.keys(draftFormValue).forEach(key => {
        // normal keys
        if (!startsWithLang(key)) {
          if (
            _.isNil(draftFormValue[key].value) ||
            draftFormValue[key].value === ""
          ) {
            draftFormValue[key].hasError = true
            draftFormValue[key].errorMessage = "Field cant be empty"
            isAllValidate = false
          }
        }
        // keys that is lang,
        if (
          startsWithLang(key) &&
          startsWithSC(key) // (currntly only validate sc other lang can bypass)
        ) {
          Object.keys(draftFormValue[key]).forEach(_key => {
            // console.log(key, _key, current(draftFormValue[key][_key]))
            if (_key === "couponImageUrl") {
              if (
                (draftFormValue[key][_key].value === "" ||
                  _.isNil(draftFormValue[key][_key].value)) &&
                !files[`${key}.${_key}`]?.files.length
              ) {
                draftFormValue[key][_key].hasError = true
                draftFormValue[key][_key].errorMessage = "Field cant be empty"
                isAllValidate = false
              }
            } else if (_key === "id") {
              // do nothing
            } else {
              if (
                draftFormValue[key][_key].value === "" ||
                _.isNil(draftFormValue[key][_key].value)
              ) {
                draftFormValue[key][_key].hasError = true
                draftFormValue[key][_key].errorMessage = "Field cant be empty"
                isAllValidate = false
              }
            }
          })
        }
      })
    })
    dispatch({ type: "OnUpdateFormValue", payload: { value: newForm } })
    console.log("newForm", newForm, isAllValidate)
    return isAllValidate
  }

  const UploadFilesToCloud = async () => {
    // uploading files to cloud
    const filesArr = Object.values(state.files)
    const resArr = []
    for (let file of filesArr) {
      const res = await new Promise((resolve, reject) =>
        props.onUploadImagesAndVideos(file.files, resolve, reject)
      )
      resArr.push({
        value: res,
        key: file.key,
        assignType: file.assignType,
      })
    }
    return resArr
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <h4>{isEditMode ? "Edit Coupon" : "Create Coupon"}</h4>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-3">Publishing Control</CardTitle>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">Type</Label>
                        <select
                          className="form-control select2"
                          value={formValue.couponType.value}
                          onChange={e => handleOptionChange(e, "couponType")}
                        >
                          {Object.values(couponTypes).map(type => (
                            <option key={type.value} value={type.value}>
                              {type.title}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="sortingIndex">Type Sorting Index</Label>
                        <Input
                          name="sortingIndex"
                          type="text"
                          className="form-control"
                          value={formValue.sortingIndex.value}
                          onChange={e => {
                            handleInputChange(e, "sortingIndex")
                          }}
                          invalid={formValue.sortingIndex.hasError}
                        />
                        <FormFeedback>
                          {formValue.sortingIndex.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="expiryDate">Expiry Date</Label>
                        <DatePicker
                          selected={
                            new Date(
                              moment(formValue.expiryDate.value).valueOf()
                            )
                          }
                          onChange={val => {
                            handleDateChange(
                              moment(val?.valueOf()).format(),
                              "expiryDate"
                            )
                          }}
                          className="form-control"
                          placeholderText="Select date"
                        />
                        <FormFeedback>
                          {formValue.expiryDate.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="6">
                      <FormGroup>
                        <Label className="control-label">Coupon Status</Label>
                        <select
                          className="form-control select2"
                          value={formValue.couponStatus.value}
                          onChange={e => handleOptionChange(e, "couponStatus")}
                        >
                          {Object.values(articleStatus).map(as => (
                            <option key={as.value} value={as.value}>
                              {as.title}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="6">
                      <FormGroup>
                        <Label htmlFor="couponCode">Display Coupon Code</Label>
                        <Input
                          name="couponCode"
                          type="text"
                          className="form-control"
                          value={formValue.couponCode.value}
                          onChange={e => {
                            handleInputChange(e, "couponCode")
                          }}
                          invalid={formValue.couponCode.hasError}
                        />
                        <FormFeedback>
                          {formValue.couponCode.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Nav tabs>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.SC}
                    onClick={() => hadleTabChange(Tabs.SC)}
                  >
                    <i className="bx bx-chat font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">簡體中文</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.TC}
                    onClick={() => hadleTabChange(Tabs.TC)}
                  >
                    <i className="bx bx-group font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">繁體中文</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab === Tabs.EN}
                    onClick={() => hadleTabChange(Tabs.EN)}
                  >
                    <i className="bx bx-book-content font-size-20 d-sm-none" />
                    <span className="d-none d-sm-block">English</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <Card>
                <CardBody>
                  <Row className="justify-content-between m-0 mb-4">
                    <CardTitle className="mb-3">General</CardTitle>
                    <Button onClick={handleCopyPress}>
                      Copy to other lang
                    </Button>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="couponTitle">Title</Label>
                        <Input
                          name="couponTitle"
                          type="text"
                          className="form-control"
                          value={formValue[activeTab].couponTitle.value}
                          onChange={e => {
                            handleInputChange(e, "couponTitle")
                          }}
                          invalid={formValue[activeTab].couponTitle.hasError}
                        />
                        <FormFeedback>
                          {formValue[activeTab].couponTitle.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="couponDescription">Description</Label>
                        <Input
                          name="couponDescription"
                          type="textarea"
                          className="form-control"
                          value={formValue[activeTab].couponDescription.value}
                          onChange={e => {
                            handleInputChange(e, "couponDescription")
                          }}
                          invalid={
                            formValue[activeTab].couponDescription.hasError
                          }
                        />
                        <FormFeedback>
                          {formValue[activeTab].couponDescription.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="couponTNC">T&C</Label>
                        <Input
                          name="couponTNC"
                          type="textarea"
                          className="form-control"
                          value={formValue[activeTab].couponTNC.value}
                          onChange={e => {
                            handleInputChange(e, "couponTNC")
                          }}
                          invalid={formValue[activeTab].couponTNC.hasError}
                        />
                        <FormFeedback>
                          {formValue[activeTab].couponTNC.errorMessage}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col sm="12">
                      <FormGroup>
                        <Label htmlFor="CouponImage">Coupon Image</Label>
                        <Input
                          id="CouponImage"
                          name="CouponImage"
                          type="file"
                          className="form-control btn btn-primary"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={e =>
                            handleImageUpload(
                              e,
                              `${activeTab}.couponImageUrl`,
                              "onlyValue"
                            )
                          }
                        ></Input>
                        <Col className="p-0">
                          <Label
                            className="btn btn-primary"
                            htmlFor="CouponImage"
                            for="CouponImage"
                          >
                            Upload
                          </Label>
                        </Col>
                        <Col className="p-0">
                          {state.files[`${activeTab}.couponImageUrl`] ? (
                            state.files[
                              `${activeTab}.couponImageUrl`
                            ]?.files.map((_file, _index) => (
                              <FileImageDisplayer
                                key={_index}
                                file={_file}
                                onClose={() =>
                                  handleClearImage(
                                    `${activeTab}.couponImageUrl`
                                  )
                                }
                              ></FileImageDisplayer>
                            ))
                          ) : (
                            <FileImageDisplayer
                              fileUrlObj={{
                                url: formValue[activeTab].couponImageUrl.value,
                                type: "image",
                              }}
                              onClose={() =>
                                handleClearImage("couponImageUrl", true)
                              }
                            ></FileImageDisplayer>
                          )}
                        </Col>
                        {formValue[activeTab].couponImageUrl.hasError && (
                          <Col className="p-0" style={{ color: "#f46a6a" }}>
                            {formValue[activeTab].couponImageUrl.errorMessage}
                          </Col>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div>
            <Button
              className="mr-3 w-md"
              color="primary"
              onClick={handleSavePress}
            >
              Save
            </Button>
            <Link to="/coupons" className="btn btn-secondary mr-2 w-md">
              Cancel
            </Link>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}
const mapStateToProps = ({ articles }) => ({
  articleTabs: articles.articleTabs,
})

const mapDispatchToProps = dispatch => ({
  onGetCouponById: (couponId, onSuccess, onError) =>
    dispatch(CouponsActions.getCouponById({ couponId, onSuccess, onError })),
  onPostCoupon: (coupon, onSuccess, onError) =>
    dispatch(CouponsActions.postCoupon({ coupon, onSuccess, onError })),
  onEditCoupon: (couponId, coupon, onSuccess, onError) =>
    dispatch(
      CouponsActions.editCoupon({ couponId, coupon, onSuccess, onError })
    ),
  onUploadImage: (images, onSuccess, onError) =>
    dispatch(FileActions.uploadImages({ images, onSuccess, onError })),
  onUploadVideos: (videos, onSuccess, onError) =>
    dispatch(FileActions.uploadVideos({ videos, onSuccess, onError })),
  onUploadImagesAndVideos: (files, onSuccess, onError) =>
    dispatch(FileActions.uploadImagesAndVideos({ files, onSuccess, onError })),
  onShowLoading: () => dispatch(LoginActions.showLoading()),
  onHideLoading: () => dispatch(LoginActions.hideLoading()),
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(CreateCoupon)
