import * as TicketingsActionTypes from "./actionTypes"

const INIT_STATE = {
  ticketings: [],
  ticketingsTotalCount: 0,
  quotas: [],
  quotasTotalCount: 0,
  error: {},
}

const ticketings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TicketingsActionTypes.GET_TICKETINGS:
      return {
        ...state,
        error: {},
      }
    case TicketingsActionTypes.GET_TICKETINGS_SUCCESS:
      return {
        ...state,
        ticketings: action.payload,
      }
    case TicketingsActionTypes.GET_TICKETINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case TicketingsActionTypes.GET_TICKETINGS_COUNT:
      return {
        ...state,
        error: {},
      }
    case TicketingsActionTypes.GET_TICKETINGS_COUNT_SUCCESS:
      return {
        ...state,
        ticketingsTotalCount: action.payload,
      }
    case TicketingsActionTypes.GET_TICKETINGS_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case TicketingsActionTypes.GENERATE_TICKETINGS:
      return {
        ...state,
        error: {},
      }
    case TicketingsActionTypes.GENERATE_TICKETINGS_SUCCESS:
      return state
    case TicketingsActionTypes.GENERATE_TICKETINGS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case TicketingsActionTypes.GET_TICKETING_QUOTAS:
      return {
        ...state,
        error: {},
      }
    case TicketingsActionTypes.GET_TICKETING_QUOTAS_SUCCESS:
      return {
        ...state,
        quotas: action.payload,
      }
    case TicketingsActionTypes.GET_TICKETING_QUOTAS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case TicketingsActionTypes.GET_TICKETING_QUOTAS_COUNT:
      return {
        ...state,
        error: {},
      }
    case TicketingsActionTypes.GET_TICKETING_QUOTAS_COUNT_SUCCESS:
      return {
        ...state,
        quotasTotalCount: action.payload,
      }
    case TicketingsActionTypes.GET_TICKETING_QUOTAS_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default ticketings
